<template>
        <div
		class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-white mb-4 hover:cursor-pointer p-4 px-3 gap-3;"
        @click="openWebinarPage"
	>
		<div class="flex flex-row w-full justify-between rounded-xl text-stan-text-dark text-sm">
			<div class="flex flex-row gap-2 w-full items-center">
				<img :src="session.image" class="helpful-webinar-image" />
				<div class="overflow-hidden">
					<div class="helpful-webinar-title para-2 text-bold">{{ session.title }}</div>
					<div class="helpful-webinar-url text-ellipsis overflow-x-hidden whitespace-nowrap overflow-y-visible">
						{{ formatDate(session.date) }} ● {{ convertTimeRange(session.time, session.duration_minutes, session.timezone) }}
					</div>
				</div>
			</div>
		</div>
	</div>

</template>


<script setup>
	import CONSTANTS from '@/global_helper/constants';
	import { configSettings } from '@/plugins/configSettings';
	import { useCommunityPublicStore } from '@/stores/public';
	import { DateTime } from 'luxon'
    import { computed, defineProps, inject } from 'vue';

	const stanAnalytics = inject('stanAnalytics')
	const communityPublicStore = useCommunityPublicStore()

    const props = defineProps({
		session: {
			type: Object,
			required: true,
		},
	});


    const openWebinarPage = () => {
		stanAnalytics('community-webinars-open',{
			props: {"webinarId": props.session.webinar_id}
		})
        window.open(`${configSettings.frontDomain}/${communityPublicStore.username}/p/${props.session.webinar_slug}`,'blank');
    };

	function convertTimeRange(startTimeStr, duration, tz) {
    	const startDateTime = DateTime.fromFormat(startTimeStr, CONSTANTS.DATE_TIME_FORMATS.TIME_ONLY, { zone: tz })
		const endDateTime = startDateTime.plus({ minutes: duration })
		function formatTime(dateTime) {
			return dateTime.toLocal().toFormat(CONSTANTS.DATE_TIME_FORMATS.TIME_ONLY).replace(':00', '')
		}

		const startFormatted = formatTime(startDateTime)
		const endFormatted = formatTime(endDateTime)

		return `${startFormatted} - ${endFormatted}`
	}
	function formatDate(dateStr) {
    	const date = DateTime.fromISO(dateStr); 
    	return date.toFormat('MMM d, yyyy'); 
	}
</script>


<style lang="scss" scoped>
	.helpful-webinar- {
		&wrapper {
			@apply flex flex-row items-center justify-between shadow-stan-box-shadow overflow-hidden rounded-xl bg-white mb-4 hover:cursor-pointer p-4 gap-3;
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col max-w-[50%];
		}
		&title {
			@apply capitalize break-words break-all line-clamp-2 overflow-hidden text-ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.webinar {
			@apply ml-auto mr-1 p-2 hover:bg-stan-gray-light;
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.buttons-wrapper {
		@apply ml-auto flex flex-row gap-1 items-center;
	}
	.helpful-webinar-button {
		@apply rounded-md hover:bg-stan-gray-light p-1;
	}
	.dropdown-button {
		cursor: pointer;
		@apply bg-white rounded-full mt-2 mr-2;
	}

	.dropdown-menu {
		cursor: pointer;
		position: absolute;
		right: 32px;
		bottom: -80px;
		z-index: 1000;
		background: white;
		box-shadow: var(--stan-box-shadow);
	}

	.dropdown-menu ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.dropdown-menu li {
		margin: 8px;
		padding: 10px 10px;
		display: flex;
	}

	.dropdown-menu li:hover {
		transition: ease 0.2s;
		background-color: var(--stan-gray-light-color);
	}

	.dropdown-menu li a {
		text-decoration: none;
		color: var(--stan-black-1);
	}

	.dropdown-menu li a:hover {
		background: var(--stan-white-3);
	}

	.delete-dropdown {
		min-width: 150px;
	}
</style>
