import { EventEmitter } from 'events'

export default class EventStreamService extends EventEmitter {
	constructor(options) {
		super()
		this.options = options
		this.streamApiUrl = options.streamApiUrl
		this.token = null

		if (this.streamApiUrl === '') {
			throw new Error('not valid Event Stream Service API URL.')
		}

		this.streamAuthUrl = `${this.streamApiUrl}/api/v1/auth`
		this.streamFeedsUrl = `${this.streamApiUrl}/api/v1/feeds`
		this.streamTestToogleUrl = `${this.streamApiUrl}/api/v1/test`

		this.sseClient = null
		this.sseChannels = []
		this.reconnectTimeout = null
		this.streamTest = false

		this.emit = this.emit.bind(this)
		this.eventListener = this.eventListener.bind(this)
	}

	setToken(token) {
		this.token = token
	}

	channels() {
		return this.sseChannels
	}

	async connect() {
		if (this.sseClient !== null) {
			return
		}
		if (this.token === null) {
			return
		}
		const resp = await fetch(this.streamAuthUrl, {
			credentials: 'include',
			headers: {
				Authorization: `Bearer ${this.token}`,
			},
		})
		await resp.json()

		this.sseClient = new EventSource(this.streamFeedsUrl, { withCredentials: true })

		this.sseClient.onopen = ev => {
			this.emit('open', ev)
		}

		this.sseClient.onerror = ev => {
			this.emit('error', ev)
			this.reconnectTimeout = setTimeout(() => {
				this.sseClient.close()
				this.sseClient = new EventSource(this.streamFeedsUrl, { withCredentials: true })
			}, 10000)
		}

		this.sseClient.addEventListener('system', ev => {
			this.sseChannels = []
			const data = JSON.parse(ev.data)
			if (data.channels && Array.isArray(data.channels)) {
				for (let i = 0; i < data.channels.length; i += 1) {
					this.sseChannels.push(data.channels[i])
					this.sseClient.addEventListener(data.channels[i], this.eventListener, false)
				}
			}
		})

		this.sseClient.addEventListener('message', this.eventListener, false)
	}

	async disconnect() {
		if (this.sseClient !== null && typeof this.sseClient !== 'undefined') {
			this.sseClient.close()
			this.sseClient = null
		}
		this.reconnectTimeout = null
	}

	eventListener(ev) {
		const data = typeof ev.data === 'string' ? JSON.parse(ev.data) : typeof ev.data === 'object' ? ev.data : null

		if (data) this.emit('message', { type: ev.type, data })
		else console.error("Event data isn't available")
	}
}
