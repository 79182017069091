<template>
	<Transition name="fade" :key="hasCommunityBaseLoaded">
		<div class="max-w-4xl w-full mx-auto h-full" v-if="hasCommunityBaseLoaded">
			<RouterView :id="`page-${currentRouteName}`" class="h-100 page-wrapper" />
		</div>
		<div v-else class="flex h-full w-full justify-center items-center">
			<SolidDotsSpinner :dot-size="10" color="var(--community-primary-color)" />
		</div>
	</Transition>
</template>
<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useGlobalsStore } from '@/stores/globals'
	import prepareStoresMixin from './PrepareStoreMixin'

	export default {
		name: 'MinimalContainer',
		mixins: [prepareStoresMixin],
		created() {
			this.globalStore.setPageLoading(false)
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			globalStore() {
				return useGlobalsStore()
			},
			hasCommunityBaseLoaded() {
				return this.communityStore.self && this.communityPublicStore.hasLoaded
			},
		},
	}
</script>
<style lang="scss" scoped></style>
