export default {
	addThemeColorsToDocument: (theme = {}) => {
		document.documentElement.style.setProperty(
			'--community-primary-color',
			theme['community-primary-color'] || 'var(--stan-primary-primary-color)'
		)
		document.documentElement.style.setProperty(
			'--community-primary-strike-color',
			theme['community-primary-strike-color'] || 'var(--stan-primary-strike-color)'
		)
		document.documentElement.style.setProperty(
			'--community-primary-dark-color',
			theme['community-primary-dark-color'] || 'var(--stan-primary-dark-color)'
		)
		document.documentElement.style.setProperty(
			'--community-primary-soft-color',
			theme['community-primary-soft-color'] || 'var(--stan-primary-soft-color)'
		)
		document.documentElement.style.setProperty(
			'--community-primary-light-color',
			theme['community-primary-light-color'] || 'var(--stan-primary-light-color)'
		)

		if (theme['community-reaction-text-color'] === 'dark') {
			document.documentElement.style.setProperty('--community-reaction-text-color', 'var(--stan-text-dark-color)')
		} else document.documentElement.style.setProperty('--community-reaction-text-color', 'var(--stan-white)')
		if (theme['community-primary-d-text-color'] === 'dark') {
			document.documentElement.style.setProperty('--community-primary-d-text-color', 'var(--stan-text-dark-color)')
		} else document.documentElement.style.setProperty('--community-primary-d-text-color', 'var(--stan-white)')
	},
}
