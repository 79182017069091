<template>
	<AppCard>
		<div class="product-card-container">
			<img :src="product.image" />
			<div class="details">
				<div class="content">
					<div class="title para-1 text-bold">{{ product.title }}</div>
					<div class="description text-light para-2">{{ product.tagline }}</div>
					<!-- <div class="date para-3" v-if="!isCommunityOwner && purchaseDate">Purchased on {{ purchaseDate }}</div> -->
				</div>

				<AppButton v-if="!productIsCommunity || isCommunityOwner" @click="accessOrEditProduct" class="mt-auto" :loading="loading" outline
					>{{ buttonLabel }}
				</AppButton>
				<AppButton v-else @click="accessOrEditProduct" class="mt-auto" :loading="loading" :disabled="true"> Selected </AppButton>
			</div>
		</div>
	</AppCard>
</template>

<script setup>
	import { ref, onUpdated, defineProps, computed, inject } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import { useProductsStore } from '@/stores/products'
	import constants from '@/global_helper/constants'
	import { configSettings } from '@/plugins/configSettings'

	const props = defineProps({
		product: {
			type: Object,
			required: false,
		},
	})

	const stanAnalytics = inject('stanAnalytics')
	const purchaseDate = ref(null)
	const loading = ref(false)

	const communityStore = computed(() => useCommunityStore())
	const productStore = computed(() => useProductsStore())
	const isCommunityOwner = computed(() => communityStore.value.self.type === constants.MEMBER_TYPES.OWNER)
	const buttonLabel = computed(() => (isCommunityOwner.value ? 'Edit' : 'Access'))
	const productIsCommunity = computed(() => props.product.type === constants.PRODUCT_TYPE.COMMUNITY)
	onUpdated(() => {
		purchaseDate.value = props.product.purchase_date
	})

	const downloadFile = (fileName, url) => {
		const a = document.createElement('a')
		a.href = url
		a.download = fileName
		a.target = '_blank'
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
	}

	const openProduct = () => {
		window.open(
			`${configSettings.adminDomain}/stores/${props.product.store_id}/page/${props.product.type}/${props.product.page_id}?order=0`,
			'blank'
		)
	}

	const access = async () => {
		const product = props.product
		if (product.type == constants.PRODUCT_TYPE.DIGITAL_DOWNLOAD) {
			if (product.digital_download?.digital_assets) {
				product.digital_download?.digital_assets.forEach(asset => {
					const opened = window.open(asset.url, '_blank')
					if (!opened) {
						alert('Please allow popups for this website!')
					}
				})
			}
		} else if (props.product.type == constants.PRODUCT_TYPE.COURSE) {
			loading.value = true
			const link = await productStore.value.getPreAuthCourseLink(props.product.product_id)
			loading.value = false
			if (link) {
				opened = window.open(link, '_blank')
				if (!opened) {
					alert('Please allow popups for this website!')
				}
			}
		}
	}
	const accessOrEditProduct = () => {
		if (isCommunityOwner.value) {
			stanAnalytics('community-product-owner-open')
			openProduct()
		} else {
			stanAnalytics('community-product-fan-open')
			access()
		}
	}
</script>
<style lang="scss" scoped>
	.card {
		width: 340px;
	}

	img {
		@apply aspect-video object-cover;
	}

	.product-card-container {
		@apply p-0.5 flex flex-col w-full overflow-hidden;
		height: 100%;
	}

	.details {
		@apply p-4 grow flex flex-col gap-5;

		.content {
			@apply gap-2;
			flex: 0 1 auto;
			@apply flex flex-col;

			.title {
				@apply line-clamp-3;
			}

			.description {
				@apply -tracking-24 text-stan-text-dark line-clamp-5 overflow-hidden;
				max-height: 82px; //5 lines of text
				flex: 0 1 auto;
			}
			//Passive Profits Accelerator
			.date {
				@apply -tracking-24 text-stan-text-light;
			}
		}

		.button {
			@apply w-full mt-auto;
		}
	}
</style>
