import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'

// eslint-disable-next-line no-use-before-define
const router = createRouterInstance()

export default router

// Load middleware modules dynamically.
// eslint-disable-next-line no-use-before-define
async function afterEach() {
	// await router.app.$nextTick()
	// router.app.$loading.finish()
}

function resolveComponents(components) {
	return Promise.all(components.map(component => (typeof component === 'function' ? component() : component)))
}

function scrollBehavior(to, from, savedPosition) {
	if (savedPosition) {
		return savedPosition
	}

	if (to.hash) {
		return { selector: to.hash }
	}

	const [component] = to.matched.slice(-1)

	if (component && component.scrollToTop === false) {
		return {}
	}

	return { x: 0, y: 0 }
}

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middlewares, index) {
	const subsequentMiddleware = middlewares[index]
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) return context.next

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters)
		// Than run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middlewares, index + 1)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}

async function beforeEach(to, from, next) {
	if (to.meta?.middlewares) {
		const middleware = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]

		const importedMiddlewares = await Promise.all(middleware.map(middleware => import(`@/middleware/${middleware}.js`)))
		const middlewares = importedMiddlewares.map(middleware => middleware.default || middleware)

		const context = {
			from,
			next,
			router,
			to,
		}
		// todo.save from path when go to the Search page for return
		const nextMiddleware = nextFactory(context, middlewares, 1)

		return middlewares[0]({ ...context, next: nextMiddleware })
	}

	return next()
}

function createRouterInstance() {
	const router = createRouter({
		scrollBehavior,
		history: createWebHistory(),
		routes: [...routes],
	})

	router.beforeEach(beforeEach)
	router.afterEach(afterEach)
	return router
}
