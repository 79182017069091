import commonHelper from '@/global_helper/helpers'

export default {
	data() {
		return {
			isMobile: false,
		}
	},
	// PrepareStores.js with before route in rotues handles getting inital data store data
	mounted() {
		this.resize = () => {
			this.isMobile = commonHelper.isMobile(false)
		}
		this.resize()
		window.addEventListener('resize', this.resize)
	},
}
