import { defineStore } from 'pinia'

export const useElementsStore = defineStore('elements', {
	state: () => ({
		_create_post_element: null,
		_header_element: null,
		_resources_with_wizard: false,
		_resource_page_has_loaded: false,
	}),
	getters: {
		createPostElement: state => state._create_post_element,
		headerElement: state => state._header_element,
		resourcesWithWizard: state => state._resources_with_wizard,
		resourcePageHasLoaded: state => state._resource_page_has_loaded,
	},
	actions: {
		setCreatePostElement(el) {
			this._create_post_element = el
		},
		setHeaderElement(el) {
			this._header_element = el
		},
		setResourcesWithWizard(val) {
			this._resources_with_wizard = val
		},
		setResourcePageHasLoaded(val) {
			this._resource_page_has_loaded = val
		},
	},
})
