import { useCommunityStore } from '@/stores/communities'
import { useCommunityPublicStore } from '@/stores/public'
import { useProductsStore } from '@/stores/products'
import { useAuthStore } from '@/stores/auth'
import { useMembershipStore } from '@/stores/membership'
import { useResourceStore } from '@/stores/resources'

const prepareStores = async (to, from, next) => {
	const communitySlug = to.params.communitySlug

	const authStore = useAuthStore()
	const communityStore = useCommunityStore()
	const membershipStore = useMembershipStore()
	const communityPublicStore = useCommunityPublicStore()
	const productsStore = useProductsStore()
	const resourceStore = useResourceStore()
	const publicStorePromise = communityPublicStore.setCurrentCommunity(to.params.communitySlug)

	// Only load other stores once authorized
	if (authStore.token) {
		// Start loading community if not set, canged or not already fully loaded
		if (!communityStore.currentCommunity || communityStore.currentCommunity?.slug !== communitySlug || !communityStore.hasCommunityLoaded) {
			communityStore.setCurrentCommunity(communitySlug)
		}

		if (productsStore.currentCommunitySlug !== communitySlug) {
			productsStore.setCurrentCommunity(communitySlug)
		}

		if (membershipStore.currentCommunitySlug !== communitySlug) {
			membershipStore.setCurrentCommunity(communitySlug)
		}

		if (resourceStore.currentCommunitySlug !== communitySlug) {
			resourceStore.setCurrentCommunity(communitySlug)
		}
	}
	try {
		await publicStorePromise
	} catch (err) {
		return next({ name: '404' })
	}
	return next()
}
export default prepareStores
