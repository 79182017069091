/* eslint-disable no-underscore-dangle, no-unused-expressions, consistent-return */

export const portalProxyList = []

export function getParentProxy(proxy) {
	if (Object(proxy.$parent) === proxy.$parent) {
		return proxy.$parent
	}

	let { parent } = proxy.$

	while (Object(parent) === parent) {
		if (Object(parent.proxy) === parent.proxy) {
			return parent.proxy
		}

		parent = parent.parent
	}
}

export function getPortalProxy(el) {
	return portalProxyList.find(proxy => proxy.contentEl !== null && proxy.contentEl.contains(el))
}

export function closePortals(proxy, evt, depth) {
	while (depth !== 0 && proxy !== void 0 && proxy !== null) {
		if (proxy.__pPortal === true) {
			depth -= 1

			proxy.hide(evt)
		}

		proxy = getParentProxy(proxy)
	}
}
