<template>
	<div class="h-full flex justify-center items-center">
		<div class="flex flex-col w-[500px] p-6">
			<h1 class="mb-4 text-stan-text-dark text-3xl font-extrabold leading-none -tracking-56">Name Your Community</h1>
			<div class="mb-[66px] text-stan-text-light text-sm leading-none -tracking-28">You can always change this later!</div>

			<AppInput
				class="mb-[66px]"
				v-model="communityName"
				placeholder="Your Community Name!"
				:disableAutoComplete="true"
				:disabled="loading"
				maxlength="100"
				label="Name"
				:error="v$.communityName.$errors"
			>
			</AppInput>

			<AppButton class="w-min text-base" @click="updateName" :loading="loading">Create</AppButton>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useCommunityStore } from '@/stores/communities'
	import constants from '@/global_helper/constants'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		data() {
			return {
				communityName: this.currentCommunity?.data?.name || '',
				loading: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			currentCommunity() {
				return this.communityStore.currentCommunity
			},
			canSubmit() {
				return !!this.communityName
			},
			isOwner() {
				return this.communityStore.isOwner
			},
		},
		validations() {
			return {
				communityName: {
					required: helpers.withMessage('Enter a valid name', required),
				},
			}
		},
		methods: {
			async updateName() {
				this.loading = true
				const isFormCorrect = await this.v$.$validate()

				if (isFormCorrect) {
					if (this.isOwner) {
						let onboardingState = { ...this.communityStore.onboardingState }
						onboardingState[constants.COMMUNITY_ONBOARDING.COMMUNITY_NAME] = true
						await this.communityStore.updateCommunityData({
							name: this.communityName,
							status: constants.COMMUNITY_STATUS.ACTIVE,
							onboarding_state: onboardingState,
						})

						await this.communityPublicStore.setCurrentCommunity(this.communityStore.currentCommunity.slug, true)
						this.communityStore.getCommunity()

						// Go to home!
						this.$router.push({ name: 'home' })
					}
				}
				this.loading = false
			},
		},
		watch: {
			currentCommunity: {
				immediate: true,
				handler(newVal) {
					if (newVal && newVal.data) {
						this.communityName = `${newVal?.data?.name}`
					}
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.card {
		height: 535px;
		width: 340px;
	}

	img {
		border-radius: 8px 8px 2px 2px;
		@apply aspect-square object-cover;
	}

	.product-card-container {
		@apply p-1.5 flex flex-col w-full overflow-hidden;
		aspect-ratio: 328/525;
	}

	.details {
		@apply p-4 flex flex-col grow;
		gap: 20px;

		.content {
			gap: 12px;
			@apply flex flex-col grow;

			.title {
				@apply font-bold text-gray-900;
			}

			.description {
				@apply -tracking-24 text-stan-text-dark overflow-auto;
				flex: 1 1 10px;
			}
			//Passive Profits Accelerator
			.date {
				@apply -tracking-24 text-stan-text-light;
			}

			.button {
				@apply w-full;
			}
		}
	}
</style>
