<template>
  <div class="dropdown-menu">
    <template v-if="items.length">
      <button
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        <AppUserImage :name="item.data?.full_name" :src="item.data?.profile_image" :color="item.data?.color" size="sm" :key="item.data?.profile_image" />
        {{ item.data.full_name }}
      </button>
    </template>
    <div class="item" v-else>
      No result
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
      this.scrollToSelectedItem()
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      this.scrollToSelectedItem()
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
    scrollToSelectedItem() {
      this.$nextTick(() => {
        const container = this.$el
        const selectedItem = container.querySelector(
          `.dropdown-menu button:nth-child(${this.selectedIndex + 1})`
        )

        if (selectedItem) {
          const containerRect = container.getBoundingClientRect()
          const itemRect = selectedItem.getBoundingClientRect()

          if (itemRect.top < containerRect.top) {
            container.scrollTop -= containerRect.top - itemRect.top;
          } else if (itemRect.bottom > containerRect.bottom) {
            container.scrollTop += itemRect.bottom - containerRect.bottom;
          }
        }
      });
    },
    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item.community_member_id, label: item.data.full_name })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* Dropdown menu */
.dropdown-menu {
  @apply h-36;
  width: 300px;
  background-color: var(--stan-white-2);
  border: 1px solid var(--stan-gray-strike-color);
  border-radius: 0.7rem;
  box-shadow: var(--stan-box-shadow);
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow: scroll;
  position: relative;

  @apply py-2;

  .item {
    @apply px-4 py-0.5 font-medium;
  }

  button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 0.5rem;
    text-align: left;
    width: 100%;
    @apply px-4 py-0.5 font-medium;

    &:hover,
    &:hover.is-selected {
      background-color: var(--stan-primary-primary-color);
      color: white;
    }

    &.is-selected {
      background-color: var(--stan-primary-primary-color);
      color: white;
    }
  }
}
</style>