<template>
	<div class="solid-dots-spinner" :style="spinnerStyle">
		<div class="dot" v-for="(ds, index) in dotsStyles" :style="ds" :key="index"></div>
	</div>
</template>
<script>
	export default {
		props: {
			animationDuration: {
				type: Number,
				default: 1000,
			},
			dotSize: {
				type: Number,
				default: 15,
			},
			color: {
				type: String,
				default: '#fff',
			},
		},
		computed: {
			horizontalMargin() {
				return this.dotSize / 2
			},
			spinnerStyle() {
				return {
					height: `${this.dotSize}px`,
					width: `${(this.dotSize + this.horizontalMargin * 2) * 4}px`,
				}
			},
			dotStyle() {
				return {
					width: `${this.dotSize}px`,
					height: `${this.dotSize}px`,
					background: this.color,
				}
			},
			dotsStyles() {
				const dotsStyles = []
				const delayModifier = 0.3
				const basicDelay = 1000
				for (let i = 1; i <= 4; i++) {
					const style = this.dotStyle

					dotsStyles.push(style)
				}
				return dotsStyles
			},
		},
	}
</script>
<style lang="scss" scoped>
	.solid-dots-spinner {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 13px;
		.dot {
			position: absolute;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			animation-timing-function: cubic-bezier(0, 1, 1, 0);
			&:nth-child(1) {
				left: 8px;
				animation: lds-ellipsis1 0.6s infinite;
			}
			&:nth-child(2) {
				left: 8px;
				animation: lds-ellipsis2 0.6s infinite;
			}
			&:nth-child(3) {
				left: 32px;
				animation: lds-ellipsis2 0.6s infinite;
			}
			&:nth-child(4) {
				left: 56px;
				animation: lds-ellipsis3 0.6s infinite;
			}
		}
	}
	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}
	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(24px, 0);
		}
	}
</style>
