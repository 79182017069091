<template>
	<div v-if="isAudio" :class="digitalAssetType(file)">
		<AppAudioPlayer :name="name" :time="time" :src="src" :message-list="true" :options="{ uploaded: true }"></AppAudioPlayer>
	</div>
	<a v-else class="post-mixed-media-attachement-container" @click="$emit('click')">
		<div v-if="isImage" class="image">
			<img :src="src" />
		</div>
		<div v-else-if="isVideo" class="image relative">
			<template v-if="videoImageLoading">
				<div class="flex justify-center items-center h-full">
					<SolidDotsSpinner color="var(--community-primary-color)" :dot-size="10" />
				</div>
			</template>
			<template v-else>
				<img :src="videoImage" />
				<div id="video-mask-src" class="art-mask rounded-xl">
					<div class="art-state">
						<img src="https://assets.stanwith.me/graphics/video-play.svg" class="ml-2 play-icon" alt="playIcon" />
					</div>
				</div>
			</template>
		</div>
	</a>
</template>
<script>
	import constants from '@/global_helper/constants'
	import { digitalAssetType } from '@/components/shared/utils'

	import { isImageProcessing, preloadImage, getOptimizeImageUrl, checkImage } from '@/components/shared/OptimizationImageTools'

	export default {
		name: 'PostMixedMediaAttachement',
		data() {
			return {
				videoImageLoading: true,
				videoImage: '',
				checkVideoPreviewInterval: null,
				isVideoPreviewImageReady: false,
				checkingVideoPreview: false,
			}
		},
		emits: ['click'],
		props: {
			type: {
				type: String,
				description: 'type of attachment',
			},
			name: {
				type: String,
				default: '',
				description: 'File name to show',
			},
			src: {
				type: String,
				description: 'Source data for attachment',
			},
			time: {
				type: String,
				description: 'Time in case of audio file',
			},
		},
		mounted() {
			this.preloadvideoImage()
		},
		computed: {
			isAudio() {
				return this.type.startsWith(constants.MEDIA_TYPES.AUDIO)
			},
			isVideo() {
				return this.type.startsWith(constants.MEDIA_TYPES.VIDEO)
			},
			isImage() {
				return this.type.startsWith(constants.MEDIA_TYPES.IMAGE)
			},
		},
		methods: {
			digitalAssetType,
			async checkVideoPreviewImage(src) {
				// Check and retries video preview load
				if (!this.checkingVideoPreview) {
					this.checkingVideoPreview = true
					this.isVideoPreviewImageReady = await checkImage(src)

					if (this.isVideoPreviewImageReady) {
						clearInterval(this.checkVideoPreviewInterval)
						this.checkVideoPreviewInterval = null
					}
					if (!this.preloadImageStart) {
						this.preloadvideoImage()
					}

					this.checkingVideoPreview = false
				}
				if (this.isVideoPreviewImageReady) {
					clearInterval(this.checkVideoPreviewInterval)
					this.checkVideoPreviewInterval = null
				}
			},
			async preloadvideoImage() {
				// attempts to load preview image, if it does not exist yet then does a check in 3 seconds
				this.preloadImageStart = true
				const image = `${this.src}/preview.jpg`
				this.isVideoPreviewImageReady = await checkImage(image)
				if (this.isVideoPreviewImageReady) {
					if (!isImageProcessing(this.src)) {
						this.videoImage = image ?? this.videoImage
						this.videoImageLoading = false
						return
					}
					const optimizeImageOptions = {
						format: 'webp',
						quality: 80,
						width: 433,
					}
					const optimizeImageSrc = await getOptimizeImageUrl(image, {
						...optimizeImageOptions,
					})
					preloadImage(optimizeImageSrc)
						.then(() => {
							this.videoImage = optimizeImageSrc ?? this.videoImage
							this.videoImageLoading = false
						})
						.catch(err => {
							this.videoImage = image ?? this.videoImage
							this.videoImageLoading = false
						})
				} else if (this.checkVideoPreviewInterval === null) {
					this.checkVideoPreviewInterval = setInterval(() => {
						this.checkVideoPreviewImage(image)
					}, 3000)
				}
				this.preloadImageStart = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.post-mixed-media-attachement-container {
		border: 1px solid var(--stan-gray-soft-color);
		@apply w-full overflow-hidden rounded-lg hover:cursor-pointer;
		.image {
			@apply w-full min-h-96;
			img {
				@apply object-cover  h-full w-full max-h-full max-w-full;
			}
		}
	}

	.art-mask {
		display: flex;
		align-items: center;
		bottom: 0;
		height: 100%;
		justify-content: center;
		left: 0;
		overflow: hidden;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 3;
		background: rgba(0, 0, 0, 0.25);
		.art-state {
			display: flex;
			width: 60px;
			height: 60px;
			border-radius: 100%;
			align-items: center;
			justify-content: center;
			position: absolute;
			opacity: 1 !important;
			right: auto !important;
			bottom: auto !important;
			background: #9f9f9f9e;
			animation: slideup 1.2s ease-in-out;
			z-index: 5;
			svg {
				transform: translateX(3px);
			}
			.play-icon {
				height: 30px;
				width: 22px;
			}
		}
	}
</style>
