import EventStreamService from '@/services/useEventStreamService'

const ssePlugin = {
	install: (app, options) => {
		const { streamApiUrl, authStore } = options
		if (!streamApiUrl) {
			throw {
				name: 'StreamApiUrlNotFound',
				message: 'ssePlugin: param streamApiUrl not passed',
			}
		}
		if (!authStore) {
			throw { name: 'AuthStoreNotFound', message: 'ssePlugin: param authStore not passed' }
		}
		const sseEmitter = new EventStreamService({ streamApiUrl })

		app.config.globalProperties.$sse = sseEmitter // Optionally, make the SSE emitter available globally

		// Provide access to the SSE emitter for components using provide/inject
		app.provide('sseEmitter', sseEmitter)
		authStore.$subscribe((mutation, state) => {
			if (state._token != sseEmitter.token) {
				sseEmitter.disconnect()
				if (state._token) {
					sseEmitter.setToken(state._token)
					sseEmitter.connect()
				}
			}
		})

		window.addEventListener('beforeunload', () => {
			sseEmitter.disconnect()
		})

		sseEmitter.on('error', err => {
			console.error(err)
		})
	},
}

export default ssePlugin
