<template>
	<div class="header-container">
		<div v-show="!headerImageLoaded" class="h-16 md:h-60 w-screen bg-stan-gray-dark" />
		<Banner ref="headerImg" v-show="!repositioning && headerImageLoaded" :imageSrc="this.communityPublicStore.banner_image" />

		<HeaderImageEditor
			ref="headerImgEditor"
			v-if="isOwner"
			:showButtons="showButtons || headerHighlighted"
			:repositioning="repositioning"
			@open-unsplash-image-picker="openUnsplashImagePicker"
			@triggered-reposition="isOn => (repositioning = isOn)"
			@update-header-image="updateHeaderImage"
		/>
		<UnsplashImagePickerModal
			ref="unsplashImagePickerModal"
			@unsplashImagePicked="onUnsplashImagePicked"
			@close-modal="$emit('cancel-unsplash')"
		/>
	</div>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'

	export default {
		name: 'Header',
		props: {
			isOwner: Boolean,
			showButtons: Boolean,
			headerHighlighted: Boolean,
		},
		data() {
			return {
				repositioning: false,
				headerImageLoaded: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
		},
		methods: {
			openUnsplashImagePicker() {
				this.$refs.unsplashImagePickerModal.init()
			},
			async onUnsplashImagePicked(payload) {
				this.communityPublicStore.setBannerImage(payload.src)
				await this.communityStore.updateCommunityData({ image: payload.src })
				await this.communityPublicStore.getCommunity()
			},
			updateHeaderImage(topPercent) {
				this.$refs.headerImg.$el.getElementsByTagName('img')[0].style.objectPosition = `50% ${topPercent}%`
			},
		},
		watch: {
			'communityStore.currentCommunity': {
				immediate: true,
				deep: true,
				handler() {
					this.headerImageLoaded = true
				},
			},
			headerHighlighted: {
				handler(newValue) {
					if (!this.isOwner) return
					if (newValue) {
						this.$refs.headerImg.$el.getElementsByTagName('img')[0].style.zIndex = 700
					} else {
						this.$refs.headerImg.$el.getElementsByTagName('img')[0].style.zIndex = 'initial'
						this.repositioning = false
					}
				},
			},
		},
	}
</script>

<style lang="scss">
	.header-container {
		position: relative;
	}
</style>
