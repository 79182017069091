<template>
	<div class="relative">
		<div v-show="repositioning" class="h-16 md:h-60 w-screen relative hidden lg:block overflow-hidden">
			<img
				class="absolute w-full object-cover z-[0]"
				ref="draggableImage"
				:src="this.communityPublicStore.banner_image"
				@mousedown.prevent="beginRepositioning"
				@mouseup.prevent="endRepositioning"
				@mousemove.prevent="getDimensions"
				@mouseleave.prevent="endRepositioning"
				draggable
			/>
			<div v-if="!isMobile" class="w-full h-full flex items-center justify-center">
				<div class="w-64 h-14 relative z-[2]">
					<div class="rounded-full px-6 py-4 bg-[rgba(0,0,0,0.5)] text-white text-center text-sm">
						<div class="flex justify-center items-center select-none">Drag to Reposition</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="absolute bottom-2 sm:bottom-4 left-0 ml-32 sm:ml-8 justify-around gap-1 z-[4] hidden lg:flex action-button-wrapper"
			:class="{ 'show-buttons': showButtons || isMobile }"
		>
			<template v-if="!repositioning">
				<AppButton color="light" :size="isMobile ? 'xs' : 'sm'" @click="openUnsplashImagePicker">
					<AppIcon src="edit" class="action-button"></AppIcon>
					<span class="hidden md:block">Edit</span>
				</AppButton>
				<AppButton color="light" :size="isMobile ? 'xs' : 'sm'" @click="triggerReposition(true)">
					<AppIcon src="image" class="action-button"></AppIcon>
					<span class="hidden md:block">Reposition</span>
				</AppButton>
			</template>
			<template v-else>
				<AppButton color="light" :size="isMobile ? 'xs' : 'sm'" @click="saveRepositioning">
					<AppIcon src="check-circle" class="action-button"></AppIcon>
					<span class="hidden md:block">Save Position</span>
				</AppButton>
				<AppButton color="light" :size="isMobile ? 'xs' : 'sm'" @click="cancelRepositioning" class="action-button">
					<AppIcon src="x" class="action-button"></AppIcon>
					<span class="hidden md:block">Cancel</span>
				</AppButton>
			</template>
		</div>
	</div>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import prepareStoresMixin from '../../layouts/PrepareStoreMixin'
	import mobileCheckMixin from '../../layouts/MobileCheckMixin'
	import { useCommunityPublicStore } from '@/stores/public'

	export default {
		name: 'HeaderImage',
		mixins: [prepareStoresMixin, mobileCheckMixin],
		props: {
			showButtons: Boolean,
			repositioning: Boolean,
		},
		data() {
			return {
				dragging: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			downOffsetFactor() {
				return this.isMobile ? 64 : 242
			},
		},
		methods: {
			triggerReposition(isOn) {
				this.$emit('triggered-reposition', isOn)
				this.$nextTick(() => {
					if (isOn) {
						const img = this.$refs.draggableImage
						const [_, verticalOffset] = this.communityStore.currentCommunity.data.image_position
						const topInPx = (verticalOffset / 100) * (img.clientHeight - this.downOffsetFactor)
						img.style.top = -topInPx + 'px'
					}
				})
			},
			beginRepositioning(e) {
				this.dragging = true
			},
			endRepositioning(e) {
				this.dragging = false
			},
			getDimensions(e) {
				if (this.dragging) {
					const deltaY = e.movementY
					const img = this.$refs.draggableImage
					const rect = img.getBoundingClientRect()

					const canGoDown = Math.abs(rect.y + deltaY) <= img.offsetHeight - this.downOffsetFactor && e.movementY < 0
					const canGoUp = rect.y + deltaY <= 0 && e.movementY > 0
					const shouldKeepDragging = canGoDown || canGoUp

					if (shouldKeepDragging) {
						img.style.top = rect.y + deltaY + 'px'
					}
				}
			},
			cancelRepositioning() {
				const img = this.$refs.draggableImage
				img.style.top = 'unset'
				this.triggerReposition(false)
			},
			async saveRepositioning() {
				try {
					const img = this.$refs.draggableImage

					this.triggerReposition(false)
					const topInPx = parseInt(img.style.top.split('px')[0])
					const topPercent = (Math.abs(topInPx) * 100) / (img.clientHeight - this.downOffsetFactor)
					this.$emit('update-header-image', topPercent)

					await this.communityStore.updateCommunityData({
						image_position: [50, topPercent],
					})
					await this.communityPublicStore.getCommunity()
					this.$notify({
						type: 'Success',
						title: 'Saved',
						text: 'Your new position was successfully saved',
					})
				} catch (err) {
					this.$notify({
						type: 'error',
						title: 'Error',
						text: 'There was an issue saving your position. Please try again later',
					})
				}
			},
			openUnsplashImagePicker() {
				this.$emit('open-unsplash-image-picker')
			},
		},
	}
</script>

<style lang="scss">
	.action-button-wrapper {
		@apply opacity-0 transition-all;
		&.show-buttons {
			@apply opacity-100;
		}
		.action-button {
			path {
				fill: var(--community-primary-d-text-color);
			}
		}
	}
</style>
