<template>
	<div class="flex justify-center items-center h-full w-full">
		<div class="flex flex-col justify-center items-center">
			<h1 class="mb-4 text-stan-text-dark text-3xl font-extrabold leading-none -tracking-56">Community Coming Soon!</h1>
			<div class="mb-[66px] text-stan-text-light text-sm leading-none -tracking-28">Just making some final tweaks</div>

			<Construction />
		</div>
	</div>
</template>

<script>
	import { defineAsyncComponent } from 'vue'
	export default {
		components: {
			Construction: defineAsyncComponent(() => import('@/assets/images/construction.svg')),
		},
	}
</script>

<style lang="scss"></style>
