export const SSE_EVENT_TYPE = {
	MESSAGE_SENT: 'stan-event-community-message-sent',
	DELETE_MESSAGE: 'stan-event-community-delete-message',
	CREATE_CHANNEL: 'stan-event-community-create-channel',
	CHANGE_CHANNEL_NAME: 'stan-event-community-change-channel-name',
	ADD_CHANNEL_PARTICIPANT: 'stan-event-community-add-channel-participant',
	ADD_REACTION: 'stan-event-community-add-reaction',
	REMOVE_REACTION: 'stan-event-community-remove-reaction',
	DATA_UPDATED: 'stan-event-community-data-updated',
	ADD_RESOURCE: 'stan-event-community-add-resource',
	UPDATE_RESOURCE: 'stan-event-community-update-resource',
	DELETE_RESOURCE: 'stan-event-community-delete-resource',
	NEW_NOTIFICATION: 'stan-event-community-new-notification',
}

// TODO: Fix targeted event types
export const SSE_CHANNEL_TYPE_PREFIXES = ['feeds.channels', 'feeds.communities', 'feeds.members']
