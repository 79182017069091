<template>
	<div
		class="comment-wrapper mt-4 relative"
		:class="{ 'has-sub-comments': shouldShowSubComments && subComments.length > 0 }"
		:id="message.message_id"
	>
		<div class="comment-image-details-wrapper">
			<AppUserImage class="my-2" :name="sender?.data.full_name" :src="sender?.data.profile_image" :color="sender?.data.color" size="md" />
			<div class="comment-details-wrapper" :class="{ 'w-100': isEditing }">
				<div class="comment-text-wrapper">
					<div class="flex flex-row items-self-start gap-1">
						<SenderNameAndTime class="flex-grow self-baseline" size="sm" :sender="sender" :time="time" />
						<EditDeleteCommentDropdown
							v-if="showActions"
							:message="message"
							@handleEditComment="onEditComment"
							@handleDeleteComment="onDeleteComment"
						/>
					</div>
					<AppCard
						v-if="isEditing"
						class="flex flex-row w-full box-border gap-x-3 items-center justify-start mt-3 mb-1 border-2 border-stan-gray-light px-3"
					>
						<EditorContent :editor="editor" class="grow editor" />
						<AppButton
							class="comment-send-button"
							color="primary"
							link
							size="xs"
							@click="updateComment"
							:loading="loading"
							:disabled="!hasContent"
						>
							<AppIcon src="send" class="send-icon" />
						</AppButton>
					</AppCard>
					<div v-if="!isEditing" class="comment para-2" v-html="content"></div>
				</div>
				<PostReactionsList
					:reactions="reactions"
					:comment-id="message.message_id"
					:onlyLikes="true"
					@reply-to-comment="replyToComment"
					v-if="showReactions && message"
				/>
			</div>
		</div>
		<SubComments v-if="shouldShowSubComments" :comments="subComments" :key="subComments.length" @reply-to-subcomment="scrollToReply" />
		<div class="pl-12" :class="{ 'mt-2': commentReplyOpened }">
			<CommentReply
				v-show="!isSubComment && commentReplyOpened"
				:message-id="message.message_id"
				:loading="commentReplyLoading"
				isInline
				@comment-replied="sendReply"
				ref="mainCommentReply"
				placeholder="Post your reply..."
			/>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted, nextTick, toRaw } from 'vue'
	import { DateTime } from 'luxon'
	import EditDeleteCommentDropdown from './EditDeleteCommentDropdown.vue'
	import StarterKit from '@tiptap/starter-kit'
	import Link from '@tiptap/extension-link'
	import Placeholder from '@tiptap/extension-placeholder'
	import { Editor, EditorContent } from '@tiptap/vue-3'
	import constants from '@/global_helper/constants'
	import { usePost } from './PostComposable'
	const props = defineProps({
		messageId: {
			type: Number,
			required: true,
		},
		parentMessageId: {
			type: Number,
			required: true,
		},
		showReactions: {
			type: Boolean,
			default: true,
			required: false,
		},
		isSubComment: {
			type: Boolean,
			default: false,
			required: false,
		},
		shouldShowSubComments: {
			type: Boolean,
			default: true,
			required: false,
		},
		showActions: {
			type: Boolean,
			default: true,
			required: false,
		},
		isFullComment: {
			type: Boolean,
			default: false,
			required: false,
		},
		channelName: {
			type: String,
			required: false,
		},
	})
	const { communityStore, message, reactions } = usePost(props.messageId, props.parentMessageId, props.channelName)
	const emit = defineEmits(['reply-to-subcomment', 'comments-loaded'])
	const mainCommentReply = ref(null)

	const isEditing = ref(false)
	const requestedMessage = ref({})

	const loading = ref(false)
	const commentReplyOpened = ref(false)
	const commentReplyLoading = ref(false)
	const editor = ref(null)
	const sender = computed(() => communityStore.members[message.value.sender_id])

	// Will update via SSE
	const subComments = computed(() => Object.values(communityStore.currentChannelThreads[message.value.message_id]?.messages ?? {}))

	const content = ref(message.value?.content || '')
	const hasContent = computed(() => content.value && content.value !== constants.TIPTAP_EMPTY_MESSAGE)

	const time = computed(() =>
		message.value.timestamp ? DateTime.fromSeconds(message.value.timestamp) : DateTime.fromSeconds(new Date().getTime() / 1000)
	)

	onMounted(async () => {
		if (props.shouldShowSubComments) {
			if (subComments.value.length == 0) {
				// Try to load subcomments in case none are loaded yet
				await communityStore.getThreadMessages({}, message.value.message_id, communityStore.currentChannel?.name)
			}
		}
		emit('comments-loaded')
		editor.value = new Editor({
			extensions: [
				StarterKit,
				Link.configure({
					openOnClick: false,
					autolink: true,
					defaultProtocol: 'https',
					protocols: ['https', 'mailto'],
				}),
				Placeholder.configure({
					placeholder: 'Write something...',
				}),
			],
			content: content.value,
			onUpdate: () => {
				content.value = editor.value.getHTML()
			},
		})
	})

	const scrollToReply = async () => {
		commentReplyOpened.value = true
		await nextTick()
		mainCommentReply.value.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
	}
	const replyToComment = async () => {
		if (!props.isSubComment) {
			commentReplyOpened.value = !commentReplyOpened.value
			if (commentReplyOpened.value) {
				await nextTick()
				mainCommentReply.value.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
			}
		} else {
			emit('reply-to-subcomment')
		}
	}
	const sendReply = async cont => {
		commentReplyLoading.value = true
		try {
			await communityStore.sendMessage(cont, null, message.value.message_id)
		} catch (e) {
			alert('Failed to send message')
		}
		const threads = await communityStore.getThreadMessages({}, message.value.message_id, communityStore.currentChannel.name)
		commentReplyOpened.value = false
		commentReplyLoading.value = false
	}
	const onEditComment = message => {
		isEditing.value = true
		requestedMessage.value = message
	}

	const onDeleteComment = message => {
		communityStore.deleteMessage(message)
	}

	const updateComment = async () => {
		loading.value = true

		const contentPayload = {
			content: content.value,
			mentions: [],
		}

		// Update Comments
		await communityStore.editMessage(contentPayload, toRaw(message.value))

		setTimeout(() => {
			loading.value = false
			isEditing.value = false
		}, 2000)
	}
</script>

<style lang="scss" scoped>
	.comment-wrapper {
		&.has-sub-comments {
			> .comment-image-details-wrapper {
				> .comment-details-wrapper {
					&::before {
						content: '';
						@apply h-full w-0 border-l-2 border-stan-gray-dark -left-8 top-10 absolute;
					}
				}
			}
		}
		.comment-image-details-wrapper {
			@apply flex gap-4 w-full;
			.comment-details-wrapper {
				@apply flex flex-col relative grow gap-2 min-w-0;
				.comment-text-wrapper {
					@apply flex flex-col gap-1  bg-white p-3 pr-6 sm:p-4 rounded-xl w-full shadow-stan-box-shadow;
				}

				.comment {
					@apply text-stan-text-dark -tracking-35 leading-normal whitespace-pre-wrap break-words;
				}
			}
		}
		.comment-send-button {
			@apply border-0 min-w-5;
			&:disabled {
				:deep(.send-icon) {
					path {
						fill: var(--community-primary-light-color);
					}
				}
			}
			:deep(.send-icon) {
				path {
					fill: var(--community-primary-color);
				}
			}
		}
		.editor {
			@include para-1;
			@apply p-2.5 rounded-lg overflow-x-hidden overflow-y-scroll max-h-40;
			:deep(.tiptap) {
				p {
					&.is-editor-empty {
						&:first-child {
							&::before {
								color: #adb5bd;
								content: attr(data-placeholder);
								float: left;
								height: 0;
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}
</style>
