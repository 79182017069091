<template>
	<AppModal
		ref="addLinksModal"
		title=""
		:action="canOpenIntroModal ? 'Next' : 'Enter Community'"
		:loading="loading"
		:backdrop="backdrop"
		@cta="updateProfileDetails"
		@close="close"
	>
		<div class="text-center">
			<p class="my-2 text-[--stan-text-light-color] text-base font-normal leading-4">Welcome to</p>
			<h3 class="mb-2 text-[--stan-blue-9] text-3xl font-extrabold leading-7 title">
				{{ activeCommunity.data.name }}
			</h3>
			<span class="text-[--stan-text-light-color] text-sm font-normal leading-5">A couple of quick things to get started!</span>
		</div>

		<div class="label-wrapper col mt-5">
			<label class="control-label -tracking-24">Profile Picture</label>
		</div>
		<div class="profile-sub-secion-right-image">
			<div class="profile-photo-wrapper mb-3" id="pick-avatar">
				<a class="cursor-pointer profile-photo-wrapper mb-3" @click="uploadLinkImage">
					<AppUserImage :src="link_image" :name="user_name" class="lg rounded-full mb-5" />
					<div class="abs-add-image">
						<img src="@/assets/icons/add-image-filled.svg?url" class="img-fluid" />
					</div>
				</a>
			</div>
		</div>

		<!-- User Name Input -->
		<AppInput v-model="user_name" label="Your Display Name" placeholder="Name" :error="v$.user_name.$errors" :maxlength="50" />

		<!-- Resize Image Modal -->
		<AppUploadImage key="AddLinksModal" v-model="link_image" ref="uploadLinkImage" @uploaded="data => fileUploaded(data)" scope="user">
		</AppUploadImage>
	</AppModal>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers, url, maxLength } from '@vuelidate/validators'
	import axios from 'axios'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
		},
		emits: ['showCommunityIntroModal'],
		data() {
			return {
				user_name: null,
				link_image: '',
				loading: false,
				canOpenIntroModal: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			async self() {
				return await this.communityStore.self
			},
			activeCommunity() {
				return this.communityStore.currentCommunity
			},
		},
		validations() {
			return {
				user_name: {
					required: helpers.withMessage('Name is required', required),
				},
			}
		},
		methods: {
			uploadLinkImage() {
				this.$refs.uploadLinkImage.triggerPickfile()
			},
			fileUploaded(fileData) {
				this.link_image = fileData.src
				this.$refs.uploadLinkImage.hide()
			},
			async updateProfileDetails() {
				// Checking form validation
				const isFormCorrect = await this.v$.$validate()

				if (!isFormCorrect) {
					return
				}

				try {
					this.loading = true

					// Update Profile API
					await axios.put(`v1/communities/${this.communityStore.currentCommunity.slug}/self`, {
						full_name: this.user_name,
						profile_image: this.link_image,
					})

					this.$notify({
						type: 'Success',
						title: `Done!`,
						text: `Your profile have been updated successfully.`,
					})

					// Model Close
					this.close()

					// Stop Loader
					this.loading = false

					this.communityStore.getSelf()
					this.communityStore.getMembers()

					if (this.isOwner) {
						this.$nextTick(async () => {
							await this.communityStore.setCurrentCommunity(this.communityStore.currentCommunity.slug)
						})
					}
					this.$emit('updated')

					if (this.canOpenIntroModal) {
						this.$emit('showCommunityIntroModal')
					}
				} catch (error) {
					if (error.response?.data?.message) {
						this.$notify({ type: 'error', text: error.response?.data?.message })
					}
				}
			},
			show() {
				this.$refs.addLinksModal.show()
			},
			close() {
				this.$refs.addLinksModal.hide()
			},
		},
		watch: {
			self: {
				immediate: true,
				async handler(newVal) {
					newVal
						.then(resolvedVal => {
							if (resolvedVal && resolvedVal.data) {
								this.user_name = resolvedVal.data.full_name ? `${resolvedVal.data.full_name}` : ''
								this.link_image = resolvedVal.data.profile_image ? `${resolvedVal.data.profile_image}` : ''
							}
						})
						.catch(error => {
							console.error('Error resolving promise:', error)
						})
				},
			},
			activeCommunity: {
				immediate: true,
				handler(newVal) {
					if (newVal && newVal.data) {
						this.canOpenIntroModal = !!(newVal.data.introduction && newVal.data.introduction.length >= 20)
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.profile-sub-secion-right-image {
		max-width: 100px;

		.profile-photo-wrapper {
			position: relative;

			.abs-add-image {
				position: absolute;
				right: 20px;
				bottom: -8px;
				display: inline-block;

				img {
					width: 20px;
				}
			}
		}
	}

	.title {
		@apply -tracking-30;
		text-transform: capitalize;
		color: var(--Text-Dark, --stan-blue-9);
		font-family: 'Plus Jakarta Sans';
		font-size: 32px;
		font-style: normal;
		font-weight: 800;
		line-height: 100%;
	}
</style>
