<template>
	<AppCard class="overflow-visible" v-if="message" :id="message.message_id">
		<div class="post-container">
			<div class="post-wrapper">
				<div class="flex flex-row justify-between items-center">
					<div class="flex flex-row gap-2 items-center text-center">
						<AppUserImage :name="sender?.data?.full_name" :src="sender?.data?.profile_image" :color="sender?.data?.color" size="md" />
						<SenderNameAndTime :sender="sender" :time="time" />
						<div v-if="tags[message.tag_id]" class="para-3 -tracking-40 sm:pt-1 leading-none">
							in <span class="text-bold"> {{ tags[message.tag_id].name }}</span>
						</div>
					</div>
					<div class="flex flex-row items-center gap-5 h-full">
						<div v-if="isPinned" class="flex flex-row gap-1 para-2 items-center text-bold text-community-primary">
							<AppIcon src="pin-fill" class="sm color-primary" />
							<span class="hidden md:inline"> Pinned </span>
						</div>
						<EditPostDropdownV2
							v-if="(canDelete || canEdit) && menuEnabled"
							:canDelete="canDelete"
							:canEdit="canEdit"
							@deletePost="deleteMessage"
							@togglePin="pinMessage"
							:isPinned="isPinned"
							@editPost="editPost"
						/>
					</div>
				</div>
				<transition name="fade">
					<div v-if="processedContent && !isEditing" class="content para-2" v-html="processedContent"></div>
					<div v-else-if="isEditing" class="pt-4">
						<TipTapEditor
							v-model:value="content"
							hasToolbar
							:should-show-image-picker="false"
							:should-show-file-picker="false"
							placeholder="Write something..."
							editor-wrapper-class="content-wrapper"
							toolbar-class="!h-10 !px-2 !mt-0"
						>
						</TipTapEditor>
						<div class="flex flex-col md:flex-row justify-end w-full gap-4">
							<AppButton outline @click="cancelEdit">Cancel</AppButton>
							<AppButton @click="savePost" :loading="loading" :disabled="content === constants.TIPTAP_EMPTY_MESSAGE">Save</AppButton>
						</div>
					</div>
				</transition>

				<div class="media para-2" v-if="assets.length && !isEditing">
					<PostAssets :assets="assets" inline @assetClicked="viewPost" @moreClicked="viewPost" />
				</div>

				<PostReactionsList v-if="!isEditing && reactionsEnabled" class="mt-2" :reactions="reactions" :only-likes="false" />
			</div>
		</div>
		<SingleCommentSection
			:showComment="quickReply"
			:quickReply="quickReply"
			class="mx-0.5 mb-0.5"
			:messageId="message?.message_id"
			@viewAll="viewPost"
		/>
	</AppCard>
</template>
<script setup>
	import { DateTime } from 'luxon'
	import { usePost } from './PostComposable'
	import { useRouter } from 'vue-router'
	import { ref, computed, defineProps, defineComponent, toRaw } from 'vue'
	import EditPostDropdownV2 from '@/components/post/EditPostDropdownV2.vue'
	import constants from '@/global_helper/constants'
	import TipTapEditor from '../shared/TipTapEditor.vue'
	import AppButton from '../shared/AppButton.vue'

	defineComponent({ components: { EditPostDropdownV2 } })
	const props = defineProps({
		messageId: {
			type: Number,
			required: true,
		},
		quickReply: {
			type: Boolean,
			required: false,
			default: true,
		},
		menuEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
		reactionsEnabled: {
			type: Boolean,
			required: false,
			default: true,
		},
	})
	const { communityStore, message, reactions } = usePost(props.messageId)

	const content = ref('')
	const loading = ref(false)
	const time = ref(DateTime.now())
	const assets = ref([])
	const isEditing = ref(false)

	const router = useRouter()

	const selfId = computed(() => communityStore.self.community_member_id)
	const canModifyMessages = computed(() => communityStore.canModifyMessages)
	const members = computed(() => communityStore.members)
	const tags = computed(() => communityStore.tags)
	const status = computed(() => message.value?.status)
	const isPinned = computed(() => status.value === constants.MESSAGE_STATUS.PINNED)
	const sender = computed(() => communityStore.members[message.value.sender_id])
	const canDelete = computed(() => selfId.value === message.value.sender_id || canModifyMessages.value)
	const canEdit = computed(() => selfId.value === message.value.sender_id)
	const processedContent = computed(() => {
		if (!content.value) return ''
		let tempContent = content.value

		const regex = /<([a-zA-Z]+)([^>]*?)data-id="(\d+)"(.*?)>(.*?)<\/\1>/g

		tempContent = tempContent.replace(regex, (match, tag, before, id, after, innerHTML) => {
			const fullName = members.value[id]?.data?.full_name || 'Unknown Member'
			const mentionClass = selfId.value === parseInt(id) ? 'current-user-mention' : 'mention'

			// Update the class attribute in the `before` string
			const updatedBefore = before.replace(/class="([^"]*)"/, (classMatch, classNames) => `class="${mentionClass}"`)

			// If no class attribute exists, add it
			const finalBefore = updatedBefore.includes('class=') ? updatedBefore : `${updatedBefore} class="${mentionClass}"`

			return `<${tag}${finalBefore}${after}>@${fullName}</${tag}>`
		})

		return tempContent
	})
	// New messages won't have a timestamp
	time.value = message.value.timestamp ? DateTime.fromSeconds(message.value.timestamp) : new Date()
	content.value = message.value.content
	assets.value = message.value.data?.digital_assets || []

	function deleteMessage() {
		communityStore.deleteMessage(message.value)
	}

	function editPost() {
		isEditing.value = !isEditing.value
	}

	async function savePost() {
		loading.value = true
		await communityStore.editMessage(
			{
				content: content.value,
				mentions: [],
			},
			toRaw(message.value)
		)
		loading.value = false
		isEditing.value = false
	}

	const cancelEdit = () => {
		isEditing.value = false
		content.value = message.value.content
	}

	function pinMessage() {
		try {
			const newStatus = isPinned.value ? constants.MESSAGE_STATUS.ACTIVE : constants.MESSAGE_STATUS.PINNED
			communityStore.updateMessageStatus(message.value, newStatus)
		} catch (error) {
			if (error.response?.data?.message) {
				this.$notify({ type: 'error', title: 'Uh Oh!', text: error.response?.data?.message })
			}
		}
	}

	const viewPost = () => {
		if (message.value?.message_id) {
			router.push({
				name: 'post',
				params: {
					messageId: message.value.message_id,
				},
			})
		}
	}
</script>

<style lang="scss" scoped>
	.post-container {
		@apply flex flex-row gap-3 bg-white p-5 rounded-xl w-full break-words;

		.post-wrapper {
			@apply flex flex-col gap-2 bg-white w-full;
			:deep(.content-wrapper) {
				@apply flex w-full gap-3 pt-4 mb-4 mx-5 overflow-auto box-border max-h-[80vh];
				max-width: calc(100% - 2.5rem);

				.editor {
					@apply h-full grow box-border;
					border: none;
					display: flex;
					padding-top: 3px;

					.ql-tooltip {
						z-index: 1;
					}

					.tiptap {
						@apply w-full p-0 box-border;

						&::before {
							@apply font-medium not-italic left-0 text-stan-gray-dark;
						}

						* {
							@apply text-stan-text-dark;
							font-family: Inter, sans;
							font-size: 1rem;
							line-height: 1.375rem;
						}

						a {
							@apply underline;
						}
						.mention {
							@apply text-community-primary;
						}
						.current-user-mention {
							@apply text-community-strike;
						}

						code {
							font-size: 0.875em;
						}

						pre.ql-syntax {
							background-color: var(--stan-oxford-blue-1);
							color: var(--stan-white);
							padding: 8px 16px;
							border-radius: 5px;
						}
					}

					a {
						color: var(--stan-primary-primary-color);
					}

					blockquote {
						border-left: 4px solid var(--stan-primary-soft-color);
						padding-left: 1rem;
						margin-top: 4px;
						margin-bottom: 4px;

						& + blockquote {
							margin-top: -4px;
						}
					}
				}
			}
			.content {
				@apply pl-9;
			}
			.media {
				@apply w-full overflow-hidden my-2 pl-9;
				:deep(.post-mixed-media-attachement-container) {
					.image {
						@apply h-full;
					}
				}
			}
			.post-reactions-list-container {
				@apply pl-9;
			}
		}
	}
</style>
