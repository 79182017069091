<template>
	<AppModalFrame
		ref="modal"
		class="app-modal"
		@ctaClick="ctaClick"
		:submitOnEnter="submitOnEnter"
		:closeOnBlur="closeOnBlur"
		:modalStyles="modalStyles"
	>
		<!-- Header -->

		<slot name="header">
			<h3 class="text-bold header" v-if="title">{{ title }}</h3>
		</slot>

		<slot name="subHeader">
			<div class="subHeader sticky" v-if="subheading">
				<div class="para-2">{{ subheading }}</div>
			</div>
		</slot>

		<!-- Main content -->
		<div class="content" :style="contentStyles">
			<slot name="default"></slot>
		</div>
		<!-- Action Buttons -->
		<div class="actions" v-if="action">
			<AppButton :loading="loading" :disabled="disabled" @click="ctaClick" :color="actionColor" v-if="action">{{ action }}</AppButton>
			<AppButton class="p-0 h-auto" link :disabled="disabled" :color="secondaryActionColor" @click="secondaryCtaClick" v-if="secondaryAction">{{
				secondaryAction
			}}</AppButton>
		</div>
	</AppModalFrame>
</template>
<script>
	export default {
		name: 'AppModal',
		props: {
			id: {
				type: String,
				default: '',
			},
			title: {
				type: String,
				default: '',
			},
			subheading: {
				type: String,
				default: undefined,
			},
			action: {
				type: String,
				default: '',
			},
			actionColor: {
				type: String,
				default: 'primary',
			},
			secondaryAction: {
				type: String,
				default: '',
			},
			secondaryActionColor: {
				type: String,
				default: 'primary',
			},
			// Drop?
			extraLarge: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			large: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			frame: {
				type: Boolean,
				default: false,
				description: 'Used to remove all formatting and offer empty canvas',
			},
			fade: {
				type: Boolean,
				default: true,
				description: 'Whether the modal should fade in/out',
			},
			position: {
				type: String,
				default: 'center',
			},
			loading: {
				type: Boolean,
				default: false,
				description: 'Used to have the button on the modal show loading hollow dot spinners',
			},
			disableAction: {
				type: Boolean,
				default: false,
				description: 'Used to disable the button on the modal',
			},
			isVideo: {
				type: Boolean,
				default: false,
				description: 'Used to indicate if the modal is used as a video player',
			},
			isImagePreview: {
				type: Boolean,
				default: false,
				description: 'Used to indicate if the modal is used as a video player',
			},
			closeOnBlur: {
				type: Boolean,
				default: false,
				description: 'Used to determine as clicking outside modal should close',
			},
			hideClose: {
				type: Boolean,
				default: false,
				description: 'Used to hide the close button in case we want a non-dismissable modal.',
			},
			overflow: {
				type: Boolean,
				default: false,
				description: 'Used to determine if the modal should allow overflow.',
			},
			backdrop: {
				type: Boolean,
				default: true,
				description: 'Used to determine if the background should be shown or not',
			},
			submitOnEnter: {
				type: Boolean,
				default: true,
				description: 'Used to determine if pressing the Enter key should submit',
			},
			modalStyles: {
				type: Object,
				default: () => ({}),
				description: 'Apply styles to modal',
			},
			contentStyles: {
				type: Object,
				default: () => ({}),
				description: 'Apply styles to content body',
			},
		},
		computed: {
			sizeClass() {
				if (this.extraLarge) return 'modal-xl'
				if (this.large) return 'modal-lg'
				return ''
			},
			disabled() {
				return this.disableAction || this.loading
			},
			modal() {
				return this.$refs.modal
			},
		},
		methods: {
			show() {
				this.modal.show()
			},
			hide() {
				this.modal.hide()
			},
			ctaClick() {
				this.$emit('cta')
			},
			secondaryCtaClick() {
				this.$emit('secondary-cta')
			},
		},
	}
</script>
<style lang="scss" scoped>
	.app-modal {
		:deep(.modal) {
			@apply flex flex-col justify-between gap-3;
		}
	}

	.close-container {
		@apply flex w-full justify-end;

		.close {
			@apply flex w-7 h-7 bg-white rounded-full justify-center items-center cursor-pointer;
		}
	}

	.header {
		@apply mb-8 w-full text-center;
		h3 {
			@apply mt-0;
		}
	}

	.subHeader {
		@apply m-auto mb-2 w-10/12 text-center text-stan-text-light;
	}

	.content {
		@apply flex flex-col grow;
	}

	.actions {
		//d-flex flex-column gap v-gap mt-3 pt-1
		@apply flex flex-col w-full gap-4 mt-4;
	}
</style>
