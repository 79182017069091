<template>
	<notifications :position="notificationPosition" :style="notificationStyle" animation-type="css" animation-name="v-fade-top" width="90%">
		<template #body="props">
			<StanNotification :data="props.item" @close="props.close" />
		</template>
	</notifications>
</template>

<script>
	export default {
		computed: {
			notificationStyle() {
				return ''
			},
			notificationPosition() {
				return 'top center'
			},
		},
	}
</script>

<style lang="scss">
	.v-fade-top-enter-active,
	.v-fade-top-leave-active,
	.v-fade-top-move {
		transition: all 0.8s ease;
	}
	.v-fade-top-enter,
	.v-fade-top-leave-to {
		opacity: 0;
		transform: translateY(-200px) scale(0.75);
	}
	.vue-notification-wrapper {
		&:first-child {
			margin-top: 32px;
		}
	}
</style>
