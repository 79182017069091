<template>
	<div class="mb-3 w-full rounded-xl">
		<h6 class="mb-0">
			<button
				class="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
				@click="toggleDropdown"
			>
				<h1>{{ title }} {{ toggled ? '-' : '+' }}</h1>
			</button>
		</h6>
		<div v-if="toggled" class="">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import { useGlobalsStore } from '@/stores/globals'
	import { useCommunityStore } from '@/stores/communities'

	export default {
		name: 'AppAccordian',
		props: {
			title: {
				type: String,
				default: 'TITLE HERE',
			},
		},
		computed: {},
		created() {},
		data() {
			return {
				toggled: false,
			}
		},
		methods: {
			toggleDropdown() {
				this.toggled = !this.toggled
			},
		},
		watch: {},
	}
</script>
<style lang="scss" scoped></style>
