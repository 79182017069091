/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import axios from 'axios'
import constants from '@/global_helper/constants'

// eslint-disable-next-line import/prefer-default-export
export const useMembershipStore = defineStore('membership', {
	state: () => ({
		_memberships: {},
		_loading: false,
		_current_community_slug: null,
	}),
	getters: {
		status: state => state._memberships[state._current_community_slug]?.status,
		validUntil: state => state._memberships[state._current_community_slug]?.valid_until,
		ccLast4: state => state._memberships[state._current_community_slug]?.credit_card_last_4_digits,
		ccBrand: state => state._memberships[state._current_community_slug]?.credit_card_brand,
		billingUpdateLink: state => state._memberships[state._current_community_slug]?.billing_link,
		loading: state => state._loading,
		currentCommunitySlug: state => state._current_community_slug,
	},
	actions: {
		getMembership() {
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/membership`)
					.then(response => {
						this._memberships[this._current_community_slug] = response.data.membership
						resolve(this._memberships)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
					.finally(() => {
						this._loading = false
					})
			})
		},
		async setCurrentCommunity(communitySlug) {
			this._current_community_slug = communitySlug
			// Tries to get community data if don't already have it
			if (!this._memberships[communitySlug]) {
				await this.getMembership()
			}
		},
		async cancelMembership() {
			try {
				await axios.post(`v1/communities/${this._current_community_slug}/membership/cancel`).then(response => {
					this._memberships[this._current_community_slug] = response.data.membership
				})

				return true
			} catch (err) {
				console.error(err)
				return false
			}
		},
	},
})
