<template>
	<div class="header-container">
		<!-- TODO: add async loading of smaller image first -->
		<img class="absolute" :src="imageSrc" :style="imgStyle" />
	</div>
</template>

<script>
	import { useGlobalsStore } from '@/stores/globals'
	import { useCommunityStore } from '@/stores/communities'

	export default {
		name: 'Banner',
		props: {
			imageSrc: {
				type: String,
			},
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			imgStyle() {
				const [horizontalOffset, verticalOffset] = this.communityStore.currentCommunity.data?.image_position || [0, 50]
				return {
					objectPosition: `${horizontalOffset}% ${verticalOffset}%`,
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.header-container {
		@apply h-16 md:h-60 w-screen relative;

		img {
			@apply h-16 md:h-60 w-full object-cover justify-center;
		}
	}
</style>
