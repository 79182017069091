import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import 'emoji-picker-element'
import VueVirtualScroller from 'vue-virtual-scroller'
import Notifications from '@kyvg/vue3-notification'
import VueClipboard from 'vue3-clipboard'
import { configSettings } from '@/plugins/configSettings'
import '@/plugins'
import setGlobalComponents from '@/plugins/setGlobalComponents'
import AppClosePopup from '@/components/directives/AppClosePopup'
import commonHelper from '@/global_helper/helpers'
import themeHelper from '@/global_helper/theme'
import constants from '@/global_helper/constants'
import debounce from '@/plugins/debounce'
import appVersion from '../app-version.json'
import ssePlugin from '@/plugins/ssePlugin'
import stanAnalytics from './plugins/stan-analytics'
import { useAuthStore } from '@/stores/auth'
import { vOnClickOutside } from '@vueuse/components'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import ImagePreview from '@/components/shared/ImagePreview'
import router from './router'
import App from './App.vue'
import '@/assets/styles/variables.css'
import '@/assets/styles/index.scss'
import Clarity from '@microsoft/clarity'

const app = createApp(App)

app.config.globalProperties.$configSettings = configSettings
app.config.globalProperties.$commonHelper = commonHelper
app.config.globalProperties.$themeHelper = themeHelper
app.config.globalProperties.$constants = constants

app.config.globalProperties.$appVersion = appVersion
Clarity.init(configSettings.clarityTrackingCode)

const pinia = createPinia()
pinia.use(({ store }) => {
	store.$authApiUrl = configSettings.authApiUrl
	store.$flaskApiUrl = configSettings.flaskApiUrl
	store.$commonHelper = commonHelper
	store.$themeHelper = themeHelper
	store.$constants = constants
	store.$sse = app.config.globalProperties.$sse
})
app.use(pinia)
app.use(router)
app.use(setGlobalComponents)
app.use(debounce)
app.use(Notifications)
app.use(VueClipboard, { autoSetContainer: true, appendToBody: true })
app.use(VueVirtualScroller)
app.use(ssePlugin, { streamApiUrl: configSettings.sseStreamApiUrl, authStore: useAuthStore() })
app.use(stanAnalytics)
app.directive('close-popup', AppClosePopup)
app.directive('on-click-outside', vOnClickOutside)
app.component('ImagePreview', ImagePreview)

if (configSettings.env_name === 'staging' || configSettings.env_name === 'production') {
	Sentry.init({
		app,
		environment: configSettings.env_name,
		dsn: configSettings.sentryDsn,
		tracesSampleRate: 0,
		release: configSettings.releaseVersion,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.5,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					'localhost',
					'https://community.stan.store/',
					'https://community.staging.stan.store/',
					'https://community.uat.stan.store/',
				],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
		],
		beforeSend(event) {
			let ignoreEvent = false
			if (event?.exception?.values?.length > 0) {
				event?.exception?.values?.forEach(ex => {
					if (ex.type === 'NavigationDuplicated') {
						ignoreEvent = true
					}
				})
			}
			if (ignoreEvent) return null

			return event
		},
	})
}

app.mount('#app')
