export function createEditChannelInState(formattedChannel, store) {
	const currChannel = store._communities[store._current_community_slug].channels[formattedChannel.name]

	if (currChannel) {
		formattedChannel.threads = currChannel.threads
		formattedChannel.messages = currChannel.messages
	}
	store._communities[store._current_community_slug].channels[formattedChannel.name] = formattedChannel

	return formattedChannel
}

export function addChannelParticipantInState(formattedParticipant, store) {
	store._communities[store._current_community_slug].channels[store._current_channel_name].participants.push(formattedParticipant)
}
