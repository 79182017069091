<template>
	<input :key="tick" type="file" :accept="accepts" :multiple="multiple" style="display: none" ref="fileInput" @change="onFilePicked" />
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import useUploadFile from '@/components/composables/useUploadFile'

	export default {
		props: {
			accepts: { type: String },
			multiple: {
				type: Boolean,
				default: () => false,
			},
			confirmUpload: { type: Boolean, default: true },
			objectType: { type: String, default: 'unknown' },
			scope: { type: String, default: 'default' },
			maxFilesAllowed: { type: Number, default: undefined },
		},
		data() {
			return {
				url: null,
				tick: 0,
				files: [],
			}
		},
		methods: {
			onPickFile() {
				this.$refs.fileInput.click()
			},
			onFilePicked(event) {
				const files = event.target.files || event.dataTransfer.files
				if (!files || files.length === 0) {
					return
				}
				this.files = Array.from(files)
				this.files.forEach(f => {
					f.metadata = { ...f.metadata, id: f.name + Date.now() }
				})
				if (this.maxFilesAllowed !== undefined && this.files.length > this.maxFilesAllowed) {
					// To allow max size
					this.files.splice(this.maxFilesAllowed, Infinity)
					this.$emit('errorUp', {
						error: `You can only upload 5 files at a time.`,
					})
				}
				this.$emit('filePicked', this.files)
				if (this.confirmUpload) this.uploadAllFiles()
			},
			uploadAllFiles(filesToUpload) {
				const files = filesToUpload || this.files
				if (!files) return
				files.forEach(f => this.uploadFile(f))
			},
			uploadFile(file) {
				const uploader = useUploadFile({ file, scope: this.scope, objectType: this.objectType, emit: this.$emit })
				uploader.addListener('assetUp', () => {
					this.tick += 1
					this.automateUpload = false
				})
			},
			clearFile() {
				this.$refs.fileInput.value = ''
			},
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},

			fileUploadMaxBytes() {
				return 10 ** 9
			},
		},
	}
</script>
