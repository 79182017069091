<template>
	<Transition name="fade">
		<div class="m-0 p-0 h-full w-full overflow-scroll flex flex-col relative" key="main">
			<div class="relative" @mouseenter="displayButtons" @mouseleave="hideButtons">
				<Header :isOwner="isOwner" :showBut :showButtons="showHeaderEditButtons" />
			</div>

			<Transition name="fade" :key="hasCommunityBaseLoaded">
				<div class="max-w-4xl w-full mx-auto" v-if="hasCommunityBaseLoaded">
					<RouterView :id="`page-${currentRouteName}`" class="h-100 page-wrapper" />
				</div>
				<div v-else class="flex h-full w-full justify-center items-center">
					<SolidDotsSpinner :dot-size="10" color="var(--community-primary-color)" />
				</div>
			</Transition>
		</div>
	</Transition>
</template>
<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useProductsStore } from '@/stores/products'
	import { useGlobalsStore } from '@/stores/globals'
	import { useAuthStore } from '@/stores/auth'
	import constants from '@/global_helper/constants'
	import prepareStoresMixin from './PrepareStoreMixin'

	export default {
		name: 'MinimalContainer',
		mixins: [prepareStoresMixin],
		data() {
			return {
				searchInput: '',
				stackedModalOpen: 0,
				mentionMemberId: undefined,
				showHeaderEditButtons: false,
			}
		},
		created() {
			this.globalStore.setPageLoading(false)
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			authStore() {
				return useAuthStore()
			},
			globalStore() {
				return useGlobalsStore()
			},
			navigationType() {
				return this.globalStore.navigationType
			},
			showNavigation() {
				return this.globalStore.showNavigation
			},
			communities() {
				return this.communityStore.communities
			},
			self() {
				return this.communityStore.self
			},
			isOwner() {
				return this.communityStore.isOwner
			},
			canModifyChannels() {
				return this.communityStore.canModifyChannels
			},
			isCreatorTeamMember() {
				return ['owner', 'moderator', 'admin'].indexOf(this.communityStore.self.type) > -1
			},
			currentCommunity() {
				return this.communityStore.currentCommunity
			},
			currentCommunityAccess() {
				return this.currentCommunity.data?.settings?.dm_level
			},
			currentChannel() {
				return this.communityStore.currentChannel
			},
			currentRouteName() {
				return this.$route.name
			},
			communityMembers() {
				return this.communityStore.members
			},
			mentionMemberDetails() {
				return this.communityStore.members[this.mentionMemberId]
			},
			showAddEditChannelModal() {
				return this.globalStore.showAddEditChannelModal
			},
			isEditingChannel() {
				return this.globalStore.isEditingChannel
			},
			channelUnreadStatus() {
				return this.communityStore.channels_unread_status
			},
			isActivityUnread() {
				return this.communityStore.isActivityUnread
			},
			tabs() {
				const tabs = [
					{ id: 'home', title: 'Community' },
					{ id: 'products', title: 'My Products' },
				]
				return tabs
			},
			hasCommunityBaseLoaded() {
				return this.communityStore.self && this.communityPublicStore.hasLoaded
			},
		},
		methods: {
			onTabSelected(selected) {
				this.$router.push({ name: selected })
			},
			onSettingsClicked() {
				this.$router.push({ name: 'settings' })
			},
			onLogoutClicked() {
				this.authStore.logout()
			},
			displayButtons() {
				this.showHeaderEditButtons = true
			},
			hideButtons() {
				this.showHeaderEditButtons = false
			},
			checkSetupCommunityName() {
				// If the community name has not been setup, perform false check since it will be undefined while loading
				if (
					this.communityStore &&
					this.communityStore.onboardingState &&
					this.communityStore.onboardingState[constants.COMMUNITY_ONBOARDING.COMMUNITY_NAME] == false
				) {
					this.$router.push({ name: 'setup' })
				}
			},
		},
		mounted() {
			this.checkSetupCommunityName()
		},
		watch: {
			hasCommunityBaseLoaded: {
				handler() {
					this.checkSetupCommunityName()
				},
			},
		},
	}
</script>
<style lang="scss" scoped></style>
