<template>
	<Transition name="fade" :css="fade">
		<div
			:id="id"
			:key="modalKey"
			ref="backdrop"
			class="backdrop"
			:class="[
				{
					background: backdrop,
				},
			]"
			v-if="visible"
		>
			<AppCard ref="modal" class="modal" :class="[widthClass, { padding: padding }]" :style="modalStyles">
				<slot></slot>
				<div class="close-container">
					<div class="close" aria-label="Close" @click="hide">
						<AppIcon class="close-icon" src="close" />
					</div>
				</div>
			</AppCard>
		</div>
	</Transition>
</template>
<script>
	import { getRandomUUID } from '@/components/utils/uuid'
	export default {
		name: 'AppModalFrame',
		data() {
			return {
				visible: false,
				backdropListener: null,
				modalListener: null,
			}
		},
		props: {
			id: {
				type: String,
				default: getRandomUUID(),
			},
			fade: {
				type: Boolean,
				default: true,
				description: 'Whether the modal should fade in/out',
			},
			backdrop: {
				type: Boolean,
				default: true,
				description: 'Used to determine if the background should be shown or not',
			},
			padding: {
				type: Boolean,
				default: true,
			},
			extraLarge: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			large: {
				type: Boolean,
				default: false,
				description: 'Used to make the modal look wider',
			},
			modalStyles: {
				type: Object,
				default: () => ({}),
				description: 'Apply styles to modal',
			},
			submitOnEnter: {
				type: Boolean,
				default: true,
				description: 'Used to determine if pressing the Enter key should submit',
			},
			closeOnBlur: {
				type: Boolean,
				default: true,
				description: 'Used to determine as clicking outside modal should close',
			},
		},
		computed: {
			widthClass() {
				if (this.extraLarge) return 'modal-xl'
				if (this.large) return 'modal-lg'
				return ''
			},
			modalKey() {
				return `modal-${this.id}`
			},
		},
		watch: {
			visible(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.$emit(newVal ? 'open' : 'close')
				}
				if (newVal && this.$commonHelper.isMobile()) {
					document.body.classList.add('overflow-hidden')
				} else {
					document.body.classList.remove('overflow-hidden')
				}

				// Add/remove listeners to auto close modal
				this.$nextTick(() => {
					setTimeout(() => {
						if (newVal) {
							this.addModalEventHandlers()
						} else {
							this.removeModalEventHandlers()
						}
					}, 100)
				})
			},
		},
		methods: {
			show() {
				this.visible = true
			},
			hide() {
				this.visible = false
			},
			addModalEventHandlers() {
				if (this.$refs?.modal && this.closeOnBlur) {
					document.addEventListener('click', this.backdropClickCallback)
				}
				document.addEventListener('keydown', this.keypressEventHandler)
			},
			removeModalEventHandlers() {
				if (this.closeOnBlur) {
					document.removeEventListener('click', this.backdropClickCallback)
				}
				document.removeEventListener('keydown', this.keypressEventHandler)
			},
			keypressEventHandler(event) {
				if (event.key === 'Escape') {
					this.hide()
				} else if (event.key === 'Enter' && this.submitOnEnter) {
					this.$emit('ctaClick')
				}
			},
			backdropClickCallback(event) {
				let closeModal = true

				// don't close due to hidden file input clicks
				const target = event.target
				if (target.tagName.toLowerCase() === 'input' && target.type === 'file') {
					return
				}
				if (this.$refs.modal) {
					const r = this.$refs.modal.$el.getBoundingClientRect() // Position relative to screen top left is 0,0
					const clickX = event.clientX
					const clickY = event.clientY

					if (r.top < clickY && clickY < r.bottom && r.left < clickX && clickX < r.right) {
						closeModal = false
					}
				}

				if (closeModal && this.closeOnBlur) {
					this.hide()
					event.preventDefault()
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.backdrop {
		@apply fixed inset-0 z-50 overflow-scroll flex;
	}

	.background {
		@apply bg-black-overlay;
	}

	.modal {
		@apply relative bg-white w-full max-w-md m-auto flex-col flex;
		min-width: min(600px, 100%);

		@media (max-width: 1023px) {
			width: 100vw !important;
		}

		&.padding {
			@apply p-8;
		}

		&.modal-xl {
			max-width: min(1536px, 100%);
			@apply w-4/5;
		}

		&.modal-l {
			max-width: min(1536px, 100%);
			@apply w-3/5;
		}
	}

	.close-container {
		@apply flex w-full justify-end absolute right-3 top-3;

		.close {
			@apply flex w-6 h-6 bg-stan-gray-light rounded-full justify-center items-center cursor-pointer p-1;
		}
	}
</style>
