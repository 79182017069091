import axios from 'axios'
import { EventEmitter } from 'events'
import { useCommunityStore } from '@/stores/communities'

const sizeUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
function humanFileSize(bytes) {
	let u = 0
	while (parseInt(bytes, 10) >= 1024 && u < sizeUnits.length - 1) {
		bytes /= 1024
		u += 1
	}
	return `${bytes.toFixed(0)}${sizeUnits[u]}`
}

function getValueByTag(data, tag) {
	if (data && typeof data.querySelector === 'function') {
		const xmlTag = data.querySelector(tag)
		if (xmlTag !== null) {
			return xmlTag.innerHTML
		}
	}
	return null
}

class UploadFileEventEmitter extends EventEmitter {
	constructor() {
		super()
		this.emit = this.emit.bind(this)
		this.eventListener = this.eventListener.bind(this)
	}

	eventListener(ev) {
		this.emit(ev)
	}
}

export default function useUploadFile({ file, scope, objectType, emit }) {
	const ev = new UploadFileEventEmitter()
	const vm = {
		tick: 0,
		automateUpload: true,
	}
	const communityStore = useCommunityStore()

	const payload = {
		file_type: file.type,
		file_name: file.name.toLowerCase(),
		file_size: file.size,
		object_type: objectType,
		scope,
		metadata: {
			community_id: communityStore.currentCommunity.community_id,
			channel_id: communityStore.currentChannel?.channel_id,
			thread_id: communityStore.currentThread?.message_id,
		},
	}

	const uploadPayload = {
		src: file.url,
		name: file.name,
		type: file.type,
		metadata: file.metadata,
	}

	let errorSent = false
	let aborted = false

	axios
		.post(`v1/communities/${communityStore.currentCommunity.slug}/generate-presigned-url`, payload)
		.then(response => {
			file.url = response.data.url
			uploadPayload.src = file.url
			const s3Data = response.data.data

			const xhr = new XMLHttpRequest()

			xhr.upload.addEventListener(
				'progress',
				() => {
					if (aborted === true) {
						return
					}
					uploadPayload.progress = Math.round((event.loaded / event.total) * 100)
					emit('progress', uploadPayload)
					ev.emit('progress', uploadPayload)
				},
				false
			)

			xhr.addEventListener(
				'load',
				() => {
					if (aborted === true) {
						return
					}
					emit('assetUp', uploadPayload)
					ev.emit('assetUp', uploadPayload)
					vm.tick += 1
					vm.automateUpload = false
				},
				false
			)

			xhr.addEventListener(
				'error',
				() => {
					if (aborted === true) {
						return
					}
					aborted = true
					uploadPayload.error = 'Failed upload'
					if (!errorSent) {
						ev.emit('errorUp', uploadPayload)
						emit('errorUp', uploadPayload)
					}
					errorSent = true
				},
				false
			)

			xhr.onreadystatechange = ev => {
				if (xhr.readyState < 4) {
					return
				}
				if (xhr.status && xhr.status >= 400) {
					aborted = true
					uploadPayload.error = 'Upload error'
					const parser = new DOMParser()
					const resp = parser.parseFromString(xhr.responseText, 'application/xhtml+xml')
					const code = getValueByTag(resp, 'Code')
					if (code === 'EntityTooLarge') {
						let maxSizeAllowed = getValueByTag(resp, 'MaxSizeAllowed')
						maxSizeAllowed = maxSizeAllowed !== null ? humanFileSize(maxSizeAllowed) : '-'
						// let proposedSize = getValueByTag(resp, 'ProposedSize')
						uploadPayload.error = `Upload file error: entity too large, max size allowed ${maxSizeAllowed}`
					}
					if (!errorSent) {
						ev.emit('errorUp', uploadPayload)
						emit('errorUp', uploadPayload)
					}
					errorSent = true
				}
			}

			xhr.open('POST', s3Data.url)
			const postData = new FormData()
			for (const key in s3Data.fields) {
				postData.append(key, s3Data.fields[key])
			}
			postData.append('file', file)
			xhr.send(postData)
		})
		.catch(err => {
			if (err.response && err.response.status === 400) {
				if (err.response.data?.error === 'incorrect file size') {
					const maxSizeAllowed = err.response.data.limits?.max !== null ? humanFileSize(err.response.data.limits.max) : '-'
					uploadPayload.error = `Upload file error: entity too large, max size allowed ${maxSizeAllowed}`
					if (!errorSent) {
						ev.emit('errorUp', uploadPayload)
						emit('errorUp', uploadPayload)
					}
					errorSent = true
				}
				return
			}
			throw new Error('Error generate upload url')
		})

	return ev
}
