<template>
	<div class="post-assets-container" :class="{ inline: inline }">
		<div
			class="mixed-assets-container"
			:class="{
				single: mediaDigitalAssetsFiltered.length === 1,
				even: mediaDigitalAssetsFiltered.length % 2 === 0,
				odd: mediaDigitalAssetsFiltered.length > 1 && mediaDigitalAssetsFiltered.length % 2 === 1,
				'has-more': mediaDigitalAssets.length > 3,
			}"
		>
			<PostMixedMediaAttachement
				v-for="(asset, index) in mediaDigitalAssetsFiltered"
				:key="index"
				:type="asset.type"
				:name="asset.name"
				:src="asset.url"
				:time="asset.time ?? ''"
				@click="mediaAssetClicked(asset)"
			/>
			<div class="more" v-if="mediaDigitalAssets.length > 3" @click="$emit('moreClicked')">+{{ mediaDigitalAssets.length - 3 }} more</div>
		</div>

		<!-- Files: attachments -->
		<div
			class="files-container"
			v-if="fileDigitalAssets.length"
			:class="{
				single: fileDigitalAssets.length === 1,
				even: fileDigitalAssets.length % 2 === 0,
				odd: fileDigitalAssets.length > 1 && fileDigitalAssets.length % 2 === 1,
			}"
		>
			<PostAttachement v-for="(file, index) in fileDigitalAssets" :key="index" :type="file.type" :name="file.name" :src="file.url" />
		</div>
	</div>
</template>
<script setup>
	import { computed } from 'vue'

	const mediaAssetTypes = ['image', 'video', 'audio']

	const props = defineProps({
		assets: {
			type: Array,
			default: () => [],
		},
		inline: {
			type: Boolean,
			default: true,
		},
	})

	const emit = defineEmits(['assetClicked', 'moreClicked'])

	const digitalAssetType = file => {
		if (file.type?.startsWith('image')) {
			return 'image'
		}

		if (file.type?.startsWith('video')) {
			return 'video'
		}

		if (file.type?.startsWith('audio')) {
			return 'audio'
		}

		return 'file'
	}

	const mediaDigitalAssets = computed(() => props.assets?.filter(digitalAsset => mediaAssetTypes.includes(digitalAssetType(digitalAsset))))

	const mediaDigitalAssetsFiltered = computed(() => mediaDigitalAssets.value.filter((asset, index) => !props.inline || index < 3))

	const fileDigitalAssets = computed(() => props.assets?.filter(digitalAsset => !mediaAssetTypes.includes(digitalAssetType(digitalAsset))))

	const mediaAssetClicked = asset => {
		emit('assetClicked', asset)
	}
</script>

<style lang="scss" scoped>
	.post-assets-container {
		@apply h-full flex flex-col gap-5;

		.files-container {
			@apply flex flex-row flex-wrap gap-4 overflow-auto h-full;
		}
		.more {
			@apply bg-gray-overlay hidden items-center select-none justify-center text-white text-sm font-semibold leading-none -tracking-35 hover:cursor-pointer;
		}
		.mixed-assets-container {
			@apply relative gap-4;
		}
		&.inline {
			.mixed-assets-container {
				@apply max-h-96;
				&:not(.single) {
					@apply grid grid-cols-2 auto-rows-fr;
				}
				&.single {
					@apply grid-cols-2 grid;
				}
				&.odd {
					grid-template-columns: 3fr 2fr;
					& > :first-child {
						@apply row-span-2;
					}
				}
				&.has-more {
					.more {
						@apply flex flex-shrink absolute right-0 bottom-0 z-10 rounded-xl;
						top: calc(50% + 8px);
						width: calc(40% - 7px);
					}
				}
			}
		}
		&:not(.inline) {
			.mixed-assets-container {
				&:not(single) {
					@apply flex flex-wrap;
					:deep(.post-mixed-media-attachement-container) {
						max-width: calc(50% - 8px);
						.image {
							@apply w-full h-full;
						}
					}
				}
			}
		}
	}
</style>
