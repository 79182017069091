import { useGlobalsStore } from '@/stores/globals'
import { useAuthStore } from '@/stores/auth'
import { useCommunityStore } from '@/stores/communities'

let apiUrl = null

function trigger(eventName, options) {
	if (!apiUrl) return
	const payload = {}
	payload.n = eventName
	payload.u = window.location.href
	payload.r = window.document.referrer || null
	payload.w = window.innerWidth
	if (options && options.meta) {
		payload.m = options.meta
	}
	if (!('m' in payload)) {
		payload.m = {}
	}
	payload.m.platform = {
		oscpu: navigator.oscpu,
		vendor: navigator.vendor,
		platform: navigator.platform,
		cpuClass: navigator.cpuClass,
		appName: navigator.appName,
		appVersion: navigator.appVersion,
		codeName: navigator.appCodeName,
		userAgent: navigator.userAgent,
	}
	if (options && options.props) {
		payload.p = options.props
	}

	const request = new XMLHttpRequest()
	request.open('POST', apiUrl, true)
	request.setRequestHeader('Content-Type', 'application/json')
	request.send(JSON.stringify(payload))

	// google analytics
	const flattenOptions = { ...payload.m, ...payload.p }
	Object.keys(flattenOptions).forEach(v => {
		if (v.includes('-')) {
			flattenOptions[v.replace('-', '_')] = flattenOptions[v]
			delete flattenOptions[v]
		}
	})

	request.onreadystatechange = () => {
		if (request.readyState === 4) {
			options && options.callback && options.callback()
		}
	}

	if (window.clarity) {
		window.clarity('set', eventName, '1')
	}
}

async function stanAnalytics(name, opts = {}) {
	const meta = opts.meta || {}
	const props = opts.props || {}
	// Plugin default metadata
	const globalsStore = useGlobalsStore()
	const communityStore = useCommunityStore()
	meta.ip = globalsStore.getIPAddress
	meta.sessionUUID = globalsStore.getSessionUUID
	meta.cookieUUID = globalsStore.getCookieUUID

	// Get user/fan metadata when available
	const authStore = useAuthStore()

	const isLoggedIn = !!authStore.token
	if (isLoggedIn) {
		meta.user_id = communityStore.currentCommunity.owner_id
	} else {
		meta.user_id = authStore.userId
	}
	meta.fan_id = authStore.fanId

	// props.experimens = getFlags()
	// Disabled Fingerprint until figure out the payment and plan details
	// meta.visitorId = await getVisitorID()
	// eslint-disable-next-line no-undef
	return trigger(name, { meta, props })
}

export default {
	install: (app, options) => {
		apiUrl = app.config.globalProperties.$configSettings.analyticsApi
		app.config.globalProperties.$stanAnalytics = stanAnalytics
		app.provide('stanAnalytics', stanAnalytics)
	},
}
