<template>
	<AppSelectDropdown
		v-if="showDropdown"
		:menu="true"
		dropdown-menu-class="!min-w-max"
		:options="selectOptions"
		@value-selected="value => valueSelected(value)"
	/>
	<AppModal
		ref="deleteModal"
		title="Just Confirming!"
		subheading="Are you sure you want to delete this comment?"
		action="Delete Comment"
		secondaryAction="Never Mind"
		@cta="deleteComment"
		@secondary-cta="closeDeleteModal"
		@close="closeDeleteModal"
	>
	</AppModal>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'

	export default {
		props: {
			message: {
				type: Object,
				required: true,
			},
		},
		emits: ['handleEditComment', 'handleDeleteComment'],
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			canModifyMessages() {
				return this.communityStore.canModifyMessages
			},
			selfId() {
				return this.communityStore.self.community_member_id
			},
			showDropdown() {
				return this.canDelete || this.canEdit
			},
			canDelete() {
				return this.canModifyMessages || this.message.sender_id == this.selfId
			},
			canEdit() {
				return this.message.sender_id == this.selfId
			},
			selectOptions() {
				const options = []
				
				if (this.canEdit) {
					options.push({value: 'edit', label: 'Edit', iconBefore: 'edit'})
				}
				if (this.canDelete) {
					options.push({value: 'delete', label: 'Delete', iconBefore: 'trash'})
				}

				return options
			}
		},
		methods: {
			valueSelected(value) {
				switch (value) {
					case 'edit':
						this.editComment()
						break
					case 'delete':
						this.showDeleteModal()
						break
				}
			},
			closeDeleteModal() {
				this.$refs?.deleteModal.hide()
			},
			showDeleteModal() {
				this.$refs?.deleteModal.show()
			},
			deleteComment() {
				this.$emit('handleDeleteComment', this.message)
				this.closeDeleteModal()
			},
			editComment() {
				this.$emit('handleEditComment', this.message)
			},
		},
	}
</script>