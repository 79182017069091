/* eslint-disable consistent-return, no-unused-vars */
import { h } from 'vue'
import { getPreviewImage, isImageProcessing, preloadImage, getOptimizeOptions, getOptimizeImageUrl, checkImage } from './OptimizationImageTools'

export const emptyImage =
	'data:image/gif;base64,R0lGODlhngHgAoABAAAAAP///yH5BAEKAAEALAAAAACeAeACQAL+jI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8b+jRw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KNqzYsWTLmj2LNq3atWzbun0LN67cuXTr2r2LN6/evXz7+v0LOLDgwYQLGz6MOLHixYwbO34MObLkyZQrW76MObPmzZw7e/4MOrTo0aRLmz6NOrXq1axbu34NO7bs2bRr276NO7fu3bx7+/4NPLjw4cSLGz+OPLny5cybO38OPbr06dSrW7+OPbv27dy7e/8OPrz48eTLmz+PPr369ezbu38PP778+fTr27+PP7/+/fz++/v/D2CAAg5IYIEGHohgggouyGCDDj4IYYQSTkhhhRZeiGGGGm7IYYcefghiiCKOSGKJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjruyGOPPv4IZJBCDklkkUYeiWSSSi7JZJNOPglllFJOSWWVVl6JZZZabslll15+CWaYYo5JZplmnolmmmquyWabbr4JZ5xyzklnnXbeiWeeeu7JZ59+/glooIIOSmihhh6KaKKKLspoo44+Cmmkkk5KaaWWXopppppuymmnnn4Kaqiijkpqqaaeimqqqq7KaquuvgprrLLOSmuttt6Ka6667sprr77+Cmywwg5LbLHGHotebLLKLstss84+C2200k5LbbXWXottttpuy2233n4Lbrjijktuueaei2666q7LbrvuvgtvvPLOS2+99t6Lb7767stvv/7+C3DAAg9McMEGH4xwwgovzHDDDj8MsR0FAAA7'

export const emptyImage73 =
	'data:image/gif;base64,R0lGODlhSQBJAIABAAAAAP///yH5BAEKAAEALAAAAABJAEkAQAJPjI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTquhBQA7'

export default {
	name: 'ImagePreview',
	props: {
		source: {
			type: String,
			default: () => '',
		},
		alt: {
			type: String,
			default: () => '',
		},
		staticClass: {
			type: String,
			default: () => '',
		},
		staticStyle: {
			type: String,
			default: () => '',
		},
		format: {
			type: String,
			default: () => 'webp',
		},
		quality: {
			type: Number,
			default: () => 100,
		},
		width: {
			type: Number,
			default: () => -1,
		},
		height: {
			type: Number,
			default: () => -1,
		},
		retry: {
			type: Number,
			default: () => -1,
		},
	},
	computed: {
		class() {
			if (this.staticClass && this.staticClass !== '') {
				return `base-preview-image ${this.staticClass}`
			}
			return 'base-preview-image'
		},
		style() {
			if (this.staticStyle && this.staticStyle !== '') {
				return this.staticStyle
			}
		},
		src() {
			const imgSrc =
				typeof this.imageSource === 'undefined' || this.imageSource === null || this.imageSource === '' ? emptyImage73 : this.imageSource
			return this.init ? emptyImage73 : imgSrc
		},
	},
	data() {
		return {
			init: true,
			imageSource: '',
			isImageReady: false,
			retryGetPreviewInterval: null,
			startedCheckImage: false,
			retryCount: 0,
		}
	},
	mounted() {
		this.preloadImage()
	},
	methods: {
		async checkImagePreview(src) {
			if (!this.startedCheckImage) {
				this.startedCheckImage = true
				this.isImageReady = await checkImage(src)
				this.startedCheckImage = false
			}
			if (this.isImageReady) {
				this.clearCheckImageInterval()
				getPreviewImage(src, this.$refs.image)
					.then(image => {
						this.imageSource = image
					})
					.catch(() => {
						this.checkSourceImage().then(image => {
							this.imageSource = image
						})
					})
				this.$emit('load')
			}
		},
		async preloadImage() {
			if (!isImageProcessing(this.source)) {
				this.init = false
				this.imageSource = this.source || emptyImage73
				return
			}

			let assetsDomain = ''
			// Vue-admin config
			if (this.$configSettings && this.$configSettings?.assetsDomain !== '') {
				assetsDomain = this.$configSettings.assetsDomain
			}

			// Nuxt config
			if (this.$config && this.$config?.assetsDomain !== '') {
				assetsDomain = this.$config.assetsDomain
			}

			const url = new URL(this.source)
			if (!url.host.endsWith(assetsDomain)) {
				this.init = false
				this.imageSource = this.source || emptyImage73
				return
			}

			const optimizeImageOptions = getOptimizeOptions(this)
			optimizeImageOptions.assetsDomain = assetsDomain
			const optimizeImageSrc = await getOptimizeImageUrl(this.source, {
				...optimizeImageOptions,
			})
			try {
				preloadImage(optimizeImageSrc)
					.then(() => {
						this.init = false
						this.imageSource = optimizeImageSrc
						this.$emit('load')
						return true
					})
					.catch(async () => {
						this.init = false
						if (this.retry > 0) {
							await this.checkImagePreview(optimizeImageSrc)
							if (!this.isImageReady) {
								this.retryGetPreviewImage(optimizeImageSrc)
							} else {
								this.$emit('load')
							}
						} else {
							this.imageSource = await this.checkSourceImage()
							this.$emit('load')
						}
						return true
					})
			} catch {
				this.imageSource = await this.checkSourceImage()
				this.$emit('load')
				return true
			}
		},
		async retryGetPreviewImage(src) {
			if (!this.isImageReady && this.retry - this.retryCount > 0) {
				await this.checkImagePreview(src)
				if (!this.isImageReady) {
					this.clearCheckImageInterval()
					this.retryGetPreviewInterval = setInterval(() => {
						this.retryCount += 1
						this.retryGetPreviewImage(src)
					}, 2000)
				}
			} else if (!this.isImageReady) {
				this.clearCheckImageInterval()
				this.imageSource = await this.checkSourceImage()
				this.$emit('load')
			} else {
				this.clearCheckImageInterval()
			}
		},
		async checkSourceImage() {
			this.init = false
			const imageSourceReady = await checkImage(this.source)
			return imageSourceReady ? this.source || emptyImage73 : emptyImage73
		},
		clearCheckImageInterval() {
			if (this.retryGetPreviewInterval !== null) {
				clearInterval(this.retryGetPreviewInterval)
				this.retryGetPreviewInterval = null
			}
		},
	},
	watch: {
		source() {
			this.init = true
			this.clearCheckImageInterval()
			this.preloadImage()
		},
	},
	render() {
		return h('img', {
			ref: 'image',
			class: this.class,
			style: this.style,
			attrs: { src: this.src, alt: this.alt },
			onClick: ev => this.$emit('click', ev),
		})
	},
}
