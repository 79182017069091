<template>
	<div>
		<ImagePreview
			class="image"
			:src="src"
			@click="showImagePreview"
			:source="src"
			:width="100"
			:height="100"
			:staticClass="imgClass"
			:staticStyle="style"
		/>
		<AppModal
			v-if="previewImage"
			class="image-preview-modal"
			:id="`image-preview-modal-${id}`"
			:ref="`image-preview-modal-${id}`"
			:isImagePreview="true"
			:frame="true"
			:hide-close="true"
		>
			<div class="image-preview" @click="hideImagePreview">
				<img :src="src" style="border-radius: 5px" />
			</div>
		</AppModal>
	</div>
</template>

<script>
	import commonHelpers from '@/global_helper/helpers'

	export default {
		name: 'AppImage',
		props: {
			src: String,
			circle: Boolean,
			cover: Boolean,
			width: String,
			height: String,
			borderRadius: { type: String, default: undefined },
			id: {
				type: String,
				default: () => commonHelpers.UUID(),
			},
			previewImage: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			style() {
				const out = {}
				out.height = this.height
				out.width = this.width
				if (this.circle || this.cover) {
					out['object-fit'] = 'cover'
				}
				if (this.borderRadius) out['border-radius'] = this.borderRadius
				return Object.keys(out)
					.map(key => `${key}:${out[key]};`)
					.join(' ')
			},
			imgClass() {
				return `${this.circle ? 'rounded-circle' : ''}`
			},
		},
		methods: {
			showImagePreview() {
				if (this.previewImage) {
					this.$refs[`image-preview-modal-${this.id}`].show()
				}
			},
			hideImagePreview() {
				if (this.previewImage) {
					this.$refs[`image-preview-modal-${this.id}`].hide()
				}
			},
		},
	}
</script>
<style scoped lang="scss">
	.image-preview-modal {
		:deep(.modal-inner-content) {
			overflow: hidden;
			display: flex;
			& > div {
				max-height: 100%;
				width: 100%;
			}
		}
	}
	.image-preview {
		width: fit-content;
		height: 100%;
		margin-top: auto;
		margin: auto;
		min-height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 100%;
		max-width: 100%;
		img {
			object-fit: contain;
			max-height: 100%;
		}
	}
	.image {
		min-height: 50px;
		min-width: 50px;
		border: 1px solid var(--stan-gray-soft-color);
		border-radius: 5px;
		background: var(--stan-white);
	}
</style>
