<template>
	<div>
		<div class="label" v-if="label">
			<div class="label-wrapper col flex flex-row justify-between w-full">
				<label :for="id" class="control-label">{{ label }}</label>
			</div>
			<span v-if="maxlength != undefined && isTypeText && showCharacterCount" class="character-counter para-3"
				>{{ localValue ? localValue.length : 0 }}/{{ maxlength }}</span
			>
		</div>

		<textarea
			ref="inputBox"
			:id="id"
			v-model="localValue"
			rows="5"
			class="para-2"
			:class="[
				inputClass,
				{
					'form-control': !suffix && !icon,
					'text-lowercase': lowercase,
					'text-uppercase': uppercase,
					error: hasError,
					primary: primary,
				},
			]"
			:type="type"
			:name="name"
			:placeholder="placeholder"
			:disabled="disabled"
			:maxlength="maxlength"
			:max="max"
			:min="min"
			:autocomplete="disableAutoComplete ? 'off' : 'on'"
			:not-chrome-work-autocomplete="disableAutoComplete ? 'disable-autocomplete' : 'on'"
			@keydown="e => $emit('keydown', e)"
			@keyup="e => $emit('keyup', e)"
			@change="e => $emit('change', e)"
			@input="e => $emit('input', e)"
			@blur="e => inputLostFocus(e)"
			@focus="e => inputFocused(e)"
		></textarea>

		<div class="para-3">
			<span v-if="hasError" class="text-bold error-message"><i></i>{{ error[0].$message }}</span>
			<span v-if="warning" style="color: #00cfcc"><br v-if="hasError" /><i></i>{{ warning }}</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'AppTextArea',
		emits: ['update:modelValue', 'keydown', 'keyup', 'change', 'input', 'blur', 'focus'],
		props: {
			modelValue: {
				type: [String, Number],
				default: '',
			},
			id: {
				type: String,
			},
			label: {
				type: String,
				default: '',
			},
			name: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
			},
			icon: {
				type: String,
				default: undefined,
			},
			type: {
				type: String,
				default: 'text',
				validator(value) {
					return ['text', 'number', 'email', 'password'].includes(value)
				},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			rows: {
				type: Number,
				default: 2,
			},
			cols: {
				type: Number,
				default: 20,
			},
			limit: {
				type: Number,
			},
			prefix: {
				type: String,
			},
			suffix: {
				type: String,
			},
			inputClass: {
				type: [Array, String],
			},
			iconWidth: {
				type: String,
			},
			maxlength: {
				type: String,
				default: '500',
			},
			showCharacterCount: {
				type: Boolean,
				default: true,
			},
			hasPrecision: {
				type: Boolean,
			},
			error: {
				type: Array,
			},
			noErrorCheck: {
				type: Boolean,
				default: false,
			},
			warning: {
				type: String,
			},
			min: {
				type: Number,
				default: undefined,
			},
			max: {
				type: Number,
				default: undefined,
			},
			uppercase: {
				type: Boolean,
				default: undefined,
			},
			lowercase: {
				type: Boolean,
				default: undefined,
			},
			primary: {
				type: Boolean,
				default: undefined,
			},
			disableAutoComplete: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			localValue: {
				get() {
					return this.modelValue
				},
				set(value) {
					if (value && this.lowercase) value = value.toLowerCase()
					if (value && this.uppercase) value = value.toUpperCase()
					this.$emit('update:modelValue', value)
				},
			},
			isTypeText() {
				return this.type === 'text'
			},
			isTypeNumber() {
				return this.type === 'number'
			},
			isTypeEmail() {
				return this.type === 'email'
			},
			isTypePassword() {
				return this.type === 'password'
			},
			isSuffixSlotPresent() {
				return 'suffix' in this.$slots
			},
			hasError() {
				return this.error && this.error.length
			},
		},
		methods: {
			inputFocused(e) {
				e.target.addEventListener('wheel', this.ignoreWheelScroll, { passive: false })
				this.$emit('focus', e)
			},
			inputLostFocus(e) {
				e.target.removeEventListener('wheel', this.ignoreWheelScroll)
				this.$emit('blur', e)
			},
			ignoreWheelScroll(e) {
				e.preventDefault()
			},
			focus() {
				setTimeout(() => this.$refs.inputBox?.focus(), 500)
			},
		},
	}
</script>
<style lang="scss" scoped>
	textarea {
		padding-left: 10px;
		padding-top: 10px;
		overflow-y: scroll;
	}
	.label {
		@apply flex justify-between gap-4 mb-2;
		.wrapper {
			@apply mb-2;
		}
	}

	.input-container {
		@apply flex items-center flex-wrap;
		.prefix {
			@apply pr-1;
		}
	}

	.posAbs {
		position: absolute;
		bottom: -23px;
		left: 0;
		font-size: 12px;
	}

	.left-icon {
		.input-wrapper {
			@apply relative;
			display: inline-flex;
			align-items: center;
		}
		svg,
		img {
			@apply inline-block absolute;
			font-size: 18px;
			line-height: 18px;
			display: inline-block;
			width: 18px;
			height: 18px;
			top: 15px;
			left: 13px;
		}
		input {
			@apply pl-11;
			width: 100%;
		}
	}

	.error-message {
		color: var(--stan-text-negative-color);
	}

	.suffixed {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		input {
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
			line-height: 1;
			color: var(--stan-text-dark-color);
			width: 0;
		}
		.suffix {
			color: #808db6;
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
		}
	}
	.input-icon {
		margin-right: 5px;
	}
	input {
		flex-grow: 1;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
	input:focus {
		box-shadow: none;
		outline: none;
	}
	input:disabled {
		cursor: not-allowed;
		color: var(--stan-text-light-color) !important;
		background: var(--stan-primary-light-color) !important;
		-webkit-text-fill-color: var(--stan-text-light-color);
		-webkit-opacity: 1;
	}
	.character-counter {
		color: var(--stan-text-light-color);
		margin-left: auto;
		padding: 0;
		align-self: center;
	}
</style>
