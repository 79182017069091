<template>
	<div v-if="isEditMode" class="link-preview-wrapper edit">
		<div class="link-preview-container">
			<button class="draft-preview-button">
				<div class="draft-preview-header" v-if="favicons">
					<ImagePreview class="draft-preview-icon" :src="favicons" alt="" />
					<span class="draft-preview-header-text">{{ sitename }}</span>
				</div>
				<div class="draft-preview-text">
					<a :href="url" target="_blank">{{ title }}</a>
				</div>
			</button>
		</div>
	</div>
	<div v-if="isPreviewMode" class="link-preview-wrapper preview" @click="openLink">
		<!-- <div class="delete-container">
            <div style="width: 16px"></div>
            <div class="delete-container-border"></div>
        </div> -->
		<div class="body-container">
			<div class="body-container__row" v-if="favicons">
				<ImagePreview class="preview-icon" :src="favicons" alt="" />
				<span class="body-container--site-name">{{ sitename }}</span>
			</div>
			<div class="body-container__row">
				<span class="body-container--link">
					<a target="_blank" :href="url">{{ title }}</a>
				</span>
			</div>
			<div class="body-container__row">
				<span class="body-container--text">{{ description }}</span>
			</div>
			<div v-if="image !== ''" class="body-container__row image">
				<AppImage :class="image" :src="image" class="rounded" cover width="375" :previewImage="true" />
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent } from 'vue'
	import AppImage from '@/components/shared/AppImage.vue'

	export default defineComponent({
		name: 'LinkPreview',
		components: {
			AppImage,
		},
		props: {
			mode: {
				type: String,
				default: 'edit',
			},
			preview: {
				type: Object,
				default: {},
			},
		},
		setup(props) {
			const url = computed(() => props.preview.original_url)
			const sitename = computed(() => props.preview.sitename)
			const favicons = computed(() => props.preview.favicons)
			const title = computed(() => props.preview.title)
			const description = computed(() => props.preview.description)
			const image = computed(() => props.preview.image)
			const imageStyle = computed(() => ({
				'background-image': `url(${image.value})`,
			}))
			const isEditMode = computed(() => props.mode === 'edit')
			const isPreviewMode = computed(() => props.mode === 'list')
			return {
				isEditMode,
				isPreviewMode,
				url,
				sitename,
				favicons,
				title,
				description,
				image,
				imageStyle,
			}
		},
	})
</script>

<style lang="scss" scoped></style>
