import { useCommunityStore } from '@/stores/communities'
import { computed, inject, provide } from 'vue'

export function usePost(messageId: number, parentMessageId?: number, passedChannelName?: string) {
	const stanAnalytics: Function = inject('stanAnalytics')!

	const communityStore = useCommunityStore()
	const channelName = passedChannelName ? passedChannelName : communityStore.currentChannel?.name
	const message = computed(() =>
		parentMessageId
			? communityStore.getChannelThread(channelName)[parentMessageId]?.messages[messageId] || {}
			: communityStore.messages[messageId] || {}
	)
	const reactions = computed(() => message.value.reactions)
	const addRemoveReaction = async reaction => {
		const action = await communityStore.reacted(message.value, reaction)
		stanAnalytics('community-post-inline-reaction', {
			props: { reaction, action, message_id: message.value?.message_id },
		})
	}
	provide('reacted', addRemoveReaction)
	provide('messageId', messageId)

	return { communityStore, message, reactions }
}
