import { useCommunityStore } from '@/stores/communities'
import { useCommunityPublicStore } from '@/stores/public'
import { useProductsStore } from '@/stores/products'
import { useResourceStore } from '@/stores/resources'
import constants from '@/global_helper/constants'

export default {
	// PrepareStores.js with before route in rotues handles getting inital data store data
	computed: {
		communityStore() {
			return useCommunityStore()
		},
		communityPublicStore() {
			return useCommunityPublicStore()
		},
		resourceStore() {
			return useResourceStore()
		},
		resources() {
			return this.resourceStore.resources
		},
		hasCommunityLoaded() {
			return this.communityStore.hasCommunityLoaded
		},
		hasPublicStoreLoaded() {
			return this.communityPublicStore.hasLoaded
		},
	},
}
