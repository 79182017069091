import { createDirective } from '@/components/utils/components'
import { closePortals, getPortalProxy } from '@/components/utils/portal'
import { isKeyCode } from '@/components/utils/key-composition'

function isNaN(val) {
	return Number.isNaN(val)
}

function getDepth(value) {
	if (value === false) {
		return 0
	}
	if (value === true || value === void 0) {
		return 1
	}

	const depth = parseInt(value, 10)
	return isNaN(depth) ? 0 : depth
}

export default createDirective({
	name: 'close-popup',
	beforeMount(el, { value }) {
		const ctx = {
			depth: getDepth(value),
			handler(evt) {
				ctx.depth !== 0 &&
					setTimeout(() => {
						const proxy = getPortalProxy(el)
						if (proxy !== void 0) {
							closePortals(proxy, evt, ctx.depth)
						}
					}, 10)
			},
			handlerKey(evt) {
				isKeyCode(evt, 13) === true && ctx.handler(evt)
			},
		}

		el.__pclosepopup = ctx

		el.addEventListener('click', ctx.handler)
		el.addEventListener('keyup', ctx.handlerKey)
	},
	updated(el, { value, oldValue }) {
		if (value !== oldValue) {
			el.__pclosepopup.depth = getDepth(value)
		}
	},
	beforeUnmount(el) {
		const ctx = el.__pclosepopup
		el.removeEventListener('click', ctx.handler)
		el.removeEventListener('keyup', ctx.handlerKey)
		delete el.__pclosepopup
	},
})
