<template>
	<div class="dialog-anchor" v-show="visible">
		<div class="dialog-background" />
		<div class="dialog-box" :class="{ shake }">
			<div class="close-button-anchor">
				<div class="content"><slot name="default" /></div>

				<div class="close-button" @click="onClose">
					<AppIcon src="close" />
				</div>

				<div class="below-dialog-box">
					<slot name="below-dialog" />
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { ref } from 'vue'

	const { visible } = defineProps({
		visible: {
			type: Boolean,
			default: false,
		},
	})
	const emit = defineEmits(['close'])
	const shake = ref(false)

	function onClose() {
		shake.value = true
		setTimeout(() => {
			emit('close')
			shake.value = false
		}, 100) // Make sure this matches the animation timing below
	}
</script>
<style lang="scss" scoped>
	$ZIndexDialog: 999;
	$ZIndexBackground: 100;

	.dialog-anchor {
		display: inline-block;
	}
	.dialog-background {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: $ZIndexBackground;
		display: flex;
		justify-content: center;
		align-items: center;

		background-color: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(2px) opacity(1);
		animation: 0.1s ease-in-out 0s 1 backdrop-filter-fade;
	}

	.dialog-box {
		z-index: $ZIndexDialog;
		position: absolute;
		display: block;
		background: white;
		border-radius: 0.5rem;
		bottom: 1rem;
		left: 3rem;
		height: 110px;

		&.shake {
			animation: 0.1s linear 0s close-button-shake;
		}
	}

	.close-button-anchor {
		position: relative;

		.close-button {
			position: absolute;
			top: -0.5rem;
			right: -0.5rem;
			background: white;
			border-radius: 50%;
			box-shadow:
				0 0 0 1px rgba(0, 0, 0, 0.1),
				0 2px 4px rgba(0, 0, 0, 0.2);

			&:hover {
				cursor: pointer;
			}
		}
	}

	.below-dialog-box {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -60%;
		margin-left: auto;
		margin-right: auto;
		width: 50px;
		z-index: $ZIndexDialog;
	}

	@keyframes backdrop-filter-fade {
		from {
			backdrop-filter: blur(2px) opacity(0);
			background-color: rgba(0, 0, 0, 0);
		}
		to {
			backdrop-filter: blur(2px) opacity(1);
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	@keyframes close-button-shake {
		0% {
			transform: translateX(0%) translateY(0%);
		}
		50% {
			transform: translateX(-2%) translateY(15%);
		}
		100% {
			transform: translateX(0%) translateY(0%);
		}
	}
</style>
