<template>
	<AppInput
		class="w-full"
		v-model="value"
		placeholder="Search..."
		icon="search"
		inputClass="!rounded-full !bg-stan-blue-8"
		:hideErrorSpacing="true"
		type="text"
		@input="input"
		@keyup="keyup"
	/>
</template>
<script>
	export default {
		name: 'AppSearchInput',
		emits: ['update:modelValue', 'keydown', 'keyup', 'change', 'input', 'blur', 'focus'],
		props: {
			initalValue: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				value: this.initalValue || '',
			}
		},
		computed: {},
		methods: {
			keyup(e) {
				if (e?.key == 'Enter') {
					this.$emit('submit', this.value)
				}
			},
			input() {
				this.$emit('input', this.value)
			},
		},
		watch: {
			initalValue() {
				this.value = this.initalValue
			},
		},
	}
</script>
<style lang="scss" scoped>
	input {
		padding-left: 10px;
	}

	.label {
		@apply flex justify-between gap-4;

		.wrapper {
			@apply mb-2;
		}
	}

	.input-container {
		@apply flex items-center flex-wrap;

		.prefix {
			@apply pr-1;
		}
	}

	.posAbs {
		position: absolute;
		bottom: -23px;
		left: 0;
		font-size: 12px;
	}

	.left-icon {
		.input-wrapper {
			@apply relative;
			display: inline-flex;
			align-items: center;
		}

		svg,
		img {
			@apply inline-block absolute;
			font-size: 18px;
			line-height: 18px;
			display: inline-block;
			width: 18px;
			height: 18px;
			top: 15px;
			left: 13px;
		}

		input {
			@apply pl-11;
			width: 100%;
		}
	}

	.error-message {
		color: var(--stan-text-negative-color);
	}

	.suffixed {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		input {
			flex-grow: 1;
			flex-basis: 0;
			max-width: 100%;
			line-height: 1;
			color: var(--stan-text-dark-color);
			width: 0;
		}

		.suffix {
			color: #808db6;
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
		}
	}

	.input-icon {
		margin-right: 5px;
	}

	input {
		flex-grow: 1;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:focus {
		box-shadow: none;
		outline: none;
	}

	input:disabled {
		cursor: not-allowed;
		color: var(--stan-text-light-color) !important;
		background: var(--stan-primary-light-color) !important;
		-webkit-text-fill-color: var(--stan-text-light-color);
		-webkit-opacity: 1;
	}

	.character-counter {
		color: var(--stan-text-light-color);
		margin-left: auto;
		padding: 0;
		align-self: center;
	}
</style>
