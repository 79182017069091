<template>
	<div v-if="!wizardEnded ">
		<div v-if="tooltipAttrs.hasBackdrop" class="bg-black opacity-50 pointer-events-none fixed w-full h-full top-0 left-0 z-[200]" />
		<div
			class="absolute h-36 w-[90%] lg:w-96 z-[201] rounded-2xl p-6 flex flex-col justify-between bg-stan-negative-light community-onboarding-tooltip pointer-events-auto"
			ref="popup"
			:class="[tooltipAttrs.classes, { 'opacity-0': shouldHideWizard }]"
			:style="tooltipAttrs.style"
		>
			<div class="text-area text-sm">
				<div>{{ tooltipAttrs.text.title }}</div>
				<div>{{ tooltipAttrs.text.description }}</div>
			</div>
			<div class="flex w-full justify-between actions-area">
				<AppButton v-if="!isFinalStep" size="xs" color="light" outline class="skip-button" @click="skip"> Skip Walkthrough </AppButton>
				<AppButton class="bg-community-primary" size="xs" @click="changeStep">
					{{ nextBtnText }}
					<AppIcon v-if="!isFinalStep" src="arrow-right" class="next-icon" />
				</AppButton>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, defineEmits, watch } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import constants from '@/global_helper/constants'
	import { useElementsStore } from '@/stores/elements'

	const props = defineProps({
		isMobile: {
			type: Boolean,
			default: false,
		},
		staticTooltipPosition: {
			type: Object,
			default: null,
		},
		aboutElement: {
			type: Object,
			default: null,
		},
		linksElement: {
			type: Object,
			default: null,
		},
		createPostElement: {
			type: Object,
			default: null,
		},
	})
	const emit = defineEmits(['toggle-highlight-header', 'change-step'])
	const communityStore = useCommunityStore()
	const onboardingState = computed(() => communityStore.currentCommunity.data.onboarding_state)
	const onboardingStateBody = ref(communityStore.currentCommunity.data.onboarding_state)
	const hasResourcePageLoaded = computed(() => useElementsStore().resourcePageHasLoaded)
	const shouldHideWizard = computed(() => !hasResourcePageLoaded.value && wizardStep.value === constants.COMMUNITY_ONBOARDING.WALKTHROUGH_RESOURCES)
	const popup = ref(null)

	const wizardStep = ref(
		Object.values(constants.COMMUNITY_ONBOARDING).filter(key => !Object.keys(onboardingState.value).includes(key))[0] ?? 'NOT_DOING'
	)

	const staticTooltipPosition = ref(null)

	const currentWizardIdx = computed(() => {
		return Object.values(constants.COMMUNITY_ONBOARDING).findIndex(el => el === wizardStep.value)
	})

	const isFinalStep = computed(() => currentWizardIdx.value === Object.values(constants.COMMUNITY_ONBOARDING).length - 1)

	const nextBtnText = computed(() => (isFinalStep.value ? 'Complete!' : 'Next'))

	const wizardEnded = computed(() => wizardStep.value === 'END' || wizardStep.value === 'NOT_DOING')

	/*
    If window is smaller than 80rem (the max width of the main container) then mx-auto on the main container isn't applied. If it is larger than 80rem then the spacing is windowWidth - 80*16(max-width) - 2*16(padding x) /2 because it is for left and right. We need to add 40 px to make it so that the tooltip is under the requested element on all desktop widths.
    */
	const sideSpace =
		window.innerWidth >= 80 * 16 ? Math.round((window.innerWidth - 82 * 16) / 2) + 40 : Math.round((window.innerWidth - 34 * 16) / 2)

	const tooltipAttrs = computed(() => {
		switch (wizardStep.value) {
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_WELCOME:
				return {
					hasBackdrop: true,
					classes: props.isMobile ? ['left-[5%]', 'top-24'] : ['left-8', 'top-8', 'left-arrow'],
					text: {
						title: 'This is your community!',
						description: "Let's take a look around",
					},
				}
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_BANNER:
				if (props.isMobile) changeStep()
				return {
					hasBackdrop: true,
					classes: ['right-8', 'top-64', 'top-arrow'],
					text: {
						title: 'This is your community header.',
						description: 'You can update your photo here.',
					},
				}
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_MESSAGE:
				return {
					hasBackdrop: true,
					classes: [props.isMobile ? 'bottom-arrow' : 'top-arrow'],
					style: props.isMobile
						? {
								left: '5%',
								bottom: '116px',
							}
						: {
								left: `${sideSpace + 12}px`,
								top: staticTooltipPosition.value?.top ? `${staticTooltipPosition.value?.top}px` : 'top-[540px]',
							},
					text: {
						title: 'You can create posts here!',
						description: '',
					},
				}
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_ABOUT:
				return {
					hasBackdrop: true,
					classes: ['top-arrow'],
					style: {
						right: props.isMobile ? '5%' : `${sideSpace}px`,
						top: staticTooltipPosition.value?.top ? `${staticTooltipPosition.value?.top}px` : 'top-[35rem]',
					},
					text: {
						title: "Edit your Community's description here.",
						description: '',
					},
				}
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_LINKS:
				return {
					hasBackdrop: true,
					classes: ['bottom-arrow'],
					style: {
						right: props.isMobile ? '5%' : `${sideSpace}px`,
						top: staticTooltipPosition.value?.top ? `${staticTooltipPosition.value?.top}px` : 'top-[35rem]',
					},
					text: {
						title: 'These are useful community links!',
						description: 'You can edit them from here.',
					},
				}
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_RESOURCES:
				return {
					hasBackdrop: true,
					classes: ['bottom-arrow'],
					style: {
						//we cannot position with the button's position because resources tab isn't mounted so the element isn't available. Thankfully, top is always 170 due to the fixed size of the header elements and right is the sidespace due to margin auto + 434 because the main element is 1280 width so 24 of the gap and (1280-2*24 (the two gaps))/3 = 410
						right: `${sideSpace + 434}px`,
						top: '170px',
					},
					text: {
						title: 'This is where you can create pages, for your members to go more indepth on specific topics.',
						description: '',
					},
				}
			default:
			changeStep()
				return {
					hasBackdrop: false,
					text: {
						title: '',
						description: '',
					},
				}
		}
	})
	const changeStep = async () => {
		onboardingStateBody.value[wizardStep.value] = true
		const constantValues = Object.values(constants.COMMUNITY_ONBOARDING).filter(
			val => !onboardingStateBody.value[val] && val !== wizardStep.value
		)

		if (constantValues.length) {
			wizardStep.value = constantValues[0]
		} else {
			wizardStep.value = 'END'
		}
		if (wizardStep.value && wizardStep.value !== 'END') onboardingStateBody[wizardStep.value] = true

		switch (wizardStep.value) {
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_BANNER:
				if (props.createPostElement) props.createPostElement.style.zIndex = 'initial'
				if (!props.isMobile && props.aboutElement) props.aboutElement.style.zIndex = 'initial'
				if (props.linksElement) props.linksElement.style.zIndex = 'initial'
				emit('toggle-highlight-header', true)
				break
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_MESSAGE:
				if (props.createPostElement && popup.value) {
					props.createPostElement.style.zIndex = 700
					staticTooltipPosition.value = {
						top: props.createPostElement.clientHeight + props.createPostElement.offsetTop + 260,
					}
					popup.value.style.zIndex = 701
				}
				if (props.linksElement) props.linksElement.style.zIndex = 'initial'
				if (!props.isMobile && props.aboutElement) {
					props.aboutElement.style.zIndex = 'initial'
				} else {
					onboardingStateBody[constants.COMMUNITY_ONBOARDING.WALKTHROUGH_BANNER] = true
				}
				emit('toggle-highlight-header', false)

				break
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_ABOUT:
				if (props.createPostElement) props.createPostElement.style.zIndex = 'initial'
				if (props.linksElement) props.linksElement.style.zIndex = 'initial'
				if (!props.isMobile && props.aboutElement && popup.value) {
					props.aboutElement.style.zIndex = 700
					staticTooltipPosition.value = {
						top: props.aboutElement.clientHeight + props.aboutElement.offsetTop + 260,
					}
					popup.value.style.zIndex = 201
				}
				emit('toggle-highlight-header', false)

				break
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_LINKS:
				if (props.createPostElement) props.createPostElement.style.zIndex = 'initial'
				if (!props.isMobile && props.aboutElement) props.aboutElement.style.zIndex = 'initial'
				if (props.linksElement) {
					props.linksElement.style.zIndex = 700
					staticTooltipPosition.value = {
						top: props.linksElement.offsetTop + 76,
					}
				}
				emit('toggle-highlight-header', false)

				break
			case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_RESOURCES:
				break
			default:
				if (props.createPostElement) props.createPostElement.style.zIndex = 'initial'
				if (!props.isMobile && props?.aboutElement?.style) props.aboutElement.style.zIndex = 'initial'
				if (props.linksElement?.style) props.linksElement.style.zIndex = 'initial'
				break
		}
		await communityStore.updateCommunityData({
			onboarding_state: onboardingStateBody.value,
		})
	}
	const skip = async () => {
		wizardStep.value = 'END'
		let onboardingState = {
			...(communityStore.currentCommunity?.data?.onboarding_state ?? {}),
		}
		Object.values(constants.COMMUNITY_ONBOARDING).forEach(key => {
			onboardingState[key] = true
		})
		await communityStore.updateCommunityData({
			onboarding_state: onboardingState,
		})
	}
	watch(
		() => wizardStep.value,
		newValue => {
			switch (wizardStep.value) {
				case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_BANNER:
					emit('toggle-highlight-header', true)
					break
				case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_MESSAGE:
					if (props.createPostElement) {
						staticTooltipPosition.value = {
							top: props.createPostElement.clientHeight + props.createPostElement.offsetTop + 260,
						}
						props.createPostElement.style.zIndex = 700
					}
					if (popup.value) popup.value.style.zIndex = 701
					break
				case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_ABOUT:
					if (props.aboutElement) {
						staticTooltipPosition.value = {
							top: props.aboutElement.clientHeight + props.aboutElement.offsetTop + 260,
						}
						props.aboutElement.style.zIndex = 700
					}
					if (popup.value) popup.value.style.zIndex = 201
					break
				case constants.COMMUNITY_ONBOARDING.WALKTHROUGH_LINKS:
					if (props.linksElement) {
						props.linksElement.style.zIndex = 700
						staticTooltipPosition.value = {
							top: props.linksElement.offsetTop + 76,
						}
					}
					break
				case 'END':
					useElementsStore().setResourcesWithWizard(false)
					break
			}
			emit('change-step', newValue)
		},
		{ immediate: true }
	)
</script>

<style lang="scss">
	.community-onboarding-tooltip {
		&.left-arrow {
			&::before {
				content: '';
				@apply block w-0 h-0 absolute border-t-[12px] border-b-[12px] border-r-[12px] border-t-transparent border-b-transparent border-r-stan-negative-light -left-3 top-[45px];
			}
		}
		&.top-arrow {
			&::before {
				content: '';
				@apply block w-0 h-0 absolute border-l-[12px] border-r-[12px] border-b-[12px] border-l-transparent border-r-transparent border-b-stan-negative-light -top-[12px] right-8;
			}
		}
		&.bottom-arrow {
			&::before {
				content: '';
				@apply block w-0 h-0 absolute border-l-[12px] border-r-[12px] border-t-[12px] border-l-transparent border-r-transparent border-t-stan-negative-light -bottom-[12px] right-8;
			}
		}

		.next-icon {
			path {
				fill: var(--stan-white);
			}
		}
	}
</style>
