<template>
	<AppModal
		ref="addUploadImageModal"
		title="Crop Image"
		action="Crop"
		secondary-action="Reset"
		@cta="crop"
		@secondary-cta="cropReset"
		@close="hide"
		:loading="loading"
	>
		<div>
			<div class="canvas-wrapper">
				<canvas ref="imageCropperCanvas" id="canvas"> Your browser does not support the HTML5 canvas element. </canvas>
			</div>
		</div>
	</AppModal>

	<AppFileUpload
		key="appUploadImage"
		ref="hiddenFileUploadImage"
		accepts="image/bmp,image/jpeg,image/png,img/svg+xml"
		:confirm-upload="false"
		:scope="scope"
		@assetUp="fileUploaded"
		@filePicked="onFilePicked"
		@progress="progressUploaded"
		@errorUp="errorFileUploaded"
	/>
</template>

<script>
	import Cropper from 'cropperjs'
	import { useCommunityStore } from '@/stores/communities'
	import { useAuthStore } from '@/stores/auth'

	export default {
		name: 'AppUploadImage',
		props: {
			scope: { type: String, default: 'default' },
		},
		emits: ['uploaded'],
		data() {
			return {
				cropper: null,
				loading: false,
				fileName: null,
				fileType: '',

				aspectRatio: 1,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
		},
		methods: {
			triggerPickfile() {
				this.$refs.hiddenFileUploadImage.onPickFile()
			},
			onFilePicked(files) {
				this.$refs.addUploadImageModal.show()
				this.$nextTick(() => {
					const file = files[0]
					const type = file.type.split('/')
					this.fileName = file.name
					this.fileMimeType = file.type
					this.fileType = type[0]
					this.filesUploading = [{ name: file.name, url: '', progress: 0, metadata: file.metadata }]
					this.cropInit(file)
				})
			},
			hide() {
				this.$refs.addUploadImageModal.hide()
				this.$refs.hiddenFileUploadImage.clearFile()
				// close cropper
				const canvas = this.$refs.imageCropperCanvas
				const context = canvas.getContext('2d')
				context.clearRect(0, 0, canvas.width, canvas.height)
				if (this.cropper) this.cropper.destroy()
			},
			fileUploaded(fileData) {
				const self = this
				const imgPreload = new Image()
				imgPreload.onload = () => {
					this.$emit('uploaded', fileData)
					this.loading = false
				}
				imgPreload.src = fileData.src
			},
			progressUploaded(event) {},
			errorFileUploaded(event) {},
			cropInit(file) {
				this.loading = true
				const self = this
				const canvas = this.$refs.imageCropperCanvas
				const context = canvas.getContext('2d')
				context.clearRect(0, 0, canvas.width, canvas.height)
				const reader = new FileReader()
				this.$refs.addUploadImageModal.show()
				// eslint-disable-next-line func-names
				reader.onload = function (evt) {
					const img = new Image()
					// eslint-disable-next-line func-names
					img.onload = function () {
						context.canvas.height = img.height
						context.canvas.width = img.width
						context.drawImage(img, 0, 0)
						if (self.cropper) self.cropper.destroy()
						self.cropper = new Cropper(canvas, {
							aspectRatio: self.aspectRatio,
							autoCropArea: 1,
							viewMode: 2,
							background: false,
							guides: false,
						})
						setTimeout(() => {
							window.dispatchEvent(new Event('resize'))
							self.cropper.reset()
							self.loading = false
						}, 200)
					}
					img.src = evt.target.result
				}
				reader.readAsDataURL(file)
			},
			crop() {
				this.loading = true
				// Get a string base 64 data url
				const croppedImageDataURL = this.cropper.getCroppedCanvas().toDataURL(this.fileType)
				const file = this.cropDataURLtoFile(croppedImageDataURL, this.fileName)
				this.imageCroppedFlag = true
				this.croppedImageSrc = croppedImageDataURL
				file.metadata = { ...file.metadata, id: file.name + Date.now() }
				this.filesUploading = [{ name: file.name, url: '', progress: 0, metadata: file.metadata }]
				this.$refs.hiddenFileUploadImage.uploadFile(file)
			},
			cropReset() {
				this.cropper.reset()
			},
			cropDataURLtoFile(dataurl, filename) {
				const arr = dataurl.split(',')
				const mime = arr[0].match(/:(.*?);/)[1]
				const bstr = atob(arr[1])
				let n = bstr.length
				const u8arr = new Uint8Array(n)

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}

				return new File([u8arr], filename, { type: mime })
			},
		},
	}
</script>

<style>
	@import 'cropperjs/dist/cropper.css';
</style>

<style lang="scss" scoped>
	.canvas-wrapper {
		width: 100%;
		display: block;
		height: 100vh;
		max-height: 350px;
	}
</style>
