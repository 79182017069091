<template>
    <AppModal ref="link-modal" class="link-modal" :title="!attributes.href ? 'Insert Link' : 'Edit Link'"
        class-name="block-modal fade" :close-button="false" :backdrop="false">
        <div class="flex flex-col justify-start columns-sm">
            <AppInput v-model.trim="link.text" icon="edit" class="form-group" placeholder="Name" />
            <div v-if="v$.link.text.$error && !v$.link.text.minLength" class="stan-text-danger mb-3">
                Link name is required
            </div>

            <AppInput v-model.trim="link.href" icon="link" class="form-group" placeholder="https://..." />
            <div v-if="v$.link.href.$error" class="stan-text-danger">
                {{ errorText }}
            </div>
        </div>
        <div class="flex flex-wrap justify-between mt-3">
            <AppButton name="tiptap-link-modal-remove-link-button" outline class="py-2 md-flat mt-1"
                data-dismiss="modal" @click="removeLink">Remove Hyperlink</AppButton>
            <AppButton name="tiptap-link-modal-save" class="py-2 md-flat ml-2 mt-1 w-100px" @click="handleSubmit">Save
            </AppButton>
        </div>
    </AppModal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, url } from '@vuelidate/validators'
import { autoAddHttps } from './utils'

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: {
        editor: {
            type: Object,
            required: true,
        },
        attributes: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            link: {
                href: '',
                text: '',
            },
        }
    },
    validations() {
        return {
            link: {
                href: {
                    maxLength: maxLength(1024),
                    required,
                    url,
                    forbiddenSites: value =>
                        !['onlyfans', 'chaturbate.com', 'solchicks.io', 'pornhub.com'].some(item => value && value.indexOf(item) !== -1),
                },
                text: {
                    maxLength: maxLength(1024),
                    required,
                },
            },
        }
    },
    computed: {
        errorText() {
            if (this.v$.link.href.required.$invalid) {
                return 'URL is required'
            }
            if (this.v$.link.href.forbiddenSites.$invalid) {
                return 'Sorry, redirect to this site is not allowed on Stan'
            }
            return 'Invalid URL'
        },
    },
    methods: {
        handleCancel() {
            this.$refs['link-modal'].hide()
            this.v$.$reset()
        },
        handleSubmit() {
            this.appendLink()
            this.v$.link.$touch()
            if (!this.v$.link.$error) {
                if (this.attributes?.href == null) {
                    this.addLink()
                } else {
                    this.editLink()
                }
            }
        },
        addLink() {
            if (this.attributes.text == this.link.text) {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink(this.prepareLink())
                    .run()

                this.handleCancel()
            } else {
                this.editLink()
            }
        },
        editLink() {
            this.editor
                .chain()
                .focus()
                .deleteSelection()
                .run()

            const cursorPosition = this.editor.view.state.selection.anchor
            this.editor
                .chain()
                .focus()
                .insertContent(this.link.text)
                .setTextSelection({ from: cursorPosition, to: cursorPosition + this.link.text.length })
                .extendMarkRange('link')
                .setLink(this.prepareLink())
                .run()

            this.handleCancel()
        },
        removeLink() {
            this.editor
                .chain()
                .focus()
                .unsetLink()
                .run()
            this.handleCancel()
        },
        prepareLink() {
            return {
                href: this.link.href,
                text: this.link.text,
                target: '_blank',
            }
        },
        assignForm() {
            this.link.text = this.attributes.text
            this.link.href = this.attributes.href
        },
        onLoad() {
            if (this.attributes) {
                this.assignForm()
            }
            this.$refs['link-modal'].show()
        },
        appendLink() {
            this.link.href = autoAddHttps(this.link.href)
            this.v$.link.href.$touch()
        },
    }
}
</script>

<style scoped lang="scss">
.link-modal {
    :deep(.header) {
        @apply text-lg mb-4
    }
}
</style>