<template>
	<!-- Hidden - Keep outside of button to avoid emoji clicks triggering button toggle -->
	<AppEmojiPicker
		ref="emojiPicker"
		:visible="false"
		:react-button-position="reactButton"
		:emoji-opened="emojiOpened"
		@toggle-emoji="updateEmojiOpened"
		@add-emoji="addReaction"
	/>
	<!-- Visible -->
	<div class="post-reaction-container" ref="reactButton" :class="{ selected }" @click="toggleEmojiPicker">
		<AppIcon src="reaction" class="reaction-svg" /> <span class="para-3 text-bold">React</span>
	</div>
</template>
<script>
	export default {
		name: 'PostReactButton',
		props: {
			isLike: {
				type: Boolean,
				default: false,
				description: 'Is this an Emoji or like reaction',
			},
			count: {
				type: Number,
				description: 'Number of reactions',
			},
			emoji: {
				type: String,
				default: '',
				description: 'Emoji Reaction is available',
			},
			selected: {
				type: Boolean,
				default: false,
				description: 'If the user selected this reaction',
			},
		},
		inject: ['reacted'],
		data() {
			return {
				emojiOpened: false,
				reactButton: {
					top: null,
					left: null,
				},
			}
		},
		computed: {
			text() {
				if (this.isLike) {
					return `${this.count} Likes`
				}
				return this.count
			},
		},
		mounted() {
			this.reactButton.top = this.$refs.reactButton.getBoundingClientRect().top
			this.reactButton.left = this.$refs.reactButton.getBoundingClientRect().left
		},
		methods: {
			toggleEmojiPicker(event) {
				this.emojiOpened = !this.emojiOpened
				this.$refs.emojiPicker.openEmoji(event)
			},
			updateEmojiOpened(state) {
				this.emojiOpened = state
			},
			addReaction({ unicode, name }) {
				this.emojiOpened = false
				this.$refs.emojiPicker.closeEmoji()
				this.reacted({ unicode, name })
			},
		},
	}
</script>

<style lang="scss" scoped>
	.post-reaction-container {
		border-width: 1px;
		@apply border-dashed border-stan-gray-strike transition duration-200 ease-in-out hover:bg-stan-gray-light;
		@apply text-stan-text-light leading-none font-semibold -tracking-36;
		@apply py-1.5 px-2.5 rounded-full flex items-center justify-center gap-1.5 cursor-pointer;

		.reaction-svg {
			@apply h-4 w-auto;
		}
	}
</style>
