import { tryGetStateCopyOfMessage, updateMessageDataInState } from '@/stores/communities/messages'

export function reactedInState(message, reaction, store) {
	const messageId = message.message_id
	store._skipMessageUpdate[messageId] = Math.min(store._skipMessageUpdate[messageId] ?? 0, 3)

	let action = 'add'
	let localMessageCopy = addOrIncrementReactionInStateIfNotAlreadyPresent(message, reaction, store)
	if (!localMessageCopy) {
		action = 'remove'
		localMessageCopy = decrementReactionInStateIfNotAlreadyPresent(message, reaction, store)
	}

	if (localMessageCopy) {
		updateMessageDataInState(localMessageCopy, store)
	}

	return action
}

export function addOrIncrementReactionInStateIfNotAlreadyPresent(message, reaction, store) {
	const { member_id: memberId } = reaction
	const targetMessage = tryGetStateCopyOfMessage(message, store)

	if (!targetMessage) {
		return null
	}

	const reactionIndexForCurrentState = targetMessage?.reactions?.findIndex(r => r.name === reaction.name)
	const isReactionAbsent = [undefined, -1].includes(reactionIndexForCurrentState)

	if (isReactionAbsent) {
		return addReactionToMessage(targetMessage, reaction)
	}

	const messageReaction = targetMessage.reactions[reactionIndexForCurrentState]
	const memberIdCheck = messageReaction.member_ids.indexOf(memberId.toString())
	const isMemberIdPresent = memberIdCheck !== -1
	if (isMemberIdPresent) {
		return null
	}

	return incrementReactionInMessage(targetMessage, reaction, reactionIndexForCurrentState)
}

export function decrementReactionInStateIfNotAlreadyPresent(message, reaction, store) {
	const { member_id: memberId } = reaction
	const targetMessage = tryGetStateCopyOfMessage(message, store)
	if (!targetMessage) {
		return null
	}

	const reactionIndex = targetMessage?.reactions?.findIndex(r => r.name === reaction.name)
	const isReactionAbsent = [undefined, -1].includes(reactionIndex)
	if (isReactionAbsent) {
		return null
	}

	const messageReaction = targetMessage.reactions[reactionIndex]
	const memberIdCheck = messageReaction.member_ids.indexOf(memberId.toString())
	const isMemberIdPresent = memberIdCheck !== -1
	if (!isMemberIdPresent) {
		return null
	}

	return decrementReactionInMessage(targetMessage, reaction, reactionIndex)
}

export function addReactionToMessage(message, reaction) {
	const newReaction = {
		name: reaction.name,
		unicode: reaction.unicode,
		member_ids: [reaction.member_id.toString()],
		count: 1,
	}
	message.reactions.push(newReaction)

	return message
}

export function incrementReactionInMessage(message, reaction, reactionIndex) {
	message.reactions[reactionIndex].count += 1
	message.reactions[reactionIndex].member_ids.push(reaction.member_id.toString())

	return message
}

export function decrementReactionInMessage(message, reaction, reactionIndex) {
	message.reactions[reactionIndex].count -= 1
	const memberReactionIndex = message.reactions[reactionIndex].member_ids.indexOf(reaction.member_id.toString())
	message.reactions[reactionIndex].member_ids.splice(memberReactionIndex, 1)

	return message
}
