<template>
	<div class="relative" ref="dropdownContainer" v-on-click-outside="closeMenu">
		<button @click.prevent.stop="toggleMenu()" class="dropdown-button" :class="[{ disabled }]" :disabled="disabled">
			<AppIcon v-if="menu" src="3dot" viewBox="0 0 8 24" />
			<div
				v-else
				class="flex justify-between items-center px-2.5 py-2 bg-stan-gray-light rounded-lg text-sm w-24 sm:w-28"
				:class="[{ 'opacity-50': disabled }, { 'w-full sm:w-full': fullWidth }, dropdownClass]"
			>
				<input
					ref="searchInputEl"
					v-if="searchable && isMenuOpen"
					v-model="searchInput"
					:placeholder="label"
					@keyup.space.prevent.stop
					class="cursor-pointer whitespace-nowrap overflow-hidden"
				/>
				
				<span v-else ref="selectLabelEl" class="w-full whitespace-nowrap overflow-hidden text-ellipsis text-left">{{ label }}</span> 
				<AppIcon src="arrow-drop" class="min-w-fit" :class="{ 'rotate-180': isMenuOpen }" />
			</div>
		</button>
		
		<div v-if="isMenuOpen" :class="['dropdown-menu', borderRadiusClass, { 'dropdown-up': shouldOpenUpward }, dropdownMenuClass]">
			<ul>
				<li
					v-for="{ label, value, iconBefore, optionClass } in displayedOptions"
					:key="label"
					class="rounded-lg"
					:class="optionClass"
					@click.prevent.stop="selectValue(value)"
				>
				<div class="para-2 flex flex-row gap-2 items-end pr-1 whitespace-nowrap overflow-hidden text-ellipsis">
					<AppIcon v-if="iconBefore" :src="iconBefore" /> 
					{{ label }}
				</div>
			</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue'
	import { client } from '@/components/utils/platform'

	const isMenuOpen = ref(false)
	const shouldOpenUpward = ref(false)
	const dropdownContainer = ref(null)
	const props = defineProps({
		options: {
			type: Array,
			default: [],
		},
		menu: {
			type: Boolean,
			default: false,
		},
		initialValue: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		searchable: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		dropdownClass: {
			type: String,
		},
		dropdownMenuClass: {
			type: String,
			default: ''
		}
	})

	const model = defineModel()
	const searchInput = ref('')
	const searchInputEl = ref(null)
	const selectLabelEl = ref(null)

	// intial value
	model.value = model.value ?? props.initialValue ?? props.options[0]?.value

	const label = computed(() => props.options.find(({ value }) => model.value === value)?.label ?? '')

	const displayedOptions = computed(() => props.options.filter(({ isHidden, label }) => {
		if (isHidden) return false

		if (props.searchable) {
			return label.toLowerCase().includes(searchInput.value.toLowerCase())
		}

		return true
	}))

	const borderRadiusClass = computed(() => {
		// on android, border-radius makes div unscrollable for some reason
		if (props.searchable && client.is.android) {
			return 'custom-border-radius'
		} else {
			return 'rounded-xl'
		}
	})

	const emit = defineEmits(['value-selected'])

	const initSearchEl = () => {
		if (!selectLabelEl.value || !props.searchable) {
			return
		}

		const width = window.getComputedStyle(selectLabelEl.value).width
		nextTick(() => {
			if (searchInputEl.value) {
				searchInputEl.value.style.width = width
				searchInputEl.value.focus()
			}
		})
	}

	const clearSearch = () => {
		searchInput.value = ''
	}

	const toggleMenu = () => {
		isMenuOpen.value = !isMenuOpen.value
	}

	const closeMenu = () => {
		isMenuOpen.value = false
	}

	watch(isMenuOpen, newVal => {
		if (newVal) {
			checkDropdownPosition()
			initSearchEl()
		} else {
			clearSearch()
		}
	})

	const checkDropdownPosition = () => {
		if (dropdownContainer.value) {
			const dropdownRect = dropdownContainer.value.getBoundingClientRect()
			const windowHeight = window.innerHeight

			// If there's less space below the dropdown than above, open upward
			shouldOpenUpward.value = dropdownRect.bottom + 200 > windowHeight
		}
	}
	
	const selectValue = val => {
		const selectedOption = props.options.find(({ value }) => val === value)
		if (!selectedOption?.nonSelectable) model.value = val
		emit('value-selected', val)
		closeMenu()
	}

	onMounted(() => {
		window.addEventListener('resize', checkDropdownPosition)
	})

	onUnmounted(() => {
		window.removeEventListener('resize', checkDropdownPosition)
	})
</script>

<style lang="scss" scoped>
	.dropdown-up {
		@apply mt-0 bottom-full top-auto;
	}

	.dropdown-button {
		@apply bg-white rounded-full w-full cursor-pointer;
		&.disabled {
			@apply cursor-not-allowed;
		}
	}

	.dropdown-menu {
		@apply w-full p-2.5 cursor-pointer absolute right-0 z-[10000] bg-white shadow-stan-box-shadow min-w-40;
	}

	.dropdown-menu ul {
		@apply p-0 m-0;
		list-style: none;
	}

	.dropdown-menu li {
		@apply p-2.5;
	}

	.dropdown-menu li:hover {
		transition: ease 0.2s;
		background-color: var(--stan-gray-primary-color);
	}

	.dropdown-menu li a {
		text-decoration: none;
		color: var(--stan-black-1);
	}

	.dropdown-menu li a:hover {
		background: var(--stan-white-3);
	}

	.custom-border-radius {
	  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" rx="10" ry="10" fill="white"/></svg>') no-repeat center;
	}
</style>
