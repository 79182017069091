export async function addMessageToTagMessageIds(message, store) {
	const currentCommunitySlug = message.community_id ? `${store.communityIdToSlugMap[message.community_id]}` : `${store._current_community_slug}`
	const currentChannelName = store.channelIdToNameMap[message.channel_id]

	// Ignore messages that are replies
	const parentMessageId = message.parent_message_id
	if (parentMessageId) {
		return
	}

	// Check if message in master messages list
	const messageId = message.message_id
	if (!store._communities[currentCommunitySlug]?.channels[currentChannelName]?.messages[messageId]) {
		return
	}

	// This is a new message pre-pend to the appropriate tag
	// null is the `all` tag. If no tag is provided we default to all
	const tagId = message?.tag_id
	if (tagId) {
		store._communities[store._current_community_slug].tagMessageIds[tagId] =
			store._communities[store._current_community_slug].tagMessageIds[tagId] || []
		store._communities[store._current_community_slug].tagMessageIds[tagId].unshift(messageId)
	}
	// All messages are added to the `all` tag which is `null`
	const allTagId = null
	store._communities[store._current_community_slug].tagMessageIds[allTagId] =
		store._communities[store._current_community_slug].tagMessageIds[allTagId] || []
	store._communities[store._current_community_slug].tagMessageIds[allTagId].unshift(messageId)
}
