<template>
	<div class="reaction-container" v-if="count > 0 || isLike" @mouseenter="showTooltip" @mouseleave="hideTooltip">
		<div v-if="showMembers" class="hover-area"></div>
		<div @click="handleClick" class="post-reaction-container" :class="{ selected }">
			<AppIcon v-if="isLike" src="thumbs-up" class="reaction-svg" />
			<span class="text-xs" v-else>{{ unicode }}</span>
			<span class="post-reaction-counter para-3 text-bold">
				<span v-if="isLike">
					{{ count == 0 ? 'Like' : `${count} Like${count > 1 ? 's' : ''}` }}
				</span>
				<span v-else>
					{{ count }}
				</span>
			</span>
		</div>
		<transition name="fade">
			<div class="tooltip" v-if="showMembers" ref="tooltipRef">
				<div class="text-gray-400 text-lg text-center w-full">{{ unicode }}</div>
				<ul class="list-none text-left !pt-[10px]">
					<li :key="listItem.name" v-for="listItem of truncatedReactedMemberList">
						<p class="w-full flex">
							<span class="truncate block"> {{ listItem.name }} </span>
							<span v-if="listItem.status !== 'active'" class="text-gray-400 flex-grow">&nbsp;({{ listItem.status }})</span>
						</p>
					</li>
					<li v-if="truncatedCount > 0" @click="openModal" class="more">and {{ truncatedCount }} more...</li>
				</ul>
			</div>
		</transition>

		<ReactedMembersModal v-if="count > 0 || isLike" ref="membersModal" :membersListData="memberListData" :unicodeReactionType="unicode" />
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, inject, ref } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import helpers from '@/global_helper/helpers'
	import ReactedMembersModal from '../modals/ReactedMembersModal.vue'
	const reacted = inject<(payload: { unicode: string; name: string }) => void>('reacted')
	const communityStore = useCommunityStore()

	const props = defineProps({
		isLike: {
			type: Boolean,
			description: 'Weather to show the special like reaction or normal emoji reactions',
		},
		name: {
			type: String,
			description: 'Name of the reaction',
			default: '',
		},
		count: {
			type: Number,
			description: 'Number of reactions',
			default: 0,
		},
		unicode: {
			type: String,
			default: '',
			description: 'Emoji Reaction is available',
		},
		selected: {
			type: Boolean,
			default: false,
			description: 'If the user selected this reaction',
		},
		reactedMembers: {
			type: Array,
			default: [],
			description: 'Ids of who reacted',
		},
	})

	const tooltipRef = ref<HTMLDivElement>()
	const membersModal = ref()
	const truncatedCount = ref(0)
	const showMembers = ref(false)

	const isMobile = computed(() => helpers.isMobile())
	const myMemberId = computed(() => (communityStore.self as Record<string, any>).community_member_id)
	const filteredReactedMembers = computed(() => {
		if (!props.selected) return props.reactedMembers
		return props.reactedMembers.filter(id => id !== `${myMemberId.value}`)
	})
	const truncatedReactedMemberList = computed(() => {
		if (fullReactedMemberList.value.length > 10) {
			truncatedCount.value = fullReactedMemberList.value.length - 10
			return fullReactedMemberList.value.slice(0, 10)
		}
		truncatedCount.value = 0
		return fullReactedMemberList.value
	})
	const memberListData = computed(() => {
		if (props.selected) {
			const yourData = communityStore.getMemberDetails(myMemberId.value)[0]
			return [yourData, ...communityStore.getMemberDetails(filteredReactedMembers.value)]
		}
		return communityStore.getMemberDetails(filteredReactedMembers.value)
	})
	const fullReactedMemberList = computed<{ name: string; status: string }[]>(() => {
		const memberData = communityStore.getMemberDetails(filteredReactedMembers.value)
		const memberNameList = memberData.reduce((names, data) => {
			const fullName: string = data.full_name

			names.push({ name: fullName, status: data.status })
			return names
		}, [])
		if (props.selected) {
			memberNameList.unshift({ name: 'You', status: 'active' })
		}
		return memberNameList
	})

	const handleClick = () => {
		const payload = { unicode: props.unicode, name: props.name }
		reacted?.(payload)
	}
	const openModal = () => {
		membersModal.value.show()
		showMembers.value = false
	}
	const showTooltip = () => {
		if (!truncatedReactedMemberList.value?.length || isMobile.value) return
		showMembers.value = true
	}

	const hideTooltip = () => {
		showMembers.value = false
	}
</script>

<style lang="scss" scoped>
	.hover-area {
		position: absolute;
		top: -8px;
		left: 0;
		right: 0;
		height: 8px;
		background-color: transparent;
		z-index: 90;
	}
	.reaction-container {
		transition: all 0.3s ease-in-out;
		position: relative;
		.tooltip {
			@apply bg-white text-stan-text-dark border;
			position: absolute;
			bottom: 120%;
			left: 50%;
			transform: translateX(-50%);
			color: var(--stan-text-dark-color);
			padding: 10px 10px;
			border-radius: 8px;
			font-size: 12px;
			font-weight: 500;
			border: 1px solid var(--stan-gray-dark-color);
			z-index: 100;
			width: fit-content;
			max-width: 150px;
			white-space: nowrap;
		}

		.tooltip::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-width: 6px;
			border-style: solid;
			border-color: white transparent transparent transparent;
		}

		.tooltip::before {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-width: 7px;
			border-style: solid;
			border-color: var(--stan-gray-dark-color) transparent transparent transparent;
			z-index: -1;
		}
	}
	.post-reaction-container {
		@apply text-stan-text-dark leading-none font-semibold -tracking-36;
		@apply py-1.5 px-2.5 rounded-full flex items-center justify-center bg-stan-gray-soft gap-1.5 cursor-pointer;
		&.selected {
			@apply bg-community-light;
			.post-reaction-counter {
				color: var(--community-reaction-text-color);
			}
			:deep(.reaction-svg) {
				path {
					fill: var(--community-reaction-text-color);
				}
			}
		}

		.reaction-svg {
			@apply h-3 w-auto;
		}
	}
	.more {
		cursor: pointer;
		color: var(--community-primary-dark-color);
	}
	.more:hover {
		text-decoration: underline;
	}
	ul {
		padding: 0;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.2s ease-in-out;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
