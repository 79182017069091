<template>
	<Teleport to="body">
		<AppModal
			ref="reactedMembersModal"
			
			@close="close"
			:closeOnBlur="true"
			:modalStyles="{ height: '500px', 'min-width': 'min(400px, 100%)' }"
			:contentStyles="{ overflow: 'auto' }"
		>
			<template #header>
				<h1 class="sticky mb-2 header">{{ unicodeReactionType }}</h1>
			</template>
			<template #default>
				<ul class="list-none pl-0">
					<li v-for="member of membersListData" :key="member.community_member_id" class="flex justify-between mb-4">
						<span class="items-center flex gap-2 w-full">
							<AppUserImage :name="member?.full_name" :src="member?.profile_image" :color="member?.color" size="md" />
							<p class="flex flex-col w-full truncate">
								<span class="text-stan-text-dark text-sm truncate" :title="member.full_name">{{ member.full_name }} </span>
								<span class="text-gray-400 text-xs flex-grow" v-if="member.status !== 'active'"> ({{ member.status }})</span>
							</p>
						</span>
					</li>
				</ul>
			</template>
		</AppModal>
	</Teleport>
</template>

<script setup>
	import { ref, defineProps } from 'vue'
	import AppUserImage from '../shared/AppUserImage.vue'

	const reactedMembersModal = ref(null)
	const props = defineProps({
		membersListData: {
			type: Array,
			default: [],
			description: 'Data of who reacted',
		},
		unicodeReactionType: {
			type: String,
			default: '',
			description: 'Unicode character of reaction',
		},
	})

	const show = () => {
		reactedMembersModal.value.show()
	}
	const close = () => {
		reactedMembersModal.value.hide()
	}

	defineExpose({
		show,
	})
</script>

<style lang="scss" scoped>
	.member-list {
		list-style: none;
	}
	.invite-user-modal {
		:deep(.modal-inner-title) {
			overflow: visible;
		}
		:deep(.modal) {
			max-height: 500px;
		}
	}
	.header {
		@apply w-full text-center;
		h3 {
			@apply mt-0;
		}
	}
</style>
