import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

export function convertToDigitalFormat(seconds, round = false) {
	const roundedSeconds = round ? Math.round(seconds) : seconds
	const ss = roundedSeconds % 60
	const mins = (roundedSeconds - ss) / 60
	const secs = ss < 10 ? `0${ss}` : ss

	return `${mins}:${secs}`
}

class BaseAudioPlayer {
	constructor() {
		this.isReadyRef = ref(false)
		this.isPlayRef = ref(false)
		this.duration = ref(null)
		this.hasEnded = ref(false)

		this.audioContext = new AudioContext()
		this.fileReader = new FileReader()
		this.audioBuffer = null
		this.decodeArrayBuffer = null
		this.source = null
		this.pausedAt = null
		this.startedAt = null
	}

	play() {
		if (this.isReadyRef.value && !this.isPlayRef.value) {
			this.isPlayRef.value = true
			this.hasEnded.value = false
			this.source = this.audioContext.createBufferSource()
			this.source.buffer = this.audioBuffer
			this.source.connect(this.audioContext.destination)
			this.source.onended = () => {
				this.isPlayRef.value = false
				this.hasEnded.value = true
			}
			if (this.pausedAt) {
				this.startedAt = Date.now() - this.pausedAt
				this.source.start(0, Math.floor(this.pausedAt / 1000))
			} else {
				this.startedAt = Date.now()
				this.source.start(0)
			}
		}
	}

	pause() {
		this.isPlayRef.value = false
		this.source !== null && this.source.stop(0)
		this.pausedAt = Date.now() - this.startedAt
	}

	stop() {
		this.isPlayRef.value = false
		this.source !== null && this.source.stop()
		this.startedAt = null
		this.pausedAt = null
	}

	destroy() {
		this.audioContext !== null && this.audioContext.close()
		this.isReadyRef.value = false
		this.isPlayRef.value = false
		this.duration.value = null
		this.audioContext = null
		this.fileReader = null
		this.arrayBuffer = null
		this.source = null
	}
}

class BlobPlayer extends BaseAudioPlayer {
	constructor(blob) {
		super()
		this.blob = blob
	}

	load() {
		return new Promise((resolve, reject) => {
			this.fileReader.onloadend = () => {
				const arrayBuffer = this.fileReader.result
				this.audioContext.decodeAudioData(arrayBuffer, ab => {
					this.audioBuffer = ab
					this.duration.value = ab.duration
					this.isReadyRef.value = true
					resolve(this.isReadyRef.value)
				})
			}
			this.fileReader.readAsArrayBuffer(this.blob)
		})
	}
}

class UrlPlayer extends BaseAudioPlayer {
	constructor(src) {
		super()
		this.src = src
	}

	async load() {
		this.audioBuffer = await fetch(this.src)
			.then(res => res.arrayBuffer())
			.then(buffer => {
				this.isReadyRef.value = true
				return this.audioContext.decodeAudioData(buffer)
			})
		this.duration.value = this.audioBuffer.duration
	}
}

export default function useAudio(src, blob, options = {}) {
	const currentTime = ref(0)
	const isReady = ref(false)
	const isPlaying = ref(false)
	const isMuted = ref(false)
	const timer = ref(null)
	const showPlayTime = computed(() => convertToDigitalFormat(currentTime.value))

	const player =
		typeof src !== 'undefined' && src !== null ? new UrlPlayer(src) : typeof blob !== 'undefined' && blob !== null ? new BlobPlayer(blob) : null

	if (player === null) {
		throw new Error('audio source not found')
	}

	const formattedDuration = computed(() => convertToDigitalFormat(player.duration.value, true))

	const reset = () => {
		currentTime.value = 0
	}

	const playProgress = () => {
		currentTime.value += 1
	}

	const startPlayback = () => {
		player.play()
		reset()
	}

	const stopPlayback = () => {
		player.stop()
		reset()
	}

	const togglePlayback = ev => {
		ev.preventDefault()

		if (!isPlaying.value) {
			player.play()
		} else {
			player.pause()
		}
		isPlaying.value = !isPlaying.value
	}

	watch(player.isPlayRef, val => {
		isPlaying.value = val
	})

	watch(isPlaying, val => {
		if (val) {
			timer.value = setInterval(playProgress, 1000)
		} else {
			clearInterval(timer.value)
			if (currentTime.value > player.duration.value - 1) {
				stopPlayback()
			}
		}
	})

	onMounted(async () => {
		if (options?.autoPlay) {
			isPlaying.value = true
		}
		await player.load()
		isReady.value = true
	})

	onUnmounted(async () => {
		player !== null && player.destroy()
	})

	return {
		showPlayTime,
		currentTime,
		isReady,
		isPlaying,
		isMuted,
		fileDuration: formattedDuration,
		startPlayback,
		stopPlayback,
		togglePlayback,
	}
}
