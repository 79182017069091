/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import axios from 'axios'
import themeHelper from '@/global_helper/theme'

// eslint-disable-next-line import/prefer-default-export
export const useCommunityPublicStore = defineStore('public', {
	state: () => ({
		_communities: {},
		_current_community_slug: null,
		_store_id: null,
		_experiments: null,
		_name: null,
		_theme: null,
		_status: null,
		_user_store_image: null,
		_username: null,
		_current_community_load_status: {
			public: false,
		},
	}),
	getters: {
		communities: state => state._communities,
		currentCommunity: state => state._communities[state._current_community_slug] || {},
		name: state => state._name,
		theme: state => state._theme,
		status: state => state._status,
		user_store_image: state => state._user_store_image,
		store_id: state => state._store_id,
		experiments: state => state._experiments,
		username: state => state._username,
		banner_image: state => state._banner_image,
		hasLoaded: state => Object.values(state._current_community_load_status).reduce((loaded, val) => loaded && val, true),
	},
	actions: {
		getCommunity() {
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${this._current_community_slug}/public`)
					.then(response => {
						this._communities[this._current_community_slug] = response.data
						this._current_community_load_status.public = true
						resolve(this._communities)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		async setCurrentCommunity(communitySlug, forceReload = false) {
			this._current_community_slug = communitySlug
			if (!this.hasLoaded || forceReload) {
				try {
					await this.getCommunity()
				} catch (err) {
					throw err
				}
			}
			const currCommunity = this._communities[communitySlug]
			if (currCommunity) {
				this._name = currCommunity.name
				this._store_id = currCommunity.store_id
				this._experiments = currCommunity.experiment_ids
				this._theme = currCommunity.theme
				this._user_store_image = currCommunity.user_store_image
				this._username = currCommunity.username
				this._status = currCommunity.status
				this._banner_image =
					currCommunity.banner_image || 'https://assets.stanwith.me/community/live/CommunityDefaultBanner/CommunityDefaultBanner.jpeg'

				// Set theme data on data load
				themeHelper.addThemeColorsToDocument(this.theme)
			} else {
				this._name = null
				this._theme = null
			}
		},
		setBannerImage(src) {
			this._banner_image = src
		},
	},
})
