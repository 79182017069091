<template>
	<img v-if="src" :src="src" :alt="name" :class="size" class="app-user-img" />
	<div v-else class="text-bold" :class="size" :style="style">
		{{ initials }}
	</div>
</template>
<script>
	export default {
		props: {
			src: {
				type: String,
			},
			name: {
				type: String,
				required: true,
			},
			color: {
				type: String,
				required: true,
				default: '#FF507A',
			},
			size: {
				type: String,
				default: 'sm',
			},
		},
		computed: {
			initials() {
				return this.$commonHelper.getInitials(this.name)
			},
			style() {
				return { backgroundColor: `${this.color}` }
			},
		},
	}
</script>
<style lang="scss" scoped>
	img {
		@apply rounded text-white flex items-center justify-center uppercase leading-none aspect-square w-full;
	}

	div {
		@apply rounded text-white flex items-center justify-center uppercase leading-none aspect-square w-full;
	}

	.sm {
		@apply min-h-4 max-h-4 min-w-4 max-w-4;
		font-size: 8px !important;
	}

	.md {
		@apply min-h-8 max-h-8 min-w-8 max-w-8;
		font-size: 12px !important;
	}

	.lg {
		@apply min-h-20 max-h-20 min-w-20 max-w-20;
		font-size: 16px !important;
	}
</style>
