<template>
	<div class="members-horizontal-list-container">
		<template v-for="(member, index) in membersShow" :key="index">
			<AppUserImage :name="member?.full_name" :src="member?.profile_image" :color="member?.color" size="md" />
		</template>
		<div class="more-text">{{ moreText }}</div>
	</div>
</template>
<script>
	export default {
		name: 'MembersHorizontalList',
		props: {
			members: {
				type: Array,
				validator(value, props) {
					let pass = true
					value.forEach(v => {
						if (v?.profile_image !== undefined && !v?.full_name) {
							pass = false
						}
					})
					return pass
				},
			},
		},
		computed: {
			membersShow() {
				return this.members.length > 3 ? this.members.slice(0, Math.min(7, this.members.length)) : []
			},
			moreText() {
				if (this.members.length - 7 > 0) {
					return `+${this.members.length - 7} more`
				}
				return null
			},
		},
	}
</script>

<style lang="scss" scoped>
	.members-horizontal-list-container {
		@apply flex flex-row flex-wrap gap-1.5 items-center;

		.more-text {
			@apply ml-1.5 text-stan-text-light;
		}
	}
</style>
