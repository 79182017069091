export default function (app) {
	const requireComponent = import.meta.glob('@/components/**/*.vue', {
		import: 'default',
		eager: true,
	})
	const uniqueKeys = {}
	Object.keys(requireComponent).forEach(fileName => {
		const componentConfig = requireComponent[fileName]
		const componentName = fileName
			.split('/')
			.pop()
			.replace(/\.\w+$/, '')

		// Make sure the component has a unique name property
		if (uniqueKeys[componentName]) {
			throw new Error(`Duplicate component name found: ${componentName}`)
		} else {
			uniqueKeys[componentName] = true
		}
		if (typeof componentConfig === 'function') componentConfig().then(c => app.component(componentName, c.default || componentConfig))
		else app.component(componentName, componentConfig.default || componentConfig)
	})
}
