<template>
	<div class="confetti-container">
		<div v-for="index in 250" :key="index" class="confetti custom" :style="randomClass()"></div>
	</div>
</template>

<script>
	export default {
		methods: {
			randomLeft() {
				return Math.random() * 100
			},
			randomDelay() {
				return Math.ceil(Math.random() * 4)
			},
			randomClass() {
				const colors = ['#ff4136', '#ffdc00', '#0074d9', '#2ecc40']
				const shapes = ['polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', 'circle()']
				const c = {
					left: `${this.randomLeft()}%`,
					'animation-duration': `${Math.random() * 3}s`,
					'animation-delay': `${Math.random() * 5}s`,
					'background-color': colors[Math.floor(Math.random() * colors.length)],
					'clip-path': shapes[Math.floor(Math.random() * shapes.length)],
				}
				return c
			},
		},
	}
</script>

<style lang="scss" scoped>
	/* Set up the container for the confetti */

	.confetti-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		pointer-events: none;
	}

	/* Create the confetti */
	.confetti {
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
		pointer-events: none;
		transform: rotateZ(45deg);
	}

	.custom {
		// animation: confetti-fall 4s ease-out forwards, confetti-drift 20s ease-in-out infinite;
		// left: 5%;
		animation-name: confetti-fall;
		animation-timing-function: ease-out;
		animation-fill-mode: forwards;
	}

	/* Animate the confetti falling */
	@keyframes confetti-fall {
		0% {
			top: -50px;
			opacity: 1;
			transform: rotateZ(0deg) translateX(0);
		}
		100% {
			top: 100%;
			opacity: 0;
			transform: rotateZ(360deg) translateX(30px);
		}
	}

	/* Animate the confetti drifting */
	@keyframes confetti-drift {
		0% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(20px);
		}
		100% {
			transform: translateX(0);
		}
	}
</style>
