function debounce(func, wait, immediate) {
	let timeout
	let args
	let context
	let timestamp
	let result
	if (wait == null) {
		wait = 100
	}

	function later() {
		const last = Date.now() - timestamp

		if (last < wait && last >= 0) {
			timeout = setTimeout(later, wait - last)
		} else {
			timeout = null
			if (!immediate) {
				result = func.apply(context, args)
				context = null
				args = null
			}
		}
	}

	const debounced = function (...arges) {
		context = this
		timestamp = Date.now()
		args = arges
		const callNow = immediate && !timeout
		if (!timeout) {
			timeout = setTimeout(later, wait)
		}

		if (callNow) {
			result = func.apply(context, args)
			context = null
			args = null
		}

		return result
	}

	debounced.clear = function () {
		if (timeout) {
			clearTimeout(timeout)
			timeout = null
		}
	}

	debounced.flush = function () {
		if (timeout) {
			result = func.apply(context, args)
			context = null
			args = null

			clearTimeout(timeout)
			timeout = null
		}
	}

	return debounced
}

// // Adds compatibility for ES modules
// debounce.debounce = debounce

// export { debounce }

export default app => {
	app.config.globalProperties.$debounce = debounce
}
