export const extractMentions = (contentToSend) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(contentToSend, 'text/html')
    const mentions = doc.querySelectorAll('.mention, .current-user-mention')
    const mentionsToSend = []
    
    // Extract and log the data-id attributes
    mentions.forEach(mention => {
        let dataId = mention.getAttribute('data-id')
        let mentionContent = mention.textContent.trim()
        mentionsToSend.push({
            id: dataId,
            denotationChar: '@',
            value: mentionContent,
            index: dataId,
        })
    })
    return mentionsToSend
}