<template>
	<label class="checkbox-container" :class="[sizeClass, { disabled: disabled }]">
		<input :id="toggleId" type="checkbox" v-model="localValue" :disabled="disabled" />
		<span class="checkmark"><AppIcon src="verified" /></span>
	</label>
</template>
<script>
	export default {
		name: 'AppCheckbox',
		props: {
			modelValue: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String,
				default: 'md',
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			toggleId: {
				type: String,
				default: 'toggle-id',
			},
		},
		data() {
			return {
				sizeMapping: {
					default: {
						sizeClass: '',
					},
					sm: {
						sizeClass: 'ts-sm',
					},
					md: {
						sizeClass: '',
					},
					lg: {
						sizeClass: 'ts-lg',
					},
				},
			}
		},
		computed: {
			localValue: {
				get: function getter() {
					return this.modelValue
				},
				set: function setter(value) {
					this.$emit('update:modelValue', value)
				},
			},
			active() {
				return !!this.val
			},
			sizeClass() {
				return this.sizeMapping[this.size]?.sizeClass ?? this.sizeMapping.default.sizeClass
			},
		},
	}
</script>
<style lang="scss" scoped>
	.checkbox-container {
		position: relative;
		padding-left: 16px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.checkbox-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		@apply bg-transparent absolute inset-0 border-community-primary border-solid border-2 rounded box-border;

		height: 16px;
		width: 16px;
		:deep(svg) {
			@apply w-3 h-3 hidden;
			path {
				fill: var(--community-primary-d-text-color);
			}
		}
	}

	.checkbox-container:hover input ~ .checkmark {
		@apply bg-community-light;
		svg {
			@apply block opacity-50;
		}
	}

	.checkbox-container input:checked ~ .checkmark {
		@apply bg-community-primary;
		svg {
			@apply block opacity-100;
		}
	}
</style>
