/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import axios from 'axios'
import { DateTime } from 'luxon'
import CONSTANTS from '@/global_helper/constants'

// eslint-disable-next-line import/prefer-default-export
export const useProductsStore = defineStore('products', {
	state: () => ({
		_products: {},
		_loading: false,
		_current_community_slug: null,
	}),
	getters: {
		products: state => state._products[state._current_community_slug] || [],
		loading: state => state._loading,
		currentCommunitySlug: state => state._current_community_slug,
		webinars: state => state._products[state._current_community_slug]?._webinars || {},
		resourceProducts: state => state._products[state._current_community_slug]?._resourceProducts || {},
		selectedWebinars: state => state._products[state._current_community_slug]?.selectedWebinars || [],
		
	},
	actions: {
		getProducts() {
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/products`)
					.then(response => {
						this._products[this._current_community_slug] = response.data.products
						resolve(this._products)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
					.finally(() => {
						this._loading = false
					})
			})
		},
		async setCurrentCommunity(communitySlug) {
			this._current_community_slug = communitySlug
			// Tries to get community data if don't already have it
			if (!this._products[communitySlug]) {
				await this.getProducts()
				await this.getWebinars()
				await this.getResourceProducts()
			}
		},
		async getPreAuthCourseLink(courseId) {
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${this._current_community_slug}/course/${courseId}/link`)
					.then(response => {
						resolve(response.data?.link)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		getResourceProducts(){
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/resource_products`)
					.then(response => {
						const resourceProducts = response.data.resource_products
						this._products[this._current_community_slug]._resourceProducts = resourceProducts
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		getWebinars(){
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/webinars`)
					.then(response => {
						const webinars = response.data.webinars
						const updatedWebinars = webinars.map(webinar => {
							const { sessions, timezone } = webinar.data.product.webinar;
							const updatedSessions = sessions.map(session => {
							  const datetime = DateTime.fromFormat(
								`${session.date} ${session.time}`,
								CONSTANTS.DATE_TIME_FORMATS.FULL_DATE_TIME,
								{ zone: timezone }
							  ).toJSDate();
						  
							  return { ...session, datetime };
							});
						  
							return {
							  ...webinar,
							  data: {
								...webinar.data,
								product: {
								  ...webinar.data.product,
								  webinar: {
									...webinar.data.product.webinar,
									sessions: updatedSessions
								  }
								}
							  }
							};
						  });
						this._products[this._current_community_slug]._webinars = updatedWebinars
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
					.finally(() => {
						this._loading = false
					})
			})
		},
	},
})
