/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

const UUID = () => {
	const lut = []
	for (let i = 0; i < 256; i++) {
		lut[i] = (i < 16 ? '0' : '') + i.toString(16)
	}
	const d0 = (Math.random() * 0xffffffff) | 0
	const d1 = (Math.random() * 0xffffffff) | 0
	const d2 = (Math.random() * 0xffffffff) | 0
	const d3 = (Math.random() * 0xffffffff) | 0
	return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${lut[d1 & 0xff]}${lut[(d1 >> 8) & 0xff]}-${
		lut[((d1 >> 16) & 0x0f) | 0x40]
	}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${lut[(d2 >> 8) & 0xff]}-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${
		lut[d3 & 0xff]
	}${lut[(d3 >> 8) & 0xff]}${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`
}

// eslint-disable-next-line import/prefer-default-export
export const useGlobalsStore = defineStore('globals', {
	state: () => ({
		_textSearch: null,
		_recentSearches: [],
		_pageTitle: null,
		_pageLoading: true,
		_showAddEditChannelModal: false,
		_isEditingChannel: false,
		_show_navigation: false,
		_navigation_type: null,
		_is_mobile: false,
		_ip: null,
		cookieUUID: Cookies.get('cookie_uuid') || UUID(),
		sessionUUID: Cookies.get('session_uuid') || UUID(),
	}),
	getters: {
		getIPAddress: state => state._ip,
		textSearch: state => state._textSearch,
		recentSearches: state => state._recentSearches,
		pageTitle: state => state._pageTitle,
		pageLoading: state => state._pageLoading,
		showAddEditChannelModal: state => state._showAddEditChannelModal,
		isEditingChannel: state => state._isEditingChannel,
		showNavigation: state => state._show_navigation,
		navigationType: state => state._navigation_type,
		isMobile: state => state._is_mobile,
		getSessionUUID: state => {
			// We save session for 10 minutes and renew every time there's an event to be tracked
			const sessionUuid = state.sessionUUID
			const in10Minutes = 1 / 24 / 6 // js-cookies uses days as base unit
			Cookies.set('session_uuid', sessionUuid, { expires: in10Minutes })
			Cookies.set('session_uuid', sessionUuid, { domain: 'stanwith.me', expires: in10Minutes })
			Cookies.set('session_uuid', sessionUuid, { domain: 'stan.store', expires: in10Minutes })
			return sessionUuid
		},

		getCookieUUID: state => {
			const cookieUuid = state.cookieUUID
			const in1Year = 1 * 365 // js-cookies uses days as base unit
			Cookies.set('cookie_uuid', cookieUuid, { expires: in1Year })
			Cookies.set('cookie_uuid', cookieUuid, { domain: 'stanwith.me', expires: in1Year })
			Cookies.set('cookie_uuid', cookieUuid, { domain: 'stan.store', expires: in1Year })
			return cookieUuid
		},
	},
	actions: {
		setTextSearch(options) {
			if (options === null) {
				this._textSearch = null
				return
			}
			const { text, channelId = null, channelTitle = null, returnRoute = null } = options
			this._textSearch = {
				text,
				channelId,
				channelTitle,
				returnRoute,
			}
			const present = this._recentSearches.filter(s => s.text === text && s.channelId === channelId)
			if (present.length > 0) {
				return
			}
			if (this._recentSearches.length > 10) {
				this._recentSearches.splice(this._recentSearches.length - 1, 1)
			}
			this._recentSearches.splice(0, 0, this._textSearch)
		},
		setIPAddress(ip) {
			this._ip = ip
		},
		clearTextSearch() {
			this._textSearch = null
		},
		removeFromRecentSearches(id) {
			this._recentSearches.splice(id, 1)
		},
		setPageTitle(title) {
			this._pageTitle = title
		},
		setPageLoading(loading) {
			this._pageLoading = loading
		},
		setShowAddEditChannelModal(show) {
			this._showAddEditChannelModal = show
		},
		setIsEditingChannel(show) {
			this._isEditingChannel = show
		},
		setShowNavigation(show) {
			this._show_navigation = show
		},
		setNavigationType(type) {
			const acceptableNavigationTypes = ['channels', 'dms', 'more']
			if (acceptableNavigationTypes.indexOf(type) > -1) this._navigation_type = type
			else {
				this._show_navigation = false
				this._navigation_type = null
			}
		},
		setIsMobile(isMobile) {
			this._is_mobile = isMobile
		},
	},
})
