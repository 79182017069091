<template>
	<div class="post-header-container" :class="fontClass">
		<div class="flex items-center gap-1.5">
			<span class="name">{{ senderName }}</span>
			<AppIcon v-if="isOwner" class="creator-check-svg" src="verified" />
		</div>
		<span class="time para-3">{{ localSentTimeText }}</span>
	</div>
</template>
<script setup>
	import { DateTime } from 'luxon'
	import { defineProps, computed } from 'vue'
	import commonHelpers from '@/global_helper/helpers'
	import { useCommunityStore } from '@/stores/communities'
	import constants from '@/global_helper/constants'

	const props = defineProps({
		sender: {
			type: Object,
			required: true,
		},
		time: {
			type: DateTime,
			default: DateTime.now(),
			required: true,
		},
		size: {
			type: String,
			default: 'md',
			require: false,
		},
	})
	const senderName = computed(() => {
		if(props.sender?.status === constants.MEMBER_STATUS.ACTIVE){
			return props.sender?.data?.full_name
		}
		return props.sender?.data?.full_name + " (Inactive)"
	})

	const localSentTimeText = computed(() => commonHelpers.localSentTimeText(props.time))
	const fontClass = computed(() => {
		switch (props.size) {
			case 'sm':
				return 'para-2'
			default:
				return 'para-1'
		}
	})
	const communityStore = useCommunityStore()

	const isOwner = computed(() => {
		const memberId = props.sender?.community_member_id
		if (memberId) {
			return communityStore.members[memberId].type === constants.MEMBER_TYPES.OWNER
		}
		return false
	})
</script>

<style lang="scss" scoped>
	.post-header-container {
		@apply flex flex-wrap sm:flex-nowrap flex-row gap-2 items-end;
		@media (max-width: 1023px) {
			@apply grow;
		}

		.name {
			@apply text-stan-text-dark -tracking-26 leading-none font-semibold;
		}
		:deep(.creator-check-svg) {
			@apply bg-community-primary rounded-full p-0.5 h-3.5 w-3.5;
			path {
				fill: var(--community-primary-d-text-color);
			}
		}

		.time {
			@apply text-stan-gray-dark -tracking-40 sm:pt-1 leading-none;
		}
	}
</style>
