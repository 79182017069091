<template>
	<div class="w-full">
		<ul class="p-0 flex flex-row flex-nowrap justify-start gap-4" :id="id" role="tablist" :class="[sizeClass]">
			<li
				v-for="item in items"
				class="nav-item"
				:class="{ 'nav-item-error': item.error }"
				role="presentation"
				:key="`${item.id}-tab`"
				:id="`${item.id}-tab`"
			>
				<a
					class="nav-link h4 font-medium"
					:class="{ active: selectedTab === item.id }"
					data-toggle="tab"
					:href="`${item.id}`"
					role="tab"
					:aria-controls="`${item.id}`"
					aria-selected="true"
					@click.prevent="onTabSelected(item.id)"
				>
					<template v-if="item.icon">
						<AppIcon v-if="loadSvg" :src="item.icon" class="tab-icon me-2" />
						<img v-else :src="item.icon" class="tab-icon me-2" />
					</template>
					<span class="text-truncate">{{ item.title }}</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script setup>
	import { ref, onMounted, watch } from 'vue'
	import commonHelper from '@/global_helper/helpers'

	const props = defineProps({
		id: {
			type: String,
			require: true,
			default: '',
		},
		items: {
			type: Array,
			require: true,
			default: () => [],
		},
		activeTab: {
			type: String,
			default: undefined,
		},
		loadSvg: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String,
			default: 'medium',
		},
	})

	const sizeClass = ref([])
	const selectedTab = ref(null)

	const emit = defineEmits(['initialised', 'tabSelected'])

	const getSize = size => {
		switch (size) {
			case 'small':
				return 'tabs-small'
			case 'medium':
				return 'tabs-medium'
			case 'large':
				return 'tabs-large'
			default:
				return 'tabs-medium'
		}
	}

	const onTabSelected = tabId => {
		selectedTab.value = tabId
		emit('tabSelected', tabId)
		if (commonHelper.isMobile()) {
			const container = document.getElementById(`${tabId}-tab`)
			container?.scrollIntoView({
				behavior: 'smooth', // or "auto" or "instant"
				block: 'nearest', // or "end"
				inline: 'nearest',
			})
		}
	}
	sizeClass.value = getSize(props.size)

	onMounted(() => {
		if (props.activeTab) onTabSelected(props.activeTab)
		emit('initialised')
	})
	watch(
		() => props.activeTab,
		newVal => {
			selectedTab.value = newVal
		},
		{ deep: true, immediate: true }
	)
</script>

<style lang="scss" scoped>
	.nav-item {
		@apply list-none font-normal text-xs leading-none;
		a.nav-link {
			transition: all 0.2s ease;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			border-bottom: 2px solid transparent;
			color: var(--stan-text-light-color);

			line-clamp: round;
			display: flex;
			align-items: center;

			color: var(--stan-text-light-color);
			transition: 0.2s ease;
			&::before {
				content: none;
			}
			:deep(.tab-icon) {
				width: 20px;
				height: 20px;
				rect:not(.no-fill),
				path:not(.no-fill) {
					fill: var(--stan-text-dark-color);
				}
			}
			&.active {
				border-color: var(--community-primary-color);
			}
			&:hover,
			&.active {
				color: var(--stan-text-dark-color);

				:deep(.tab-icon) {
					rect:not(.no-fill),
					path:not(.no-fill) {
						fill: var(--community-primary-color);
					}
				}
			}
		}

		&.nav-item-error {
			a.nav-link {
				color: var(--stan-danger);

				&::before {
					content: none;
				}
				:deep(.tab-icon) {
					rect:not(.no-fill),
					path:not(.no-fill) {
						fill: var(--stan-danger);
					}
				}
				&:hover,
				&.active {
					color: var(--stan-danger);
					border-color: var(--stan-danger);
					:deep(.tab-icon) {
						rect:not(.no-fill),
						path:not(.no-fill) {
							fill: var(--stan-danger);
						}
					}
				}
			}
		}
	}
</style>

<!-- 
<style lang="scss" scoped>
	.app-tabs {
		border-bottom: none;
		overflow-x: auto;
		overflow-y: hidden;

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		.nav-item {
			min-width: auto;
			font-weight: 400;
			font-size: 0.8rem;
			line-height: 1;
			a.nav-link {
				padding: 0.5rem 1rem;
				border-radius: 30px;
				border: 1.5px solid;
				border-color: transparent;
				display: flex;
				align-items: center;
				color: var(--stan-text-dark-color);

				&::before {
					content: none;
				}
				:deep(.tab-icon) {
					width: 20px;
					height: 20px;
					rect:not(.no-fill),
					path:not(.no-fill) {
						fill: var(--stan-text-dark-color);
					}
				}
				&:hover,
				&.active {
					color: var(--community-primary-color);
					border-color: var(--community-primary-color);
					:deep(.tab-icon) {
						rect:not(.no-fill),
						path:not(.no-fill) {
							fill: var(--community-primary-color);
						}
					}
				}
				&:not(.active):hover {
					border-color: transparent;
				}
			}

			&.nav-item-error {
				a.nav-link {
					color: var(--stan-danger);

					&::before {
						content: none;
					}
					:deep(.tab-icon) {
						rect:not(.no-fill),
						path:not(.no-fill) {
							fill: var(--stan-danger);
						}
					}
					&:hover,
					&.active {
						color: var(--stan-danger);
						border-color: var(--stan-danger);
						:deep(.tab-icon) {
							rect:not(.no-fill),
							path:not(.no-fill) {
								fill: var(--stan-danger);
							}
						}
					}
				}
			}
		}
	}
</style> -->
