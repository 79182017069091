<template>
	<AppSelectDropdown
		:menu="true"
		dropdown-menu-class="!min-w-max"
		:options="selectOptions"
		@value-selected="value => valueSelected(value)"
	/>
	<AppModal
		ref="deleteModal"
		title="Just Confirming!"
		subheading="Are you sure you want to delete this post?"
		action="Delete Post"
		secondaryAction="Never Mind"
		@cta="deletePost"
		@secondary-cta="closeDeleteModal"
		@close="closeDeleteModal"
	>
	</AppModal>
</template>
<script>
	import { useCommunityStore } from '@/stores/communities'

	export default {
		props: {
			isPinned: {
				type: Boolean,
				required: true,
			},
			canDelete: {
				type: Boolean,
			},
			canEdit: {
				type: Boolean,
			},
		},
		emits: ['deletePost', 'togglePin', 'editPost'],
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			canModifyChannels() {
				return this.communityStore.canModifyChannels
			},
			selectOptions() {
				const options = []

				if (this.canModifyChannels && this.isPinned) {
					options.push({value: 'unpin', label: 'Unpin', iconBefore: 'pin-fill'})
				}
				if (this.canModifyChannels && !this.isPinned) {
					options.push({value: 'pin', label: 'Pin', iconBefore: 'pin'})
				}
				if (this.canEdit) {
					options.push({value: 'edit', label: 'Edit', iconBefore: 'edit'})
				}
				if (this.canDelete) {
					options.push({value: 'delete', label: 'Delete', iconBefore: 'trash'})
				}
				
				return options
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			valueSelected(value) {
				switch (value) {
					case 'pin':
					case 'unpin':
						this.handlePin()
						break
					case 'edit':
						this.handleEditPost()
						break
					case 'delete':
						this.showDeleteModal()
						break
				}
			},
			closeDeleteModal() {
				this.$refs?.deleteModal.hide()
			},
			showDeleteModal() {
				this.$refs?.deleteModal.show()
			},
			deletePost() {
				this.$emit('deletePost')
			},
			handlePin() {
				this.$emit('togglePin')
			},
			handleEditPost() {
				this.$emit('editPost')
			},
		},
	}
</script>