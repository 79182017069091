/* eslint-disable import/extensions */
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/stores/auth'
import { useCommunityStore } from '@/stores/communities'
import router from '@/router'
import { useGlobalsStore } from '../stores/globals'

// Request interceptor
axios.interceptors.request.use(request => {
	const store = useAuthStore()
	const token = store.token
	if (token) {
		request.headers.Authorization = `Bearer ${token}`
	}
	// request.headers.common['x-request-id'] = UUID()
	// request.headers.common['x-session-uuid'] = getSessionUUID()
	// Sentry.setContext('Logging Ids', { 'Session Id': request.headers.common['x-session-uuid'] })
	return request
})

// Response interceptor
axios.interceptors.response.use(
	response => {
		const globalsStore = useGlobalsStore()

		if (typeof response.data === 'object' && response?.data?.ip) {
			globalsStore.setIPAddress(response.data.ip)
		}
		return response
	},
	error => {
		const authStore = useAuthStore()
		const communityStore = useCommunityStore()

		const status = error?.response?.status // Possibility of response being undefined.

		if (status === 401 && authStore.token && router.currentRoute.value.name !== '404') {
			authStore.logout()
			communityStore.logout()
			router.push({ name: 'login' })
		}

		return Promise.reject(error)
	}
)
