<template>
	<div class="media-gallery">
		<div class="media-container">
			<Transition :name="animationDirection">
				<template v-if="currentMedia?.type.startsWith('image')">
					<img :src="currentMedia.url" :alt="currentMedia.name" :key="currentMedia.name" class="media-item" />
				</template>
				<template v-else-if="currentMedia?.type.startsWith('video')" :key="currentMedia.name">
					<Video
						:videoId="currentMedia.url"
						:video-src="currentMedia.url"
						:fcp-complete="true"
						:avoidDefaultSpacing="true"
						class="media-item w-full"
						key="video"
					></Video>
				</template>
			</Transition>
		</div>
		<div class="controls" v-if="showControls">
			<button @click="showPrevious" aria-label="Previous" class="control-button"><AppIcon src="arrow-left" /></button>
			<button @click="showNext" aria-label="Next" class="control-button"><AppIcon src="arrow-right" /></button>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue'

	const props = defineProps({
		assets: {
			type: Array,
			required: true,
			default: () => [],
		},
		initialIndex: {
			type: Number,
			default: 0,
		},
	})

	const currentIndex = ref(props.initialIndex)
	const isSlidingLeft = ref(false)
	const isSlidingRight = ref(false)
	const animationDirection = ref('next')

	const showControls = computed(() => props.assets.length > 1)

	const currentMedia = computed(() => props.assets[currentIndex.value])

	const showPrevious = () => {
		animationDirection.value = 'previous'
		isSlidingLeft.value = true
		currentIndex.value = (currentIndex.value - 1 + props.assets.length) % props.assets.length

		isSlidingLeft.value = false
	}

	const showNext = () => {
		animationDirection.value = 'next'
		isSlidingRight.value = true
		currentIndex.value = (currentIndex.value + 1) % props.assets.length

		isSlidingRight.value = false
	}

	// Function to allow specific index to be shown
	const showAtIndex = index => {
		if (index >= 0 && index < props.assets.length) {
			currentIndex.value = index
		}
	}

	defineExpose({
		showAtIndex,
	})
</script>

<style scoped lang="scss">
	.media-gallery {
		@apply flex flex-col items-center w-full grow h-5/6 justify-center relative h-full md:h-auto;
	}

	.media-container {
		@apply relative w-full overflow-hidden grow flex items-center;
	}

	.media-item {
		@apply h-[85%] object-scale-down rounded-xl mx-auto max-w-[90%];
		@media (max-width: 1023px) {
			@apply m-0 max-w-full;
		}
	}

	.controls {
		@apply flex justify-between w-full mt-4 absolute;
		top: 50%;
		transform: translatey(-50%);
		z-index: 100000;
		.control-button {
			@apply bg-stan-gray-light rounded-full p-3;
		}
	}
	.next-enter-active,
	.previous-enter-active,
	.next-leave-active,
	.previous-leave-active {
		@apply transition ease-in-out duration-1000;
	}
	.next-enter-from,
	.next-leave-to {
		@apply translate-x-24 opacity-0;
	}
	.previous-enter-from,
	.previous-leave-to {
		@apply -translate-x-24 opacity-0;
	}
</style>
