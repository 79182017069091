<template>
	<div class="members-horizontal-list-container">
		<template v-for="(member, index) in membersShow" :key="index">
			<AppUserImage :name="member?.data?.full_name" :src="member?.data?.profile_image" :color="member?.data?.color" size="md"  class="member-image" :style="{ zIndex: membersShow.length - index }"/>
		</template>
		<div class="total-members">{{ totalMembersShow }}</div>
	</div>
</template>
<script>
	export default {
		name: 'MembersHorizontalListCramped',
		props: {
			members: {
				type: Array,
			},
			totalMembers:
			{
				type: Number
			}
		},
		computed: {
			membersShow() {
				return this.members
			},
			totalMembersShow() {
				return  `${this.totalMembers} member${this.totalMembers === 1 ? '' : 's'}`;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.members-horizontal-list-container {
		@apply flex flex-row items-center relative; 
		padding-left:3px;
		.member-image {
			position: relative; 
			width: 40px; 
			height: 40px; 
			border-radius: 20%; 
			margin-left: -10px; 
			border: 2px solid var(--border-color, #fff); 

			.member-image:nth-child(1) { left: 0; }   
			.member-image:nth-child(2) { left: 10px; } 
			.member-image:nth-child(3) { left: 20px; } 
			.member-image:nth-child(4) { left: 30px; } 
			.member-image:nth-child(5) { left: 40px; } 
		}
	}
	.total-members {
		@apply ml-1.5 text-stan-text-light;
	}
</style>


