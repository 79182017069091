<template>
	<AppCard class="login-card">
		<div class="top-section">
			<div class="user-details-wrapper">
				<img :src="headerImage" class="user-image" width="54" height="54" alt="logo" />
			</div>
			<div class="flex flex-col items-center justify-center mb-12 gap-2">
				<h2 class="community-name">
					{{ title }}
				</h2>
				<span class="para-2 text-light"> {{ subtitle }} </span>
			</div>
		</div>
		<form id="login-form" @submit.prevent="$emit('submit')">
			<slot></slot>
		</form>
	</AppCard>
</template>

<script setup>
	import { defineProps, computed, ref, getCurrentInstance, watchEffect, watch, nextTick } from 'vue'
	import { useCommunityPublicStore } from '@/stores/public'

	/**
	 * Instance
	 */
	const { appContext } = getCurrentInstance()

	/**
	 * Props
	 */
	const props = defineProps({
		fallbackSubtitle: {
			type: String,
			description: 'fallback subtitle to use if no community data found.',
		},
	})

	const communityPublicStore = computed(() => useCommunityPublicStore())
	const headerImage = computed(() => {
		if (communityPublicStore.value?.currentCommunity && Object.keys(communityPublicStore.value?.currentCommunity).length > 0) {
			return communityPublicStore.value.user_store_image
		}
		return '/favicon.ico'
	})

	const title = computed(() => {
		if (communityPublicStore.value?.name) {
			return communityPublicStore.value.name
		}
		return 'Your Communities'
	})

	const subtitle = computed(() => {
		if (communityPublicStore.value?.username) {
			return `@${communityPublicStore.value.username}`
		}
		return props.fallbackSubtitle
	})
</script>

<style scoped lang="scss">
	.login-card {
		@apply m-auto;
		min-width: 100%;
		max-width: 100%;
		overflow-wrap: break-word;

		@media (min-width: 1024px) {
			min-width: 476px;
			max-width: 564px;
		}
	}
	.top-section {
		&::before {
			@apply block;
			content: '';
			background-color: var(--community-primary-color);
			height: 38px;
		}
	}
	.community-name {
		@apply gap-2 justify-center mt-5 items-center font-bold text-center px-4 break-words;
		max-width: 100%;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}

	#login-form {
		@apply flex flex-col gap-5 items-center px-4 md:px-12 pb-9 box-border mx-auto;
		max-width: 500px;

		.form-input {
			@apply w-full;
		}
	}
	.user-details-wrapper {
		@apply relative flex justify-center;
		&::before {
			@apply block absolute w-full;
			content: '';
			background-color: var(--community-primary-color);
			height: 50%;
		}
		.user-image {
			@apply rounded-full z-[1];
			border: 3px solid var(--stan-white);
		}
	}
</style>
