<template>
	<AppModal ref="unsplashImagePickerModal" title="Add Image" @close="closeModal" class="unsplash-img-modal">
		<div class="full-height-container">
			<div class="overflow-auto img-container">
				<div
					@dragover.prevent="dragOver"
					@dragleave.prevent="dragLeave"
					@drop.prevent="drop($event)"
					draggable
					class="thumbnail-container image-uploader flex flex-col items-center justify-center px-3"
					:class="{ 'dropZone-over': isDragging }"
				>
					<div v-if="isDragging" class="blue-circle flex justify-center items-center">
						<AppIcon src="arrow-down" />
					</div>
					<div v-else class="flex flex-col gap-y-2 items-center">
						<div class="text-center pb-1">
							<div tracking-tight>
								<div class="placeholder-value pb-1 text-sm font-bold">Drag and drop an image</div>
								<div class="placeholder-value text-xs">
									1920x1080
									<br />
									-or-
								</div>
							</div>
						</div>
						<AppButton class="flex upload-btn items-center px-3" outline @click="uploadImage">
							<p class="mb-0">Choose File</p>
						</AppButton>
					</div>
					<AppFileUpload
						key="unsplashImage"
						ref="hiddenFileUploadImage"
						accepts="image/bmp,image/jpeg,image/png,img/svg+xml"
						@assetUp="fileUploaded"
						@filePicked="filePicked"
						@progress="progressUploaded"
						@errorUp="errorFileUploaded"
					/>
				</div>

				<div v-for="image in unsplashImages" :key="image.id" class="thumbnail-container">
					<img
						:src="image.urls.small"
						class="thumbnail"
						:class="{ 'border-4 border-community-primary': image.id === chosenImage.id }"
						@click.self="imageSelected(image)"
					/>
					<div class="user-name-text" @click="visitUser(image)">{{ image.user.first_name }} {{ image.user.last_name }}</div>
				</div>

				<div class="my-2 loading-container">
					<AppButton name="load-more-unsplash-image-picker-modal" v-if="hasMore && !loading" outline class="mx-auto" @click="getNextPage()"
						>Load More</AppButton
					>
					<SolidDotsSpinner v-if="loading" color="var(--community-primary-color)" :dot-size="10" />
				</div>
			</div>
			<div v-if="chosenImage.id || uploadedImg" class="absolute bottom-0 z-40 bg-white add-image-btn">
				<AppButton class="my-8 w-full" @click="imageUsed()"  :disabled="Object.keys(chosenImage).length === 0"> Add Image </AppButton>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { debounce as _debounce } from 'lodash'
	import axios from 'axios'
	import { configSettings } from '../../plugins/configSettings'

	export default {
		data() {
			return {
				images: [],
				search: '',
				lastSearch: '',
				page: 1,
				loading: false,
				hasMore: true,
				isDragging: false,
				choosingImage: false,
				chosenImage: {},
				file: null,
				fileName: '',
				fileType: '',
				uploadedImg: {},
			}
		},
		computed: {
			modal() {
				return $(this.$refs.unsplashImagePickerModal.$el)
			},
			unsplashImages() {
				return this.images.slice(0, this.images.length - 1)
			},
		},
		methods: {
			init() {
				this.search = ''
				this.lastSearch = ''
				this.page = 1
				this.loading = false
				this.getImages()
				this.show()
			},
			show() {
				this.$refs.unsplashImagePickerModal.show()
			},
			close() {
				this.$refs.unsplashImagePickerModal.hide()
			},
			getImages(search = '', page = 1) {
				const clientId = configSettings.unsplashApiClientId
				if (!clientId) return
				this.loading = true
				if (page === 1) {
					this.images = [] // new search
					this.hasMore = true
				}

				const searchTerm = search || 'all'

				// use search endpoint
				const url = `https://api.unsplash.com/search/photos/?client_id=${clientId}&query=${searchTerm}&page=${page}&per_page=30`
				axios.get(url).then(response => {
					if (response.data.total_pages <= page) this.hasMore = false
					this.images.push(...response.data.results)
					this.loading = false
				})
			},
			getNextPage() {
				if (this.loading) return
				this.page++
				this.getImages(this.search, this.page)
			},
			imageSelected(image) {
				this.chosenImage = image
			},
			imageUsed() {
				this.choosingImage = true
				const clientId = configSettings.unsplashApiClientId
				if (!clientId) return
				const downloadEventUrl = `${this.chosenImage.links.download_location}&client_id=${clientId}`
				axios.get(downloadEventUrl)
				const name = `unsplash-${this.chosenImage.id}`
				this.$emit('unsplashImagePicked', { src: this.chosenImage.urls.regular, name, metadata: { id: name } })
				this.closeModal()
			},
			visitUser(image) {
				window.open(`${image.user.links.html}?utm_source=Stan&utm_medium=referral`)
			},
			closeModal() {
				this.close()
				if (!this.choosingImage) {
					this.$emit('close-modal')
				}
			},
			dragOver() {
				this.isDragging = true
			},
			dragLeave() {
				this.isDragging = false
			},
			drop(e) {
				this.choosingImage = true
				this.isDragging = false

				this.$refs.hiddenFileUploadImage.onFilePicked(e)
			},
			uploadImage() {
				this.choosingImage = true
				this.isDragging = false
				this.$refs.hiddenFileUploadImage.onPickFile()
			},
			filePicked(files) {
				this.loading = true
				this.$nextTick(() => {
					const file = files[0]
					const type = file?.type.split('/')
					this.fileName = file?.name
					this.fileType = type?.[0]
				})
			},
			fileUploaded(fileData) {
				const self = this
				this.uploadedImg = fileData
				this.choosingImage = true
				this.$emit('unsplashImagePicked', {
					src: this.uploadedImg.src,
					name: this.uploadedImg.fileName,
					metadata: { id: this.uploadedImg.fileName },
				})
				this.$notify({
					type: 'success',
					title: 'Success',
					text: 'Successfully uploaded image',
				})
				this.closeModal()
			},
			errorFileUploaded() {
				this.$notify({
					type: 'error',
					title: 'Error',
					text: 'There was an error uploading your file, please try again',
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.unsplash-img-modal {
		:deep(.modal.padding) {
			@apply p-4 sm:p-8;
		}
		.full-height-container {
			@apply h-[80vh] overflow-y-scroll pb-16;
			.img-container {
				@apply grid grid-cols-2 md:grid-cols-3 gap-2.5;
				& > :last-child {
					@apply col-span-2 md:col-span-3;
				}
				.thumbnail-container {
					@apply relative m-0 overflow-hidden h-40 max-h-40;

					.thumbnail {
						@apply h-full w-full object-cover cursor-pointer rounded-md;
					}

					.user-name-text {
						@apply text-center text-sm text-white absolute bottom-1 left-2 cursor-pointer;
					}
				}
				.loading-container {
					@apply h-12;
				}
				.image-uploader {
					@apply border border-dashed rounded-sm;
					border-color: var(--stan-text-light-color);
				}
				.upload-btn {
					@apply border border-solid rounded-md min-w-0;
					border-color: var(--stan-primary-primary-color);
					color: var(--stan-primary-primary-color);
					p {
						@apply text-sm;
					}
				}

				.placeholder-value {
					@apply opacity-50;
				}
				.blue-circle {
					background: var(--stan-primary-primary-color);
					@apply w-12 h-12 text-white rounded-lg pointer-events-none;
				}
				.dropZone-over {
					background-color: var(--stan-blue-8);
				}
			}
		}
		.add-image-btn {
			@apply w-[calc(100%-2rem)] sm:w-[calc(100%-4rem)];
		}
	}
</style>
