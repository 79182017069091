export async function updateNotificationInState(notification, store) {
	// TODO: notifications are generic and may not belong to a specfic community, the member id being specfic should account for this issue though
	const currentCommunitySlug = store._current_community_slug
	const currentMemberId = store.self?.community_member_id

	if (currentMemberId == notification?.receiver_id) {
		if (!store._communities[currentCommunitySlug]?.notifications) {
			store._communities[currentCommunitySlug].notifications = {}
		}

		store._communities[currentCommunitySlug].notifications[notification.message_notification_id] = notification
	}
}
