export function tryGetStateCopyOfMessage(message, store) {
	const { parent_message_id: parentMessageId, message_id: messageId } = message
	const channelName = store.channelIdToNameMap[message.channel_id]

	return parentMessageId
		? store._communities[store._current_community_slug]?.channels[channelName]?.threads[parentMessageId]?.messages[messageId]
		: store._communities[store._current_community_slug].channels[channelName]?.messages[messageId]
}

export async function updateMessageDataInState(message, store) {
	const currentCommunitySlug = message.community_id ? `${store.communityIdToSlugMap[message.community_id]}` : `${store._current_community_slug}`
	const currentChannelName = store.channelIdToNameMap[message.channel_id]

	// Vue checks shallow for updates, so for deeper message changes this will ensure an update
	message.updateInState = Date.now()

	if ((store._skipMessageUpdate[message.message_id] ?? 0) > 0) {
		store._skipMessageUpdate[message.message_id] -= 1
		return
	}
	if (!store._communities[currentCommunitySlug]?.channels[currentChannelName]?.messages)
		store._communities[currentCommunitySlug].channels[currentChannelName].messages = {}

	const parentMessageId = message.parent_message_id
	if (parentMessageId) {
		if (!store._communities[currentCommunitySlug]?.channels[currentChannelName]?.messages[parentMessageId])
			await store.getMessageDetails(currentCommunitySlug, currentChannelName, parentMessageId)
		if (!store._communities[currentCommunitySlug]?.channels[currentChannelName]?.threads[parentMessageId]?.messages) {
			store._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId] = { messages: {} }
		}
		store._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId].messages[message.message_id] = message

		return
	}
	if (
		!store._communities[currentCommunitySlug]?.channels[currentChannelName]?.messages[message.message_id] ||
		message.thread_count !== store._communities[currentCommunitySlug]?.channels[currentChannelName]?.messages[message.message_id].thread_count
	) {
		store.getThreadMessages({}, message.message_id, currentChannelName)
	}

	store._communities[currentCommunitySlug].channels[currentChannelName].messages[message.message_id] = message

	if (store._current_community_slug === currentCommunitySlug && store._current_channel_name === currentChannelName) {
		if (parentMessageId && store._current_thread_id === parentMessageId) {
			store.getThreadMessages({ mark_read: 1 })
		}
	}
}

export function deleteMessageInState(formattedMessage, store) {
	const messageId = formattedMessage.message_id
	const parentMessageId = formattedMessage.parent_message_id
	const channelName = store.channelIdToNameMap[formattedMessage.channel_id]

	if (parentMessageId) {
		delete store._communities[store._current_community_slug]?.channels[channelName]?.threads[parentMessageId].messages[messageId]
	} else {
		delete store._communities[store._current_community_slug].channels[channelName]?.messages[messageId]
	}
	if (store._messages_later.results[messageId]) delete store._messages_later.results[messageId]
}
