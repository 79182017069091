import { defineComponent } from 'vue'
import AuthContainer from '@/layouts/AuthContainer.vue'

const noPageFoundRoutes = {
	path: '/:pathMatch(.*)*',
	name: '404',
	redirect: '',
	component: () => import('./PageNotFound.vue').then(m => m.default || m),
}

export default noPageFoundRoutes
