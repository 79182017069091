<script setup>
	import { defineAsyncComponent } from 'vue'

	const props = defineProps({
		src: {
			type: String,
			required: true,
		},
		viewBox: {
			type: String,
			required: false,
			default: '0 0 24 24',
		},
	})

	// All icons must be 24 x 24
	const icon = defineAsyncComponent(() => import(`./../../assets/icons/ic-${props.src}.svg`))
</script>

<template>
	<component :is="icon" class="app-icon" :viewBox="viewBox" />
</template>
<style lang="scss" scoped>
	.app-icon {
		width: 20px;
		height: 20px;
		&.sm {
			width: 16px;
			height: 16px;
		}
		&.md {
			width: 30px;
			height: 30px;
		}

		&.lg {
			width: 40px;
			height: 40px;
		}

		:deep(path),
		:deep(rect) {
			&.empty {
				display: block;
			}
			&.filled {
				display: none;
			}
		}

		&.filled {
			:deep(path),
			:deep(rect) {
				&.empty {
					display: none;
				}
				&.filled {
					display: block;
				}
			}
		}

		&.color-text-dark {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-text-dark-color);
			}
		}

		&.color-text-white {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-white) !important;
			}
		}
		&.color-stroke-white {
			:deep(path),
			:deep(rect) {
				stroke: var(--stan-white) !important;
			}
		}

		&.color-text-light {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-text-light-color);
			}
		}

		&.color-gray {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-text-light-color);
			}
		}
		&.color-purple {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-primary-primary-color);
			}
		}
		&.color-danger {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-negative-primary-color) !important;
			}
		}
		&.color-primary {
			:deep(path),
			:deep(rect) {
				fill: var(--community-primary-color) !important;
			}
		}
		&.color-soft {
			:deep(path),
			:deep(rect) {
				fill: var(--community-primary-soft-color) !important;
			}
		}
		&.no-fill {
			:deep(path),
			:deep(rect) {
				fill: none !important;
			}
		}
	}
</style>
