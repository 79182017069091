<template>
	<AppModal ref="communityInfoModal" title="" action="Enter Community" :loading="loading" :backdrop="backdrop" @cta="close" @close="close">
		<div class="relative mb-8">
			<div class="shadow-stan-box-inner-custom mx-auto">
				<div class="p-1 mb-3.5 shadow-stan-image-shadow bg-white rounded-full flex justify-center items-center w-16 h-16 mx-auto">
					<img class="rounded-full h-14 w-14" :src="link_image" alt="Profile Picture" />
				</div>
				<h3 class="text-[--stan-blue-9] text-3xl text-center font-extrabold leading-7 title">
					{{ activeCommunity.data.name }}
				</h3>
			</div>
		</div>
		<div
			class="max-h-80 h-full shadow-stan-box-inner-custom overflow-auto bg-[--stan-gray-mid-light-color] p-5 rounded-xl"
			v-html="content"
		></div>
	</AppModal>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'

	export default {
		setup: () => ({}),
		props: {
			backdrop: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				link_image: '',
				loading: false,
				content: '<p></p>',
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			activeCommunity() {
				return this.communityStore.currentCommunity
			},
		},
		methods: {
			show() {
				this.$refs.communityInfoModal.show()
			},
			close() {
				this.$refs.communityInfoModal.hide()
			},
		},
		watch: {
			activeCommunity: {
				immediate: true,
				handler(newVal) {
					if (newVal && newVal.data) {
						this.community_name = `${newVal?.data?.name}`
						this.content = newVal?.data?.introduction || '<p></p>'
						this.link_image = newVal?.data?.image ? `${newVal?.data?.image}` : ''
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped></style>
